
import React, { Suspense, lazy } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FileText } from "lucide-react";
import { Button } from "../../components/ui/button";

// Lazy-loaded components
const WhoCanJoin = lazy(() => import("./whoCanJoin"));
const FlipCard = lazy(() => import("./flipingCard"));
const Carousel = lazy(() => import("./crousel"));
const LearnCard = lazy(() => import("./learncards"));
const TrainerCard = lazy(() => import("./trainerSection"));
const FAQComponent = lazy(() => import("./faqSection"));
const AnimatedCounter = lazy(() => import("./numberSpinner"));
const HeroSection = lazy(() => import("./herosection"));
const Join = lazy(() => import("./join"));

// Import assets
import award from "../../assets/full-stack-development/certificate_4_11zon.webp";
import coin from "../../assets/full-stack-development/code-snippet-template_7_11zon.webp";
import computer from "../../assets/full-stack-development/experince-letter.webp";
import gift from "../../assets/full-stack-development/free-paid-tools_10_11zon.webp";
import microchip from "../../assets/full-stack-development/proffesional-roadmap_12_11zon.webp";
import supriceBox from "../../assets/full-stack-development/suprise-bonus_14_11zon.webp";
import bg1 from "../../assets/full-stack-development/free-certification_9_11zon.webp";
import bg2 from "/new-banner/skillyard-certificate.png"
import bg3 from "../../assets/full-stack-development/sn-certificate_5_11zon.webp";
import bg4 from "../../assets/full-stack-development/personalised-roadmap_11_11zon.webp";
import bg5 from "../../assets/full-stack-development/reusable-code_13_11zon.webp";
import bg6 from "../../assets/full-stack-development/paid-tools_6_11zon.webp";





import cr1 from "../../assets/digital_icon/certificate/1.webp";
import cr2 from "../../assets/digital_icon/certificate/2.webp";
import cr3 from "../../assets/digital_icon/certificate/3.webp";
import rupansh from "../../assets/digital_icon/rupansh.webp";

// Define types
type StatItemProps = {
  value?: number | null;
  label: string;
  backValue: string;
};

type CardData = {
  id: number;
  front: string;
  back: string;
  img: string;
  bgimg: string;
};

type LearnCardData = {
  id: number;
  title: string;
  des: { id: number; top: string }[];
};

type TrainerData = {
  id: number;
  name: string;
  des: string;
  img: string;
};

type FAQData = {
  question: string;
  answer: string;
};

const StatItem: React.FC<StatItemProps> = ({ value, label, backValue }) => {
  return (

    <div className="bg-[#5E5F5E] w-full rounded-xl gap-3 lg:p-2 shadow-lg text-center p-0 m-0 lg:w-[30%]  ">
      <p className="text-3xl text-white font-bold m-2 p-0">
        <Suspense fallback={<span>{value}</span>}>
          <AnimatedCounter backValue={backValue} value={Number(value)} />
        </Suspense>
      </p>
      <p className="text-xl text-white m-2 p-0">{label}</p>
    </div>
  );
};

const ConnectWithMarket: React.FC = () => {
  // const hasRun = useRef(false);

  // useEffect(() => {
  //   if (!hasRun.current) {
  //     // Perform any one-time initialization here if needed
  //     hasRun.current = true;
  //   }
  // }, []);

  const images = [
    { titel: "Startup India", heading: "Skillyards Versatility is recognised by Startup India", src: cr2, alt: 'Image 1' },
    { titel: "Best Digital Marketing Agency", heading: "SN Digitech, Skilllyards’ Parent Company, was awarded One of the Best Digital Marketing Agencies in New York", src: cr1, alt: 'Image 2' },
    { titel: "MSME", heading: " Skillyards recognized by MSME India for training excellence.", src: cr3, alt: 'Image 3' },
  ];

  const statsData = [
    { value: 15, label: "Years in Business", backValue: "+ Years" },
    { value: 300, label: "Happy Clients", backValue: "+" },
    { value: 30, label: "Domain Expertise", backValue: "+" },
    { value: 100, label: "Employees", backValue: "+" },
    { value: 360, label: "Integrated Marcom Agency", backValue: "°" },
    { value: 98, label: "Campaign Success Rate", backValue: "%" },
  ];

  const cardData: CardData[] = [
    { id: 1, front: "Professional Roadmap", back: "Personalized Roadmap to Becoming a Full-Stack Developer", img: microchip, bgimg: bg1 },
    { id: 2, front: "Certification", back: "Free Certification Upon Completing the Workshop", img: computer, bgimg: bg2 },
    { id: 3, front: "Paid Tools for Free", back: "Chance to get get access to Paid Tools for Free", img: award, bgimg: bg3 },
    { id: 4, front: "Code Snippet Templates", back: "Reusable Code Snippets to save time for building Websites", img: coin, bgimg: bg4 },
    { id: 5, front: "Experience Letter", back: "Chance to gain an experience letter by SN Digitech", img: gift, bgimg: bg5 },
    { id: 6, front: "Surprise Bonuses", back: "Unique Surprise Bonuses for Top Participants", img: supriceBox, bgimg: bg6 },
  ];

  const learnCards: LearnCardData[] = [
    {
      id: 1,
      title: "What is Full Stack Development?",
      des: [
        { id: 11, top: "Introduction to Full Stack Development?" },
        { id: 222, top: "Frontend vs Backend Development" },
        { id: 333, top: "Why Choose MERN Stack?" },
        { id: 444, top: "Role of a Full Stack Developer" },
      ],
    },
    {
      id: 2,
      title: "Frontend Development (React.js)",
      des: [
        { id: 666, top: "Basics of HTML, CSS, and JavaScript" },
        { id: 777, top: "Introduction to React.js" },
        { id: 888, top: "React Components, State, and Props" },
        { id: 999, top: "React Router and SPA (Single Page Applications)" },
        { id: 1000, top: "Advanced React: Context API and Hooks" },
      ],
    },
    {
      id: 3,
      title: "Backend Development (Node.js and Express.js)",
      des: [
        { id: 1010, top: "Introduction to Node.js" },
        { id: 1111, top: "Setting up a Backend with Express.js" },
        { id: 1212, top: "RESTful APIs: GET, POST, PUT, DELETE" },
        { id: 1313, top: "Middleware in Express.js" },
        { id: 1414, top: "Authentication with JWT (JSON Web Tokens)" },
      ],
    },
    {
      id: 4,
      title: "Database Management (MongoDB)",
      des: [
        { id: 1010, top: "Introduction to MongoDB" },
        { id: 1111, top: "CRUD Operations in MongoDB" },
        { id: 1212, top: "Data Modeling and Schemas with Mongoose" },
        { id: 1313, top: "Connecting MongoDB with Express.js" },
      ],
    },
    {
      id: 5,
      title: "Full Stack Project Development",
      des: [
        { id: 1010, top: "Setting up the MERN Stack" },
        { id: 1111, top: "Integrating Frontend and Backend" },
        { id: 1212, top: "State Management with Redux (Optional)" },
        { id: 1313, top: "Building a Complete MERN Application" },
        { id: 1314, top: "Deployment with Vercel, Netlify, or Heroku" },
      ],
    },
    {
      id: 6,
      title: "Advanced Topics",
      des: [
        { id: 1010, top: "Introduction to WebSockets (Real-Time Apps)" },
        { id: 1111, top: "Server-Side Rendering (SSR) with Next.js" },
        { id: 1212, top: "Unit Testing and Integration Testing" },
        { id: 1313, top: "Scaling and Optimizing Applications" },
      ],
    },
  ];

  const trainer: TrainerData[] = [
    {
      id: 1,
      name: "Mr. Manmohan Singh Jina",
      des: "Mr. Manmohan Singh Jina is an experienced Full-Stack Web Developer and Trainer at Skillyards. With more than five years of expertise and having served over 100 clients, he delivers valuable knowledge and professional guidance to support IT Enthusiasts in achieving success.",
      img: rupansh,
    },
  ];

  const faqs: FAQData[] = [
    {
      question: "Is EMI available in the Full-Stack Development Training Program?",
      answer: "Yes, EMI option is available in the Full-Stack Development Training Program so that the students do not feel the need to submit the program fees in one go.",
    },
    {
      question: "Do I need any previous experience before starting the Full-Stack Development Training Program?",
      answer: "No, there is no previous experience required before starting the program because all the basics are included in the curriculum."
    },
    {
      question: "What kind of projects will I work on during the training program?",
      answer: "You will get to work on projects by international as well as domestic clients including projects such as creating applications, websites, and many more!",
    },
  ];
  const badge = ['full-stack Developer', 'Backend Developer', 'Express.js', 'Next.js', 'TypeScript']



  return (
    <div >
      <Helmet>
        <title>FullStack Development</title>
        <meta name="description" content="Master digital marketing with AI integration. Join our workshop to learn SEO, WordPress, Shopify, and social media marketing from industry experts." />
        <meta name="keywords" content="digital marketing, AI marketing, SEO training, WordPress development, social media marketing" />
        <link rel="canonical" href="https://skillyards.com/full-stack-web-development-training-in-agra" />

      </Helmet>
      <div className="transition-opacity duration-500 ease-out">
        <Suspense fallback={<div>Loading Hero Section...</div>}>
          <HeroSection />
        </Suspense>

        <div className="mt-[330px] sm:mt-[100px] lg:mt-0">
          <section className="bg-[#414047] py-16 md:py-20 lg:py-20">
            <div className="max-w-7xl mx-auto px-6 sm:px-8">
              <header className="text-center mb-16 md:mb-20">
                <div className="flex flex-col gap-2 text-center">
                  <h1 className="font-montserrat text-4xl sm:text-5xl md:text-6xl lg:text-5xl text-white font-bold tracking-wide leading-tight">
                    Is Full-Stack Development in Demand?
                  </h1>
                  <p className="text-lg sm:text-xl md:text-2xl lg:text-xl leading-relaxed font-montserrat text-white/90 mt-4 md:mt-6 text-left w-full">
                    The demand for full-stack developers is growing rapidly, with job opportunities projected to rise by more than 8% from 2023 to 2033. Companies in India themselves hire more than 10000 full-stack developers every year, reflecting the high need for versatile professionals skilled in the field.
                  </p>
                </div>
              </header>

              <div className="flex flex-wrap sm:flex-wrap justify-center gap-6 p-0 m-0 md:my-5 lg:my-2">
                {statsData.map((stat, index) => (
                  <StatItem key={index} value={Number(stat.value)} label={stat.label} backValue={stat.backValue} />
                ))}
              </div>
            </div>
          </section>
        </div>

        <section className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 md:py-16">
          <div className="flex flex-col gap-6 sm:gap-8 md:gap-12">
            <div className="text-center flex flex-col justify-center items-center gap-1">
              <h2 className="font-bold text-3xl sm:text-3xl md:text-3xl lg:text-3xl mb-0">
                Benefits You'll Get From Workshop
              </h2>
              <div className="border-[3px] w-[30%] sm:w-[40%] md:w-[30%] lg:w-[20%] border-yellow-400 mt-2" />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6 sm:gap-8 lg:gap-y-32">
              {cardData.map((elm) => (
                <div key={elm.id} className="flex justify-center items-center">
                  <Suspense fallback={<div>Loading Flip Card...</div>}>
                    <FlipCard data={elm} />
                  </Suspense>
                </div>
              ))}
            </div>
          </div>
        </section>

        <div className="flex flex-col-reverse md:flex-row justify-center items-center gap-5 p-4 md:p-6 mt-4">
          <Suspense fallback={<div>Loading Carousel...</div>}>
            <div className="w-full">
              <Carousel images={images} />
            </div>
          </Suspense>
        </div>

        <Suspense fallback={<div>Loading Join Section...</div>}>
          <Join />
        </Suspense>

        <div className="flex flex-col my-12 m-1">
          <div className="text-center mt-2 flex flex-col justify-center items-center gap-1">
            <h2 className="font-bold text-3xl sm:text-3xl md:text-3xl lg:text-3xl mb-0">
              What you'll Learn
            </h2>
            <div className="border-[3px] w-[30%] sm:w-[40%] md:w-[30%] lg:w-[20%] border-yellow-400 mt-2" />
          </div>

          <div className="flex flex-wrap justify-evenly gap-4 mt-5">
            {learnCards.map((elm) => (
              <div key={elm.id} className="w-full md:w-1/3 lg:w-1/4 p-2">
                <Suspense fallback={<div>Loading Learn Card...</div>}>
                  <LearnCard data={elm} />
                </Suspense>
              </div>
            ))}
          </div>
        </div>

        <div className="mx-0 px-0 sm:px-6 lg:px-10 py-8">
          <div className="text-center mb-8">
            <h2 className="font-bold text-3xl">Meet Our Trainer</h2>
            <div className="border-[3px] border-yellow-400 w-32 sm:w-40 lg:w-48 mx-auto mt-2" />
          </div>

          <div className="px-2">
            {trainer.map((elm) => (
              <div key={elm.id} className="flex justify-center items-center lg:px-12">
                <Suspense fallback={<div>Loading Trainer Card...</div>}>
                  <TrainerCard badge={badge} name={elm.name} des={elm.des} img={elm.img} />
                </Suspense>
              </div>
            ))}
          </div>
        </div>

        <div className="text-center font-montserrat mt-5">
          <div className="mt-5">
            <Suspense fallback={<div>Loading Who Can Join Section...</div>}>
              <WhoCanJoin />
            </Suspense>
          </div>
        </div>

        <div className="flex justify-center items-center p-2 sm:p-4 m-2 sm:m-4 mt-4">
    <Link to="/foundation-training-program-in-agra" className="relative group">
      <div className="absolute -inset-1 bg-gradient-to-r from-amber-300 via-yellow-400 to-amber-500 rounded-lg blur opacity-70 group-hover:opacity-100 transition duration-500"></div>
      <Button className="relative rounded-3xl flex items-center gap-3 px-6 sm:px-10 py-3 sm:py-4 bg-gradient-to-r from-amber-300 via-yellow-400 to-amber-200 text-gray-900 text-lg sm:text-xl font-medium hover:scale-105 active:scale-95 transition">
        <FileText className="w-5 h-5 sm:w-6 sm:h-6 transition-transform group-hover:rotate-12 group-hover:scale-110" />
        View Syllabus
      </Button>
    </Link>
  </div>

        <div className="flex justify-center items-center">
          <div className="w-[90%]">
            <Suspense fallback={<div>Loading FAQ Section...</div>}>
              <FAQComponent faqs={faqs} />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectWithMarket;