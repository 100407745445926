import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

import axios from 'axios';
import { toast } from "sonner";
import { NeoDove } from "./neo-dove";
// import { P } from "node_modules/framer-motion/dist/types.d-6pKw1mTI";

interface Payload {
  name: string;
  email: string;
  message: string;
  phone: string;
  subject: string;
}

const formSchema = z.object({
  name: z.string().min(2, "Name must be at least 2 characters")
    .regex(/^[A-Za-z\s]+$/, "Please enter only alphabetic characters"),
  email: z.string().email("Please enter a valid email address"),
  mobile: z.string()
    .regex(/^\d{10}$/, "Please enter a valid 10-digit mobile number"),
  subject: z.string().min(1, "Please select a program"),
  location: z.string().min(2, "Location must be at least 2 characters")
    .regex(/^[A-Za-z\s]+$/, "Please enter only alphabetic characters"),
  message: z.string().min(1, "Message must be at least 1 characters"),
});

type FormValues = z.infer<typeof formSchema>;

const programs = [
  "Full-Stack Foundation",
  "Full-Stack Development",
  "Front-End Development",
  "Back-End Development",
  "UI/UX Designing Program",
  "Advanced Digital Marketing",
  "BCA Degree Program",
  "MCA Degree Program",
  "Other"
] as const;

interface CareerCounselingModalProps {
  show: boolean;
  handleClose: () => void;
}

export default function CareerCounselingModal({ show, handleClose }: CareerCounselingModalProps): JSX.Element {
  const api = `${import.meta.env.VITE_APP_API_URL}`;
  
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      mobile: "",
      subject: "",
      location: "",
      message: "",
    },  
  });

  async function onSubmit(values: FormValues): Promise<void> {
    try {
      //console.log(values, "before db");
     await axios.post(`${api}/user/contact`, { formData: values });
     
      toast.success("Your freebie is on its way! 🎉", {
        description: "we'll soon get in touch with you"
      });

      const payload: Payload = {
        name: values.name,
        email: values.email,
        message: values.message,
        phone: values.mobile,
        subject: values.subject 
      }

      await NeoDove(payload)
      form.reset();
      handleClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(`Oops! Something went wrong.${error}`, {
        description: "Please try again later."
      });
    }
  }

  return (
    <Dialog open={show} onOpenChange={handleClose}>
  <DialogContent className="sm:max-w-[380px] p-0 overflow-hidden bg-white rounded-lg shadow-xl">
    <div className="relative">
      {/* Compact Header */}
      <div className="bg-white px-3 pt-3 pb-4 text-center relative">
        <button
          onClick={handleClose}
          className="absolute right-1.5 top-1.5 text-gray-400 hover:text-gray-600"
        >
         
        </button>
        
        <div className="mb-3">
          <img 
            src="/Web_logo/front_weblogo.webp" 
            alt="Skillyards" 
            className="w-50 h-12 mx-auto"
          />
        </div>
        
        <h2 className="text-xl  mb-1">
        Transform Your Career
        </h2>
        <p className="text-[12px]">
        Get personalized guidance from industry experts 
        </p>
        <span className="text-[12px] px-0 py-0 mx-0 my-0"  >and take the first step towards your dream career</span>
      </div>

      {/* Compact Purple Section */}
      {/* <div className="relative bg-purple-600 text-white p-2 flex items-center gap-2 rounded-lg mx-3 
        shadow-lg before:absolute before:inset-0 before:translate-y-1 before:translate-x-0.5 
        before:bg-purple-800 before:rounded-lg before:-z-10">
        <div className="relative">
          <span className="p-1 text-3xl rounded-full bg-black/10">
            🔓
          </span>
          <div className="absolute inset-0 rounded-full border-2 border-purple-300/20 animate-spin" 
               style={{animation: 'spin 3s linear infinite'}}></div>
        </div>
        <p className="text-xs font-medium">
          Get your Bonus instantly! Unlock insights now.
        </p>
      </div> */}

      {/* Compact Form Section */}
      <div className="p-3 sm:px-4 sm:py-3">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
            <div className="grid grid-cols-2 gap-2">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input 
                        placeholder="Name"
                        className="rounded-md border-gray-300 h-9 text-sm focus:ring-1 focus:ring-purple-500"
                        {...field} 
                      />
                    </FormControl>
                    <FormMessage className="text-xs" />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input 
                        placeholder="Email"
                        type="email"
                        className="rounded-md border-gray-300 h-9 text-sm focus:ring-1 focus:ring-purple-500"
                        {...field} 
                      />
                    </FormControl>
                    <FormMessage className="text-xs" />
                  </FormItem>
                )}
              />
            </div>

            <div className="grid grid-cols-2 gap-2">
              <FormField
                control={form.control}
                name="mobile"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input 
                        placeholder="Phone"
                        className="rounded-md border-gray-300 h-9 text-sm focus:ring-1 focus:ring-purple-500"
                        {...field} 
                      />
                    </FormControl>
                    <FormMessage className="text-xs" />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="subject"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <SelectTrigger className="rounded-md border-gray-300 h-9 text-sm focus:ring-1 focus:ring-purple-500">
                          <SelectValue placeholder="Program" />
                        </SelectTrigger>
                        <SelectContent>
                          {programs.map((program) => (
                            <SelectItem 
                              key={program} 
                              value={program}
                              className="text-sm hover:bg-purple-50"
                            >
                              {program}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="text-xs" />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="location"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input 
                      placeholder="Location"
                      className="rounded-md border-gray-300 h-9 text-sm focus:ring-1 focus:ring-purple-500"
                      {...field} 
                    />
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Textarea 
                      placeholder="Message"
                      className="rounded-md border-gray-300 min-h-[60px] text-sm focus:ring-1 focus:ring-purple-500"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />

            <Button 
              type="submit" 
              className="w-full bg-yellow-400
                        text-black font-semibold rounded-md py-2 text-sm shadow-sm hover:bg-yellow-300"
            >
              Start your carrier journey now
            </Button>


          </form>
        </Form>
      </div>
    </div>
  </DialogContent>
</Dialog>
  );
}