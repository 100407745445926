
// Define the base type for the item
interface BaseItem {
  id: number;
  title: string;
  subtitle?: string;
  description?: string[];
  buttonText?: string;
  buttonAction?: string;
  backgroundImage: string;
}

// Define a generic type that extends the base type
interface GenericItem<T> extends BaseItem {
  additionalFields?: T; // Allow additional fields of generic type T
}

// Define the props for the component
interface BgDisplayItemsProps<T> {
  item: GenericItem<T>;
}

// Implement the BgDisplayItems component
const BgDisplayItems = <T,>({ item }: BgDisplayItemsProps<T>) => {
  return (
    <div className="relative flex items-center justify-center h-64 md:h-80 xl:h-96 overflow-hidden mb-12">
    <img
      src={item.backgroundImage}
      alt="Blogs Background"
      className="absolute inset-0 w-full h-full object-cover brightness-50"
    />
    <h1 className="relative z-10 text-4xl md:text-3xl xl:text-4xl font-bold text-white text-center">{item.title}</h1>
  </div>
  );
};

export default BgDisplayItems;