import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "sonner";

interface ContentItem {
  _id: string;
  title: string;
  description: string;
  topic: string;
  subTopic: string;
  subDescription: string;
  example: string;
  codeSnippet: string;
  file: File | null;
  links: string[];
  prerequisites: string[];
  subject: string;
  tags: string[];
  level: string;
  duration: string;
  courseId: string;
}

interface CreateCourse {
  _id: string;
  usedInIndustries: string;
  descriptionts: string;
  subject: string;
}

interface CourseFormData {
  _id: string;
  title: string;
  description: string; // Updated to `description`
  topic: string;
  subTopic: string;
  subDescription: string;
  example: string;
  codeSnippet: string;
  file: File | null;
  links: string[];
  prerequisites: string[];
  subject: string;
  tags: string[];
  level: string;
  duration: string;
  courseId: string;
  createCourse: CreateCourse;
}

interface FormComponentProps {
  formData: CourseFormData;
  setFormData: React.Dispatch<React.SetStateAction<CourseFormData>>;
  createCourse: CreateCourse;
  setCreateCourse: React.Dispatch<React.SetStateAction<CreateCourse>>;
  setContent: React.Dispatch<React.SetStateAction<ContentItem[]>>;
  handleCreateCourse: (e: React.FormEvent) => void;
}

const FormComponent: React.FC<FormComponentProps> = ({
  formData,
  setFormData,
}) => {
  const api = `${import.meta.env.VITE_APP_API_URL}/admin/`;
  const [options, setOptions] = useState<CreateCourse[]>([]);

  // Fetch available courses
  const getCourses = async () => {
    try {
      const response = await fetch(`${api}getAllCourses`);
      const data = await response.json();

      if (!data.error) {
        setOptions(data.courseSections);
        toast.success("Courses fetched successfully!")
      }else{
        toast.error("Failed to fetch course sections.");
      }
    } catch (error) {
      toast.error(`Failed to fetch course sections. Please try again.${error}`);
      // console.error("Error fetching courses:", error);
      // alert("Failed to fetch course sections. Please try again.");
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  // Autofill fields based on course selection
  const handleAutofill = (value: string) => {
    const selectedCourse = options.find((course) => course._id === value);
    if (selectedCourse) {
      setFormData((prev) => ({ ...prev, courseId: selectedCourse._id }));
      toast.success("Course autofilled successfully!");
    }
  };

  // Handle input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle array input changes
  const handleArrayChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const values = e.target.value.split(",");
    setFormData((prev) => ({ ...prev, [name]: values }));
  };

  // Handle file upload
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setFormData((prev) => ({ ...prev, file }));
    toast.success("File uploaded successfully!");
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const data = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => data.append(key, item));
      } else if (value instanceof File) {
        data.append(key, value);
      } else {
        data.append(key, value);
      }
    });

    try {
      const response = await fetch(`${api}uploadCreateContent`, {
        method: "POST",
        body: data,
      });
      const result = await response.json();

      if (!result.error) {
        // alert("Content uploaded successfully!");
        toast.success("Content uploaded successfully!");
      } else {
        // alert("Failed to upload content.");
        toast.error("Failed to upload content."); 
      }
    } catch (error) {
      // console.error("Error uploading content:", error);
      // alert("Error occurred while uploading content.");
      toast.error(`Error occurred while uploading content ${error}`);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white shadow-2xl rounded-2xl p-8 w-full space-y-6">
      <h2 className="text-xl text-center text-gray-800 tracking-tight">Post Study Material</h2>

      {/* Course Section Dropdown */}
      <div>
        <Label htmlFor="courseSection">Select Course Section</Label>
        <Select onValueChange={handleAutofill}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select a section" />
          </SelectTrigger>
          <SelectContent>
            {options.map((option) => (
              <SelectItem key={option._id} value={option._id}>
                {option.subject}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {/* Title */}
      <div>
        <Label htmlFor="title">Title</Label>
        <Input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          placeholder="Enter title"
        />
      </div>

      {/* Topic */}
      <div>
        <Label htmlFor="topic">Topic</Label>
        <Input
          type="text"
          id="topic"
          name="topic"
          value={formData.topic}
          onChange={handleChange}
          placeholder="Enter topic"
        />
      </div>

      {/* Description with CKEditor */}
      <div>
        <Label htmlFor="description">Description</Label>
        <CKEditor
          editor={ClassicEditor}
          data={formData.description || ""} // Updated to `description`
          onChange={(_, editor) => {
            const data = editor.getData();
            setFormData((prev) => ({ ...prev, description: data })); // Updated to `description`
          }}
        />
      </div>

      {/* SubTopic */}
      <div>
        <Label htmlFor="subTopic">SubTopic</Label>
        <Input
          type="text"
          id="subTopic"
          name="subTopic"
          value={formData.subTopic}
          onChange={handleChange}
          placeholder="Enter subtopic"
        />
      </div>

      {/* SubDescription with CKEditor */}
      <div>
        <Label htmlFor="subDescription">SubDescription</Label>
        <CKEditor
          editor={ClassicEditor}
          data={formData.subDescription || ""}
          onChange={(_, editor) => {
            const data = editor.getData();
            setFormData((prev) => ({ ...prev, subDescription: data }));
          }}
        />
      </div>

      {/* Code Snippet */}
      <div>
        <Label htmlFor="codeSnippet">Code Snippet</Label>
        <Textarea
          id="codeSnippet"
          name="codeSnippet"
          value={formData.codeSnippet}
          onChange={handleChange}
          rows={3}
          placeholder="Enter code snippet"
        />
      </div>

      {/* Links */}
      <div>
        <Label htmlFor="links">Links (comma-separated)</Label>
        <Input
          type="text"
          id="links"
          name="links"
          value={formData.links.join(",")}
          onChange={(e) => handleArrayChange(e, "links")}
          placeholder="Enter links"
        />
      </div>

      {/* File Upload */}
      <div>
        <Label htmlFor="file">Upload File</Label>
        <Input type="file" id="file" name="file" onChange={handleFileChange} />
      </div>

      {/* Remaining Fields */}
      {[
        { id: "example", label: "Example", type: "textarea" },
        { id: "level", label: "Level", type: "text" },
        { id: "duration", label: "Duration", type: "text" },
        { id: "tags", label: "Tags (comma-separated)", type: "text" },
        { id: "prerequisites", label: "Prerequisites (comma-separated)", type: "text" },
      ].map(({ id, label, type }) => (
        <div key={id}>
          <Label htmlFor={id}>{label}</Label>
          {type === "textarea" ? (
            <Textarea
              id={id}
              name={id}
              value={formData[id as keyof CourseFormData] as string}
              onChange={handleChange}
              rows={3}
              placeholder={`Enter ${label.toLowerCase()}`}
            />
          ) : (
            <Input
              type={type}
              id={id}
              name={id}
              value={formData[id as keyof CourseFormData] as string}
              onChange={(e) =>
                id === "tags" || id === "prerequisites"
                  ? handleArrayChange(e, id)
                  : handleChange(e)
              }
              placeholder={`Enter ${label.toLowerCase()}`}
            />
          )}
        </div>
      ))}

      {/* Submit Button */}
      <Button type="submit" className="w-full bg-gradient-to-r from-blue-500 to-blue-600">
        Save Content
      </Button>
    </form>
  );
};

export default FormComponent;