import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

interface Blog {
  _id: string;
  title: string;
  description: string;
  images: string;
  category: string;
  createdAt: string;
  authorname: string;
}

const Blogs: React.FC = () => {
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [categories, setCategories] = useState<Blog[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const categoriesList = [
    'All-Category',
    'Digital Marketing',
    'UI/UX Designing',
    'Full-Stack Web Development',
    'Cybersecurity',
    'Degree',
    'Communication Skills',
  ];

  const apiUrl = `${import.meta.env.VITE_APP_API_URL}/user/blog`;

  const fetchBlogs = async () => {
    setLoading(true);
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setBlogs(data.data);
      setCategories(data.data);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryFilter = (index: number) => {
    if (index === 0) {
      setCategories(blogs);
    } else {
      const filteredBlogs = blogs.filter((blog) => blog.category.trim() === categoriesList[index]);
      setCategories(filteredBlogs);
    }
  };

  const generateSlug = (title: string): string => {
    return title
      .replace(/\s+/g, ' ')
      .trim()
      .replace(/[^\w\s-]/g, '-')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-+|-+$/g, '');
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-100">
        loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-100">
        <p className="text-red-500 text-lg">{error}</p>
      </div>
    );
  }

  return (
    <>

<Helmet>
  {/* Character Encoding */}
  <meta charSet="utf-8" />

  {/* Page Title */}
  <title>Blogs | Skillyards - Explore Insights and Ideas</title>

  {/* Compatibility */}
  <meta httpEquiv="x-ua-compatible" content="ie=edge" />

  {/* Viewport Meta */}
  <meta name="viewport" content="width=device-width, initial-scale=1" />

  {/* Meta Description */}
  <meta 
    name="description" 
    content="Explore Skillyards' blogs, where we share insights, trends, and ideas to revolutionize education inspired by our rich cultural history and modern methodologies." 
  />

  {/* Keywords */}
  <meta 
    name="keywords" 
    content="Skillyards blogs, education blogs, modern education, trends in education, Skillyards insights, education powerhouse" 
  />

  {/* Author */}
  <meta name="author" content="Skillyards Team" />

  {/* Open Graph Tags for Social Media */}
  <meta property="og:title" content="Blogs | Skillyards - Explore Insights and Ideas" />
  <meta property="og:description" content="Discover educational insights, trends, and ideas with Skillyards' blogs, inspired by our rich history and modern education methodologies." />
  <meta property="og:image" content="https://www.skillyards.com/assets/images/blog-banner.jpg" />
  <meta property="og:url" content="https://www.skillyards.com/blogs" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="Skillyards" />

  {/* Twitter Card for Social Media */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Blogs | Skillyards - Explore Insights and Ideas" />
  <meta name="twitter:description" content="Stay updated with Skillyards' blogs, featuring trends, insights, and innovative ideas for modern education." />
  <meta name="twitter:image" content="https://www.skillyards.com/assets/images/blog-banner.jpg" />
  <meta name="twitter:site" content="@Skillyards" />

  {/* Favicon and Apple Touch Icons */}
  <link rel="apple-touch-icon" sizes="180x180" href="Web_logo/favicon/apple-touch-icon.png" />
  <link rel="icon" type="image/png" sizes="32x32" href="Web_logo/favicon/favicon-32x32.png" />
  <link rel="icon" type="image/png" sizes="16x16" href="Web_logo/favicon/favicon-16x16.png" />
  <link rel="mask-icon" href="Web_logo/favicon/safari-pinned-tab.svg" color="#5bbad5" />

  {/* Canonical Link */}
  <link rel="canonical" href="https://www.skillyards.com/blogs" />

  {/* Additional Meta Tags */}
  <meta name="robots" content="index, follow" />
</Helmet>

      <div className="bg-gray-50 min-h-screen w-full">
        {/* Breadcrumb Section */}
        <div className="relative flex items-center justify-center h-64 md:h-80 xl:h-96 overflow-hidden mb-12">
          <img
            src="/Web_logo/Background/blog.png"
            alt="Blogs Background"
            className="absolute inset-0 w-full h-full object-cover brightness-50"
          />
          <h1 className="relative z-10 text-4xl md:text-6xl xl:text-7xl font-bold text-white text-center">Blogs</h1>
        </div>

        {/* Recent Blogs Section */}
        <div className="mt-4">
          <h3 className="text-2xl md:text-3xl p-2 lg:text-4xl font-semibold text-gray-800 mb-8 text-left mx-4 mt-4">
            Recent Blog Post
          </h3>
          <div className="mx-auto px-4 md:px-6 lg:px-8 flex flex-col lg:flex-row justify-between gap-6">
            {/* Left Section */}
            <div className="w-full lg:w-[50%]">
              {blogs.slice(0, 1).map((blog) => (
                <Link
                  to={`/blogs/${generateSlug(blog.title)}?id=${blog._id}`}
                  key={blog._id}
                  className="block h-full"
                >
                  <div className="bg-white rounded-xl overflow-hidden transition-all duration-300 group flex flex-col">
                    <div className="relative h-48 sm:h-56 lg:h-64 overflow-hidden flex-shrink-0">
                      <img
                        src={blog.images}
                        alt={blog.title}
                        className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
                      />
                    </div>
                    <div className="p-4 md:p-6 flex-grow flex flex-col justify-between">
                      <div>
                        <h4 className="text-lg md:text-xl lg:text-2xl font-bold text-gray-800 mb-2 md:mb-3 hover:text-blue-600 transition duration-200">
                          {blog.title}
                        </h4>
                        <p className="text-sm md:text-base text-gray-600 mb-3 md:mb-4 line-clamp-2">
                          {blog.description?.substring(0, 150)}
                        </p>
                      </div>
                      <div className="flex items-center justify-between mt-3">
                        <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded">
                          {blog.category}
                        </span>
                        <span className="text-blue-600 hover:text-blue-800 transition duration-300 text-sm">
                          Read More
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>

            {/* Right Section */}
            <div className="w-full lg:w-[50%] flex flex-col gap-4">
              {blogs.slice(2, 4).map((blog) => (
                <Link
                  to={`/blogs/${generateSlug(blog.title)}?id=${blog._id}`}
                  key={blog._id}
                  className="block h-full"
                >
                  <div className="flex flex-col sm:flex-row gap-4">
                    <div className="w-full sm:w-[50%] transition-all duration-300 overflow-hidden">
                      <img
                        className="w-full h-48 md:h-56 object-cover hover:scale-110 transition-transform duration-300"
                        src={blog.images}
                        alt={blog.title}
                      />
                    </div>
                    <div className="w-full sm:w-[50%] flex flex-col gap-3">
                      <div className="flex justify-between items-center">
                        <span className="text-blue-500 font-bold">
                          {new Date(blog.createdAt).toLocaleDateString('en-us', {
                            day: 'numeric',
                            month: 'long',
                          })}
                        </span>
                        <span className="font-bold text-blue-500">{blog.authorname}</span>
                      </div>
                      <h4 className="text-sm lg:text-base font-bold text-gray-800 mb-2 hover:text-blue-600 transition duration-200">
                        {blog.title}
                      </h4>
                      <p className="text-sm text-gray-600 line-clamp-2">
                        {blog.description?.substring(0, 250)}
                      </p>
                      <div className="flex justify-between">
                        <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded">
                          {blog.category}
                        </span>
                        <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded">
                          Read more...
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>

        {/* Blog Categories Section */}
        <div className="m-2 mx-auto px-4 md:px-6 lg:px-8 py-12 bg-gray-100">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-900 mb-8 text-left">Blog Categories</h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 md:gap-6">
            {categoriesList.map((category, index) => (
              <div
                key={index}
                onClick={() => handleCategoryFilter(index)}
                className="bg-white p-3 md:p-4 rounded-xl shadow-md hover:shadow-xl hover:-translate-y-2 transition-all duration-300 cursor-pointer flex items-center justify-center group"
              >
                <p className="text-sm md:text-base text-gray-800 font-semibold group-hover:text-blue-600 transition-colors text-center">
                  {category}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Filtered Blogs Section */}
        <div className="mx-auto px-4 md:px-6 lg:px-8 py-12">
          <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-900 mb-8 text-left">Filtered Blogs</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8 lg:gap-10 auto-rows-fr">
            {(categories.length === 0 ? blogs : categories).map((blog) => (
              <Link
                to={`/blogs/${generateSlug(blog.title)}?id=${blog._id}`}
                key={blog._id}
                className="block h-full"
              >
                <div className="bg-white shadow-lg rounded-xl overflow-hidden transition-all duration-300 hover:shadow-xl group cursor-pointer h-full flex flex-col">
                  <div className="relative h-48 md:h-56 lg:h-64 w-full overflow-hidden flex-shrink-0">
                    <img
                      src={blog.images}
                      alt={blog.title}
                      className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
                    />
                  </div>
                  <div className="p-4 md:p-6 flex-grow flex flex-col justify-between">
                    <div>
                      <h4 className="text-lg md:text-xl lg:text-2xl font-bold text-gray-800 mb-2 md:mb-3 hover:text-blue-600 transition duration-200">
                        {blog.title}
                      </h4>
                      <p className="text-sm md:text-base text-gray-600 mb-3 md:mb-4 line-clamp-2">
                        {blog.description?.substring(0, 150)}
                      </p>
                    </div>
                    <div className="flex items-center justify-between mt-4">
                      <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded">
                        {blog.category}
                      </span>
                      <span className="text-blue-600 hover:text-blue-800 transition duration-300 text-sm">
                        Read More
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;