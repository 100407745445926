import { Helmet } from "react-helmet";
import BgDisplayItems from "../common/BgDisplayItem";
// import DisplayInfo from "../common/DisplayInfo";
import FAQ from "../common/FAQ";
// import ImageDisplays from "../common/ImageDisplays";
import CombinedDisplay from "../common/mergecontentDisplay";

const FrontEndWebDevelopment = () => {
    const staticItem = {
        id: 1,
        title: "Front-End Development Training In Agra!",
       
        
        backgroundImage: "/Web_logo/Background/frontend.png",
    };

    const simpleItem = {
        heading: "Front-End Development Training Program",
        subheading: "Duration: 6 Months Training + 3 Months Internship",
        data: {
            dataHeading1: {
                title: "Basic Fundamentals",
                items: [
                     'Introduction',
                    "Local environment setup",
                    
                ],
            },
            dataHeading2: {
                title: "Development",
                items: [
                    " HTML / HTML5",
                    "CSS / CSS3",
                    "JavaScript",
                    "Bootstrap /Bootstrap5",
                    "JavaScript framework","Global CSS","React JS","React Hooks","Redux","API Integration"," Animated UI"
                ],
            },
            dataHeading3: {
                title: "Project Practice",
                items: [
                    "Industry Level",
                    "Company Projects",
                    
                ],
            },
            dataHeading4: {
                title: "Internship",
                items: [
                    "3 Months paid internship",
                    "Stipend of upto INR 15000",
                    
                ],
            },
           
        },
    };

    const images = [
        "assets/full-stack-webp/pexels-fotios-photos-16129703_3_11zon.webp",
        "assets/full-stack-webp/pexels-goumbik-574073_4_11zon.webp",
        "assets/full-stack-webp/pexels-harold-vasquez-853421-2653362_5_11zon.webp",
        "assets/full-stack-webp/pexels-mati-5952738_6_11zon.webp",
      ];

      const FAQ_ITEMS = [
        {
          question: "Is EMI available in the Front-End Development Training Program?",
          answer: `Yes, EMI option is available in the Front-End Development Training Program so that the students do not feel the need to submit the program fees in one go.`,
        },
        {
          question: "Will I get the opportunity to get mentorship from my senior developers?",
          answer: `Yes, trainees will work with a team of highly experienced developers & trainers on client projects to provide them with guidance and career advice for their journey as a developer.`,
        },
        {
          question: "What type of portfolio will I have by the end of the training program?",
          answer: `Throughout your journey as a trainee at Skillyards, you will get to work on multiple client projects, such as building user-friendly front-end applications and more, which will add to your portfolio for future opportunities.`,
        },
      ];
  return (
    <div>
              <Helmet>
      {/* Character encoding */}
      <meta charSet="utf-8" />

      {/* Page title */}
      <title>Best Front-End Development Training in Agra</title>

      {/* Compatibility with Internet Explorer */}
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />

      {/* Responsive viewport */}
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/* Page description */}
      <meta
        name="description"
        content="Equip yourself with the skills to build real-world projects with our Front-End Development Training in Agra and establish yourself as an expert Front End developer."
      />

      {/* SEO keywords */}
      <meta
        name="keywords"
        content="aspiring front end developer, front end developer in hindi, front end web development with react coursera solutions, freelance front end developer, best front end developer bootcamp, frontend freelance, frontend bootcamp free, fe developer, freelance front end development, front end developer training near me, front end developer training in agra"
      />

      {/* Author */}
      <meta name="author" content="Skillyards" />
      <link rel="canonical" href="https://skillyards.com/front-end-developer-training-in-agra" />
    </Helmet>
            <BgDisplayItems item={staticItem} />
            {/* <div>
                <DisplayInfo item={simpleItem} />
                <ImageDisplays images={images} />
            </div> */}
            <CombinedDisplay item={simpleItem} images={images} />
            <FAQ faqItems={FAQ_ITEMS} />
        </div>
  )
}

export default FrontEndWebDevelopment