import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Input } from "@/components/ui/input"; // shadcn Input
import { Label } from "@/components/ui/label"; // shadcn Label
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"; // shadcn RadioGroup
import { BlogData } from "../../types"; // Import the shared type
import { toast } from "sonner";

interface BlogFormProps {
  blogData: BlogData;
  onChange: (field: keyof BlogData, value: string | File | null) => void; // Allow null for images
}

const BlogForm: React.FC<BlogFormProps> = ({ blogData, onChange }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    onChange(name as keyof BlogData, value);
    toast.success(`Field "${name}" updated successfully!`, {
      duration: 2000, // Show for 2 seconds
    }); // Success toast

  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof BlogData) => {
    const file = e.target.files?.[0] || null; // Allow null for images
    if(file){
      onChange(field, file);
      toast.success(`File for "${field}" uploaded successfully!`, {
        duration: 2000, // Show for 2 seconds
      }); // Success toast
    }else{
      toast.error("No file selected. Please choose a file.", {
        duration: 2000, // Show for 2 seconds
      });
    }
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-lg w-full mx-auto ">
      <h2 className="text-2xl font-semibold text-gray-700 mb-6 text-center">
        Update Blog Info
      </h2>
      <form className="space-y-6">
        {/* Blog Title */}
        <div>
          <Label htmlFor="title">Blog Title</Label>
          <Input
            type="text"
            name="title"
            id="title"
            placeholder="Enter blog title"
            value={blogData.title}
            onChange={handleInputChange}
          />
        </div>

        {/* Keywords */}
        <div>
          <Label htmlFor="krywords">Keywords</Label>
          <textarea
            name="krywords"
            id="krywords"
            placeholder="Enter keywords"
            value={blogData.krywords}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>

        {/* Blog Description */}
        <div>
          <Label htmlFor="description">Blog Short Description</Label>
          <textarea
            name="description"
            id="description"
            placeholder="Blog short description"
            value={blogData.discription}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>

        {/* Image Upload */}
        <div>
          <Label htmlFor="mainImage">Main Image</Label>
          {blogData.images && typeof blogData.images !== "string" && (
            <img
              src={URL.createObjectURL(blogData.images)}
              alt="Main"
              className="w-40 h-40 object-cover rounded-lg mb-4"
            />
          )}
          <Input
            type="file"
            id="mainImage"
            accept="image/*"
            onChange={(e) => handleFileChange(e, "images")}
          />
        </div>

        {/* Image Description */}
        <div>
          <Label htmlFor="imageDescription">Image Description</Label>
          <div className="border border-gray-300 rounded-lg focus-within:ring-2 focus-within:ring-blue-400">
            <CKEditor
              editor={ClassicEditor}
              data={blogData.imagediscription}
              onChange={(_, editor) =>
                onChange("imagediscription", editor.getData())
              }
            />
          </div>
        </div>

        {/* Status */}
        <div>
          <Label>Status</Label>
          <RadioGroup
            value={blogData.status}
            onValueChange={(value: "Approved" | "Not-Approved") =>
              onChange("status", value)
            }
            className="flex space-x-6"
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="Approved" id="approved" />
              <Label htmlFor="approved">Approved</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="Not-Approved" id="not-approved" />
              <Label htmlFor="not-approved">Not-Approved</Label>
            </div>
          </RadioGroup>
        </div>
      </form>
    </div>
  );
};

export default BlogForm;