import { Helmet } from "react-helmet";
import BgDisplayItems from "./common/BgDisplayItem";

const About = () => {
  const staticItem = {
    id: 1,
    title: "About Us",
    subtitle: "With the On-The Job BCA, MCA, or BBA Degree Program",
    description: [
      "Project-based training with Skillyards Club Facilitation",
      "Paid Internship with stipend up to ₹15,000/month",
      "Guaranteed Placement up to 10 LPA in Top MNCs",
    ],
    buttonText: "Join Now",
    buttonAction: "#",
    backgroundImage: "/Web_logo/Background/aboutus.png",
  };

  return (<>

<Helmet>
  {/* Page Title */}
  <title>About Us | Skillyards - Empowering Your Success</title>

  {/* Meta Descriptions */}
  <meta 
    name="description" 
    content="Discover Skillyards' mission to empower individuals and businesses with cutting-edge skills. Learn about our team, vision, and commitment to excellence." 
  />

  {/* Keywords for SEO */}
  <meta 
    name="keywords" 
    content="Skillyards, About Skillyards, IT Company, Skillyards Mission, Skillyards Vision, Skillyards Team, About Skillyards IT" 
  />

  {/* Author */}
  <meta name="author" content="Skillyards Team" />

  {/* Canonical Link */}
  <link rel="canonical" href="https://www.skillyards.com/about" />

  {/* Open Graph (OG) Tags for Social Media */}
  <meta property="og:title" content="About Us | Skillyards - Empowering Your Success" />
  <meta property="og:description" content="Learn about Skillyards, our journey, our mission, and the team working to help you achieve your goals. Visit now to know more!" />
  <meta property="og:image" content="https://www.skillyards.com/assets/images/about-us-banner.jpg" />
  <meta property="og:url" content="https://www.skillyards.com/about" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="Skillyards" />

  {/* Twitter Card for Social Media */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="About Us | Skillyards - Empowering Your Success" />
  <meta name="twitter:description" content="Explore Skillyards' story, mission, and team dedicated to delivering exceptional skill development for businesses and individuals." />
  {/* <meta name="twitter:image" content="https://www.skillyards.com/assets/images/about-us-banner.jpg" /> */}
  <meta name="twitter:site" content="@Skillyards" />

  {/* Favicon */}
  <link rel="icon" href="https://www.skillyards.com/favicon.ico" />

  {/* Additional Meta Tags */}
  <meta name="robots" content="index, follow" />
</Helmet>
    
    <div className="bg-gray-50">
      {/* Hero Section */}
      <div>
        <BgDisplayItems item={staticItem} />
      </div>

      {/* About Section */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <div className="flex flex-col lg:flex-row items-center gap-12">
          {/* Left Side: Image with Hidden Text Description */}
          <div className="w-full lg:w-1/2 relative">
            <img
              src="/Web_logo/Background/aboutoffice.JPG" // Replace with actual image path
              alt="About Skillyards"
              className="w-full h-auto rounded-lg shadow-lg"
            />
            {/* Hidden Text Description */}
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 rounded-lg">
              <p className="text-white text-lg text-center p-4">
                Skillyards is dedicated to bridging the gap between academia and industry by providing hands-on training and guaranteed placements.
              </p>
            </div>
          </div>

          {/* Right Side: Heading, Subheading, and Description */}
          <div className="w-full lg:w-1/2">
            <h1 className="text-4xl font-bold text-gray-800 mb-4">
              About Skillyards
            </h1>
            {/* <h2 className="text-2xl text-gray-600 mb-6"> */}
              {/* Empowering Students for a Brighter Future */}
            {/* </h2> */}
            <p className="text-lg text-gray-600 mb-6">
            Skillyards, a leader in the IT industry, aims to build an IT Ecosystem in the City of Taj, Agra by creating IT experts from scratch and offering them guaranteed In-house/ Industry placements.

While doing this, Skillyards also lends a hand to fill the void between the theoretical & practical development of students, helping them to be industry-ready.

Through On-The Job Degree & On-The Job Training programs, Skillyards provides practical development to students, along with working on their communication skills through regular personality development, public speaking & English language fluency activities in the Skillyards Club Facilitation.

The regular client projects with professional trainers & developers combined with the regularly updated curriculum create an incomparable package of practical learning.


            </p>
            <ul className="space-y-2 mb-6">
              <li className="text-gray-700">
                {/* <span className="mr-2">•</span>  */}
               <p className="text-2xl text-ellipsis"> So if you are looking to craft a new era of the IT industry, then Skill up with Skillyards!</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    </>  );
};

export default About;