import React from 'react';
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import {
  MapPin,
  Phone,
  Mail,
  Facebook,
  Twitter,
  Instagram,
  Linkedin,
  MessageCircleMore,
  ArrowUp,
  Search,
  ExternalLink,
  
} from 'lucide-react';

// Types
interface FooterLink {
  href: string;
  text: string;
}

interface SocialLink {
  icon: typeof Facebook;
  href: string;
  label: string;
}

interface Address {
  icon: typeof MapPin;
  text: string;
}

interface ContactInfo {
  icon: typeof Phone | typeof Mail;
  text: string;
  href: string;
}

// interface FormData {
//   name: string;
//   mobile: string;
//   subject: string;
//   location: string;
//   message: string;
// }

// Constants
const TRAINING_PROGRAMS: FooterLink[] = [
  { href: "foundation-training-program-in-agra", text: "Full-Stack Foundation Program" },
  { href: "full-stack-web-development-training-in-agra", text: "Full-Stack Development Program" },
  { href: "front-end-developer-training-in-agra", text: "Front-End Development Program" },
  { href: "back-end-web-developer-training-in-agra", text: "Back-End Development Program" },
  { href: "graphic-designing-training-program-in-agra", text: "UI/UX Designing Program" },
  { href: "digital-marketing-training-in-agra", text: "Advanced Digital Marketing" },
];

const DEGREE_PROGRAMS: FooterLink[] = [
  { href: "bca-training-program-in-agra", text: "BCA Degree Program" },
  { href: "mca-training-program-in-agra", text: "MCA Degree Program" },
];

const SOCIAL_LINKS: SocialLink[] = [
  { icon: Facebook, href: "https://www.facebook.com/skillyards", label: "Facebook" },
  { icon: Twitter, href: "https://twitter.com/Skillyardss", label: "Twitter" },
  { icon: Instagram, href: "https://www.instagram.com/skillyardss", label: "Instagram" },
  { icon: Linkedin, href: "https://www.linkedin.com/company/skillyards/mycompany", label: "LinkedIn" },
];

const ADDRESSES: Address[] = [
  {
    icon: MapPin,
    text: "Head Office: OC 840-841, Gaur City Centre, near Gaur Chawk, Greater Noida, Uttar Pradesh 203207"
  },
  {
    icon: MapPin,
    text: "Branch Office: D-24, Gailana Rd, behind St. Conrad's School, Nirbhay Nagar, Agra, Uttar Pradesh 282007"
  },
];

const CONTACT_INFO: ContactInfo[] = [
  {
    icon: Phone,
    text: "+91 7060100561-62-63",
    href: "tel:+91 7060100561"
  },
  {
    icon: Mail,
    text: "info@skillyards.com",
    href: "mailto:info@skillyards.com"
  },
];

const Footer: React.FC = () => {
  // const [formData, setFormData] = React.useState<FormData>({
  //   name: '',
  //   mobile: '',
  //   subject: '',
  //   location: '',
  //   message: '',
  // });
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
 // console.log(formData);
  

  React.useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

 
  //   const { name, value } = e.target;
  //   setFormData(prev => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  const LinkList: React.FC<{ title: string; links: FooterLink[] }> = ({ title, links }) => (
    <div className="relative group">
      <div className="absolute -inset-2 bg-gradient-to-r from-pink-600 to-purple-600 rounded-lg blur opacity-0 group-hover:opacity-10 transition duration-1000"></div>
      <h4 className="text-white text-lg font-semibold mb-6 flex items-center gap-2">
        {title}
        <div className="h-1 w-6 bg-gradient-to-r from-pink-500 to-purple-500 rounded"></div>
      </h4>
      <ul className="space-y-3">
        {links.map((link, index) => (
          <li key={index} className="transform hover:translate-x-2 transition-transform duration-300">
            <a 
              href={link.href} 
              className="hover:text-white transition-colors duration-200 flex items-center gap-2 group"
            >
              <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <ExternalLink className="w-4 h-4" />
              </span>
              {link.text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <footer className="relative bg-gradient-to-b from-slate-900 to-slate-950 text-gray-300 overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:14px_24px]"></div>
      
      <div className="relative max-w-7xl mx-auto px-4 py-16">
        {/* Main Grid Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          <div className="space-y-6">
            <h3 className="text-2xl font-bold text-white">Skillyards</h3>
            <p className="text-gray-400 leading-relaxed">
              Empowering the next generation of developers with cutting-edge training and education.
            </p>
            <div className="flex gap-4">
              {SOCIAL_LINKS.map((social, index) => (
                <a
                  key={index}
                  href={social.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2 hover:text-white transition-all duration-300 hover:scale-110 bg-slate-800/50 rounded-lg hover:bg-slate-700/50"
                  aria-label={social.label}
                >
                  <social.icon className="w-5 h-5" />
                </a>
              ))}
            </div>
          </div>

          <LinkList title="Training Programs" links={TRAINING_PROGRAMS} />
          <LinkList title="Degree Programs" links={DEGREE_PROGRAMS} />

          <div className="space-y-6">
            <h4 className="text-white text-lg font-semibold mb-6 flex items-center gap-2">
              Contact Us
              <div className="h-1 w-6 bg-gradient-to-r from-pink-500 to-purple-500 rounded"></div>
            </h4>
            <div className="space-y-4">
              {ADDRESSES.map((address, index) => (
                <div key={index} className="flex gap-3 group">
                  <div className="mt-1 transform group-hover:scale-110 transition-transform duration-300">
                    <address.icon className="w-5 h-5 text-purple-400" />
                  </div>
                  <p className="text-sm">{address.text}</p>
                </div>
              ))}
              {CONTACT_INFO.map((contact, index) => (
                <a 
                  key={index} 
                  href={contact.href}
                  className="flex gap-3 group hover:text-white transition-colors duration-300"
                >
                  <div className="mt-1 transform group-hover:scale-110 transition-transform duration-300">
                    <contact.icon className="w-5 h-5 text-purple-400" />
                  </div>
                  <span className="text-sm">{contact.text}</span>
                </a>
              ))}
            </div>
          </div>
        </div>

        <Separator className="my-12 bg-gray-800" />

        {/* Bottom Section */}
        <div className="flex flex-col md:flex-row justify-between items-center gap-6">
          <div className="flex items-center gap-2">
            <span>Made </span>
          
            <span>by</span>
            <a 
              href="https://www.sndigitech.com" 
              className="text-purple-400 hover:text-purple-300 font-medium"
              target="_blank"
              rel="noopener noreferrer"
            >
              SN Digitech
            </a>
          </div>
          <div className="text-sm text-gray-400">
            © {new Date().getFullYear()} Skillyards. All rights reserved.
          </div>
        </div>
      </div>

      {/* Floating Buttons */}
      <Button
        asChild
        variant="default"
        className="fixed bottom-24 right-6 p-3 rounded-full shadow-lg bg-gradient-to-r from-green-500 to-emerald-500 hover:from-green-600 hover:to-emerald-600 transition-all duration-300 hover:scale-110 border-none"
      >
        <a href="https://wa.link/4krvdq">
          <MessageCircleMore className="w-6 h-6" />
        </a>
      </Button>

      <Button
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        variant="default"
        className={`fixed bottom-6 right-6 p-3 rounded-full shadow-lg bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 transition-all duration-300 hover:scale-110 border-none ${
          isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        <ArrowUp className="w-6 h-6" />
      </Button>

      {/* Search Dialog */}
      <Dialog open={isSearchOpen} onOpenChange={setIsSearchOpen}>
        <DialogContent className="sm:max-w-md bg-slate-900/95 backdrop-blur-lg border-gray-800">
          <div className="relative">
            <Input
              placeholder="Search Here..."
              className="pr-10 bg-slate-800/50 border-gray-700 focus:border-purple-500 text-white"
            />
            <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
        </DialogContent>
      </Dialog>
    </footer>
  );
};

export default Footer;