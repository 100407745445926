import React from "react";

interface GlitchLoaderProps {
  text: string;
}

const GlitchLoader: React.FC<GlitchLoaderProps> = ({ text }) => {
  return (
    <div className="flex items-center justify-center rounded-2xl bg-gray-900 p-4">
      <div className="relative text-white text-xl sm:text-xl md:text-3xl font-bold tracking-wider glitch">
        {text}
        <span
          aria-hidden="true"
          className="absolute inset-0 text-purple-500 opacity-80 animate-glitch"
        >
          {text}
        </span>
        <span
          aria-hidden="true"
          className="absolute inset-0 text-green-500 opacity-80 animate-glitch-reverse"
        >
          {text}
        </span>
      </div>
    </div>
  );
};

export default GlitchLoader;