import { Route, Routes } from "react-router-dom";
import Sidebar from "./sidebar";
import AdminHeader from "./adminHeader";
import Dashboard from "./dashboard";
import StudentList from "./student-list";
import AccountHistory from "./account-history";
import CertificateList from "./certificate-list";
import AdminBlog from "./admin-blog";

import ContactRequest from "./contact-request"
import AddBlog from "./add-blog";
import UpdateBlog from "./blogContentForm/update-blog";
import AdminPost from "./studyMaterial/admin-post";
import DigitalMarketingDashboard from "./digitalMarketing/digital-marketing";

import SendPushNotification from "./notification/send-notification";
import CreateCuopon from "./createCuopon/create-cuopon";
import CreateAptitudeTest from "./aptitudeTest/CreateAptitudeTest";

const route = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/students",
    element: <StudentList/>,
  },
  {
    path: "/account-history",
    element: <AccountHistory/>,
  },
  {
    path: "/certificates",
    element: <CertificateList/>,
  },
  {
    path: "/contact",
    element: <ContactRequest/>,
  },
  {
    path: "/blogs",
    element: <AdminBlog/>,
  },
  {
    path: "/add-blog",
    element:<AddBlog/> ,
  },
  {
    path: "/update-blog",
    element: <UpdateBlog/>,
  },
  {
    path: "/study-add",
    element:<AdminPost/>,
  },
 
  {
    path: "/digitalmarketing-dashboard",
    element: <DigitalMarketingDashboard/>,
  },
  {
    path:"/push-notification",
    element:<SendPushNotification/>
  },
  {
    path:"/create-coupon",
    element:<CreateCuopon/>
  },
  {
    path: "/create-aptitude-test",
    element: <CreateAptitudeTest/>,
}
];

const AdminRoute: React.FC = () => {
  return (
    <div className="flex flex-col h-screen">
      {/* Header */}
      <AdminHeader />

      {/* Main Content Area */}
      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <div className="w-[20%] bg-white border-r">
          <Sidebar />
        </div>

        {/* Routes Content */}
        <div className="w-[80%] mt-[5%] overflow-y-auto p-6">
          <Routes>
            {route.map((elm) => (
              <Route key={elm.path} path={elm.path} element={elm.element} />
            ))}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AdminRoute;