import React, { useState, useEffect, useRef } from 'react';
import { 
  X, 
  GitBranch, 
  Circle, 
  ArrowRight, 
  Code, 
  Zap, 
  Shield, 
  Loader2,
  XCircle,
  Maximize2,
  Minimize2,
  Bot,
  Github,
  FileCode,
  Copy,
  FileBadge,
  
} from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Label } from '@/components/ui/label';
import { Skeleton } from '@/components/ui/skeleton';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Progress } from '@/components/ui/progress';
import { Badge } from '@/components/ui/badge';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

// Type definitions


const api=import.meta.env.VITE_APP_API_URL
interface Template {
  name: string;
  icon: React.ReactNode;
  description: string;
  prompt: string;
}

interface AnalysisResult {
  file: string;
  analysis: string;
  score: number;
  fileSize: number;
}

const GithubRepoAnalyzerChatbot: React.FC = () => {
  // Changed default state to be open
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [repoUrl, setRepoUrl] = useState<string>('');
  const [prompt, setPrompt] = useState<string>('');
  const [isAnalyzing, setIsAnalyzing] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [statusMessage, setStatusMessage] = useState<string>('Enter your GitHub repository URL to analyze it with AI');
  const [results, setResults] = useState<AnalysisResult[]>([]);
  const [error, setError] = useState<string>('');
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string>('general');

  const resultsContainerRef = useRef<HTMLDivElement>(null);
  const lastEventTimeRef = useRef<number | null>(null);
  const loadingTimerRef = useRef<NodeJS.Timeout | null>(null);
  const eventSourceRef = useRef<EventSource | null>(null);
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const templates: Record<string, Template> = {
    general: {
      name: "General Analysis",
      icon: <Code size={16} />,
      description: "Best practices, performance & code improvements",
      prompt: `
        Analyze the following files for best practices, performance optimization, and code improvements.
        For each file, provide a summary of 20-30 words of the analysis, specific areas where the code can be improved,
        and suggest possible improvements. Additionally, if any specific lines of code need improvement,
        mention the file name and the line number. Also include a score (out of 10) reflecting the
        overall quality of the code, based on clarity, maintainability, performance, and adherence to best practices.
        Try to provide summary in less than 50 words.
      `
    },
    performance: {
      name: "Performance Focus",
      icon: <Zap size={16} />,
      description: "Deep dive on performance optimizations",
      prompt: `
        Analyze the following files with a focus on performance optimization.
        Identify bottlenecks, inefficient algorithms, unnecessary re-renders, and any other performance issues.
        For each file, suggest specific optimizations with examples of how the code could be rewritten.
        Rate each file on a performance scale from 1-10 and provide an overall assessment of the codebase's performance.
        Try to provide summary in less than 50 words.
      `
    },
    security: {
      name: "Security Audit",
      icon: <Shield size={16} />,
      description: "Find security vulnerabilities & risks",
      prompt: `
        Perform a security audit on the following files. 
        Identify potential security vulnerabilities such as injection risks, authentication issues,
        data exposure, XSS vulnerabilities, and insecure dependencies.
        For each file, highlight specific security concerns with line numbers, severity level (Low/Medium/High),
        and recommended fixes. Also provide general security best practices that should be implemented.
        Try to provide summary in less than 50 words.
      `
    }
  };

  useEffect(() => {
    if (templates[selectedTemplate]) {
      setPrompt(templates[selectedTemplate].prompt);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (isAnalyzing) {
      setLoading(true);
      loadingTimerRef.current = setInterval(() => {
        const lastEventTime = lastEventTimeRef.current;
        if (lastEventTime && Date.now() - lastEventTime < 3000) {
          setLoading(false);
        } else {
          setLoading(true);
        }
      }, 500);
    } else {
      if (loadingTimerRef.current) {
        clearInterval(loadingTimerRef.current);
        loadingTimerRef.current = null;
      }
      setLoading(false);
    }

    return () => {
      if (loadingTimerRef.current) {
        clearInterval(loadingTimerRef.current);
        loadingTimerRef.current = null;
      }
    };
  }, [isAnalyzing]);

  // Enhanced scrollToBottom function
  useEffect(() => {
    if (isAnalyzing) {
      scrollToBottom();
    }
  }, [results, statusMessage, progress]);

  useEffect(() => {
    if (isAnalyzing) {
      setIsModalOpen(true);
    }
  }, [isAnalyzing]);

  const scrollToBottom = (): void => {
    // For the normal container
    if (resultsContainerRef.current) {
      const container = resultsContainerRef.current;
      container.scrollTop = container.scrollHeight;
    }

    // For the ScrollArea component in the dialog
    if (scrollAreaRef.current) {
      const scrollableArea = scrollAreaRef.current.querySelector('[data-radix-scroll-area-viewport]');
      if (scrollableArea) {
        scrollableArea.scrollTop = scrollableArea.scrollHeight;
      }
    }

    // Adding a small delay to ensure content has rendered
    setTimeout(() => {
      if (resultsContainerRef.current) {
        resultsContainerRef.current.scrollTop = resultsContainerRef.current.scrollHeight;
      }
      
      if (scrollAreaRef.current) {
        const scrollableArea = scrollAreaRef.current.querySelector('[data-radix-scroll-area-viewport]');
        if (scrollableArea) {
          scrollableArea.scrollTop = scrollableArea.scrollHeight;
        }
      }
    }, 50);
  };

  const handleSubmit = async (e?: React.FormEvent): Promise<void> => {
    if (e) e.preventDefault();
    if (!repoUrl || !prompt) {
      setError('Repository URL and prompt are required');
      return;
    }

    setIsAnalyzing(true);
    setResults([]);
    setProgress(0);
    setStatusMessage('Starting analysis...');
    setError('');
    setIsComplete(false);
    setLoading(true);
    lastEventTimeRef.current = null;

    try {
      const response = await fetch(`${api}/analyze/stream`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ repoUrl, prompt }),
      });

      if (response.headers.get('Content-Type') === 'text/event-stream') {
        const reader = response.body!.getReader();
        const decoder = new TextDecoder();
        let buffer = '';

        const processStream = async (): Promise<void> => {
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            
            buffer += decoder.decode(value, { stream: true });
            const lines = buffer.split('\n\n');
            buffer = lines.pop() || '';

            for (const line of lines) {
              processEventLine(line);
            }
          }
        };

        processStream().catch((err: Error) => {
          console.error('Error processing stream:', err);
          setError(`Stream processing error: ${err.message}`);
          setIsAnalyzing(false);
        });
      } else {
        const data = await response.json();
        if (!response.ok) {
          // console.log('check');
          
          throw new Error(data.message || 'Failed to start analysis');
        }
        setupEventSource();
      }
    } catch (err: unknown) {
      console.error('Error starting analysis:', err);
      setError(`Failed to start analysis: ${(err as Error).message}`);
      setIsAnalyzing(false);
    }
  };

  const setupEventSource = (): EventSource => {
    const eventSource = new EventSource(`${api}/analyze/stream`);
    eventSourceRef.current = eventSource;
    
    eventSource.onopen = () => {
      console.log('SSE connection opened');
    };

    eventSource.addEventListener('status', (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      setStatusMessage(data.message);
      lastEventTimeRef.current = Date.now();
      if (data.progress !== undefined) {
        setProgress(data.progress);
      }
      // Scroll after status update
      scrollToBottom();
    });

    eventSource.addEventListener('fileAnalysis', (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      const result = data.result;
      setResults(prev => [...prev, {
        file: result.file,
        analysis: result.analysis,
        score: result.score,
        fileSize: result.fileSize,
      }]);
      lastEventTimeRef.current = Date.now();
      if (data.progress !== undefined) {
        setProgress(data.progress);
      }
      // Scroll after new result
      scrollToBottom();
    });

    eventSource.addEventListener('complete', (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      setStatusMessage(`Analysis completed: ${data.analyzedFiles} files analyzed`);
      setIsComplete(true);
      setIsAnalyzing(false);
      eventSource.close();
      eventSourceRef.current = null;
      // Final scroll after completion
      scrollToBottom();
    });

    eventSource.addEventListener('error', (event: MessageEvent) => {
      const data = event.data ? JSON.parse(event.data) : { error: 'Unknown error' };
      setError(`Analysis error: ${data.details || data.error || 'Unknown error'}`);
      setIsAnalyzing(false);
      eventSource.close();
      eventSourceRef.current = null;
    });

    return eventSource;
  };

  const processEventLine = (line: string): void => {
    const eventMatch = line.match(/event: ([^\n]+)/);
    const dataMatch = line.match(/data: ([^\n]+)/);
    
    if (eventMatch && dataMatch) {
      const eventType = eventMatch[1];
      const data = JSON.parse(dataMatch[1]);
      
      lastEventTimeRef.current = Date.now();
      
      switch (eventType) {
        case 'status':
          setStatusMessage(data.message);
          if (data.progress !== undefined) {
            setProgress(data.progress);
          }
          break;
        case 'fileAnalysis':
          const result = data.result;
          setResults(prev => [...prev, {
            file: result.file,
            analysis: result.analysis,
            score: result.score,
            fileSize: result.fileSize,
          }]);
          if (data.progress !== undefined) {
            setProgress(data.progress);
          }
          break;
        case 'complete':
          setStatusMessage(`Analysis completed: ${data.analyzedFiles} files analyzed`);
          setIsComplete(true);
          setIsAnalyzing(false);
          break;
        case 'error':
          setError(`Analysis error: ${data.details || data.error || 'Unknown error'}`);
          setIsAnalyzing(false);
          break;
      }
      
      // Scroll after any update
      scrollToBottom();
    }
  };

  const stopAnalysis = (): void => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      eventSourceRef.current = null;
    }
    setIsAnalyzing(false);
    setStatusMessage('Analysis stopped by user');
  };

  const formatFileSize = (bytes: number): string => {
    if (bytes < 1024) return bytes + ' B';
    else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + ' KB';
    else return (bytes / 1048576).toFixed(1) + ' MB';
  };

  // const getScoreColor = (score: number): string => {
  //   if (score >= 8) return 'text-green-600';
  //   if (score >= 6) return 'text-yellow-600';
  //   return 'text-red-600';
  // };

  const getBadgeColor = (score: number): string => {
    if (score >= 8) return 'bg-green-100 text-green-800 border-green-300';
    if (score >= 6) return 'bg-yellow-100 text-yellow-800 border-yellow-300';
    return 'bg-red-100 text-red-800 border-red-300';
  };

  return (<>
    <div className="fixed bottom-4 right-4 z-50">
    {!isOpen ? (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button 
              onClick={() => setIsOpen(true)} 
              className="rounded-full size-14 bg-yellow-500 hover:bg-yellow-600 text-white shadow-lg flex items-center justify-center transition-transform hover:scale-105"
              aria-label="Open Code Analyzer"
            >
              <Bot size={24} />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="left">
            <p>Open Code Analyzer</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    ) : (
      <div className={`bg-white rounded-lg shadow-xl flex flex-col transition-all duration-300 ${
        isExpanded ? 'w-full sm:w-96 md:w-[450px] h-[550px]' : 'w-full sm:w-80 md:w-96 h-[480px]'
      } max-w-[95vw] max-h-[90vh] border border-gray-200`}>
        <div className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-white p-3 rounded-t-lg flex justify-between items-center">
          <div className="flex items-center gap-2">
            <div className="bg-white rounded-full p-1">
              <Github size={18} className="text-yellow-600" />
            </div>
            <h3 className="font-semibold text-lg">Skillyard Code Analyzer</h3>
          </div>
          <div className="flex items-center gap-1">
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="hover:bg-yellow-600/50 p-1.5 rounded transition-colors"
              aria-label={isExpanded ? "Minimize" : "Maximize"}
            >
              {isExpanded ? <Minimize2 size={18} /> : <Maximize2 size={18} />}
            </button>
            <button
              onClick={() => setIsOpen(false)}
              className="hover:bg-yellow-600/50 p-1.5 rounded transition-colors"
              aria-label="Close"
            >
              <X size={18} />
            </button>
          </div>
        </div>
  
        <div className={`p-4 flex-1 flex flex-col overflow-hidden ${
          isExpanded ? 'h-[calc(550px-48px)]' : 'h-[calc(480px-48px)]'
        }`}>
          <div className="mb-3 flex items-center gap-2">
            <div className={`rounded-full p-1 flex items-center justify-center ${
              isAnalyzing ? 'bg-blue-100' : 'bg-green-100'
            }`}>
              {isAnalyzing ? (
                <Loader2 size={18} className="animate-spin text-blue-600" />
              ) : (
                <Circle size={10} fill="#10b981" stroke="#10b981" />
              )}
            </div>
            <p className="text-sm font-medium flex-1">{statusMessage}</p>
          </div>
  
          {isAnalyzing && (
            <div className="mb-3">
              <Progress value={progress} className="h-2" />
              <p className="mt-1 text-xs text-gray-500 text-right">{Math.round(progress)}% complete</p>
            </div>
          )}
  
          <ScrollArea className="flex-1 pr-2" ref={resultsContainerRef}>
            <form onSubmit={handleSubmit} className="flex flex-col gap-3">
              <div>
                <Label htmlFor="repoUrl" className="text-sm font-medium mb-1 block">GitHub Repository URL</Label>
                <div className="relative">
                  <div className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500">
                    <GitBranch size={16} />
                  </div>
                  <Input
                    id="repoUrl"
                    type="text"
                    value={repoUrl}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRepoUrl(e.target.value)}
                    placeholder="https://github.com/username/repo"
                    className="w-full pl-8 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
                    disabled={isAnalyzing}
                  />
                </div>
              </div>
  
              <div className="mt-2 mb-2">
                <p className="text-sm font-medium mb-2">Select Analysis Template:</p>
                <RadioGroup 
                  value={selectedTemplate} 
                  onValueChange={setSelectedTemplate}
                  className="space-y-2"
                  disabled={isAnalyzing}
                >
                  {Object.entries(templates).map(([key, template]) => (
                    <div key={key} className="flex items-start p-2 rounded-md hover:bg-gray-50 border border-gray-200 transition-all">
                      <RadioGroupItem value={key} id={key} className="mr-2 mt-0.5" />
                      <div className="flex-1">
                        <div className="flex items-center gap-2">
                          <div className={`p-1 rounded ${
                            key === 'general' ? 'bg-blue-100 text-blue-600' : 
                            key === 'performance' ? 'bg-yellow-100 text-yellow-600' : 
                            'bg-green-100 text-green-600'
                          }`}>
                            {template.icon}
                          </div>
                          <Label htmlFor={key} className="font-medium cursor-pointer">{template.name}</Label>
                        </div>
                        <p className="text-xs text-gray-500 mt-1">{template.description}</p>
                      </div>
                    </div>
                  ))}
                </RadioGroup>
              </div>
  
              <div className="flex gap-2 mt-2">
                <Button
                  type="submit"
                  disabled={isAnalyzing || !repoUrl}
                  className={`bg-yellow-500 hover:bg-yellow-600 text-white flex-1 py-2 transition-colors ${
                    !repoUrl ? 'opacity-70 cursor-not-allowed' : ''
                  }`}
                >
                  {isAnalyzing ? (
                    <>Analyzing <Loader2 size={16} className="ml-2 animate-spin" /></>
                  ) : (
                    <>Analyze <ArrowRight size={16} className="ml-2" /></>
                  )}
                </Button>
                {isAnalyzing && (
                  <Button
                    onClick={stopAnalysis}
                    variant="destructive"
                    className="bg-red-500 hover:bg-red-600 text-white transition-colors"
                  >
                    Stop <XCircle size={16} className="ml-2" />
                  </Button>
                )}
              </div>
  
              {error && (
                <div className="p-2 mt-2 bg-red-50 border border-red-200 rounded-md">
                  <p className="text-red-600 text-sm flex items-center gap-2">
                    <XCircle size={14} />
                    {error}
                  </p>
                </div>
              )}
            </form>
          </ScrollArea>
        </div>
      </div>
    )}
  </div>
  
  <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
    <DialogContent className="max-w-4xl max-h-[90vh] flex flex-col p-0 rounded-lg overflow-hidden sm:w-[95vw] md:w-[90vw] border-0">
      <DialogHeader className="p-4 border-b bg-gradient-to-r from-yellow-500 to-yellow-600 text-white sticky top-0 z-10">
        <DialogTitle className="flex justify-between items-center">
        {error && (
                <div className="p-2 mt-2 bg-red-50 border border-red-200 rounded-md">
                  <p className="text-red-600 text-sm flex items-center gap-2">
                    <XCircle size={14} />
                    {error}
                  </p>
                </div>
              )}
          <div className="flex items-center gap-2">
            <div className="bg-white rounded-full p-1">
              <Github size={18} className="text-yellow-600" />
            
            </div>
            <span>Code Analysis Results</span>
          </div>
          <Button
            variant="ghost"
            onClick={() => setIsModalOpen(false)}
            disabled={isAnalyzing}
            className="text-white hover:bg-yellow-600/50 p-1 h-auto"
          >
            <X size={20} />
          </Button>
        </DialogTitle>
        <div className="mt-2">
          <div className="flex items-center gap-2">
            {isAnalyzing ? (
              <div className="animate-pulse flex items-center gap-2">
                <Loader2 size={16} className="animate-spin" />
                <span className="text-sm">{statusMessage}</span>
              
              </div>
            ) : (
              <div className="flex items-center gap-2">
                {isComplete ? (
                  <Badge className="bg-green-100 text-green-800 border border-green-300 px-2 py-0.5">Complete</Badge>
                ) : (
                  <Badge className="bg-yellow-100 text-yellow-800 border border-yellow-300 px-2 py-0.5">In Progress</Badge>
                )}
                <span className="text-sm">{statusMessage}</span>
              </div>
            )}
          </div>
          {isAnalyzing && (
            <div className="mt-2">
              <Progress value={progress} className="h-2 bg-white/30" />
              <div className="flex justify-between mt-1 text-xs text-white/90">
                <span>Analyzed: {results.length} files</span>
                <span>{Math.round(progress)}% complete</span>
              </div>
            </div>
          )}
        </div>
      </DialogHeader>
  
      <ScrollArea className="flex-1 p-4 overflow-auto" ref={scrollAreaRef}>
        {loading && results.length === 0 && (
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <Card key={i} className="mb-4 overflow-hidden">
                <CardHeader className="pb-2">
                  <div className="flex items-center justify-between">
                    <Skeleton className="h-6 w-40" />
                    <Skeleton className="h-6 w-24" />
                  </div>
                  <Skeleton className="h-4 w-28 mt-1" />
                </CardHeader>
                <CardContent>
                  <Skeleton className="h-4 w-full mb-2" />
                  <Skeleton className="h-4 w-full mb-2" />
                  <Skeleton className="h-4 w-3/4" />
                </CardContent>
              </Card>
            ))}
          </div>
        )}
  
        {results.length > 0 && (
          <div className="mb-4 flex flex-col sm:flex-row gap-3 justify-between items-start sm:items-center">
            <div className="bg-white p-3 rounded-lg border border-gray-200 shadow-sm w-full sm:w-auto">
              <div className="flex items-center gap-4">
                <div className="flex flex-col items-center">
                  <div className="text-2xl font-bold">{results.length}</div>
                  <div className="text-xs text-gray-500">Files</div>
                </div>
                <div className="h-10 border-r border-gray-200"></div>
                <div className="flex flex-col items-center">
                  <div className="text-2xl font-bold">
                    {(results.reduce((acc, result) => acc + result.score, 0) / results.length).toFixed(1)}
                  </div>
                  <div className="text-xs text-gray-500">Avg Score</div>
                </div>
                <div className="h-10 border-r border-gray-200"></div>
                <div className="flex flex-col items-center">
                  <div className="text-2xl font-bold text-green-600">
                    {results.filter(r => r.score >= 8).length}
                  </div>
                  <div className="text-xs text-gray-500">Good</div>
                </div>
                <div className="flex flex-col items-center">
                  <div className="text-2xl font-bold text-yellow-600">
                    {results.filter(r => r.score >= 6 && r.score < 8).length}
                  </div>
                  <div className="text-xs text-gray-500">Average</div>
                </div>
                <div className="flex flex-col items-center">
                  <div className="text-2xl font-bold text-red-600">
                    {results.filter(r => r.score < 6).length}
                  </div>
                  <div className="text-xs text-gray-500">Poor</div>
                </div>
              </div>
            </div>
          </div>
        )}
  
        <div className="grid grid-cols-1 gap-4 ">
          {results.map((result, index) => (
            <Card 
              key={index} 
              className="mb-0 border-l-4 hover:shadow-md transition-shadow" 
              style={{ 
                borderLeftColor: result.score >= 8 ? '#10b981' : result.score >= 6 ? '#f59e0b' : '#ef4444' 
              }}
            >
              <CardHeader className="pb-2 p-3 sm:p-4">
                <CardTitle className="flex flex-col sm:flex-row sm:items-center justify-between text-base gap-2">
                  <div className="flex items-center gap-2 overflow-hidden">
                    <FileCode size={16} className="text-gray-500 flex-shrink-0" />
                    <span className="truncate">{result.file}</span>
                  </div>
                  <Badge className={`${getBadgeColor(result.score)} flex-shrink-0 ml-0 sm:ml-2`}>
                    Score: {result.score}/10
                  </Badge>
                </CardTitle>
                <p className="text-xs text-gray-500">
                  Size: {formatFileSize(result.fileSize)}
                </p>
              </CardHeader>
              <CardContent className="pt-0 p-3 sm:p-4">
                <div className="text-sm whitespace-pre-wrap bg-gray-50 p-3 rounded-md border border-gray-100 overflow-x-auto">
                  {result.analysis}
                </div>
              </CardContent>
              <div className="px-3 sm:px-4 pb-3 sm:pb-4 flex justify-end">
                <Button variant="outline" size="sm" className="text-xs h-7">
                  <Copy size={12} className="mr-1" /> Copy Analysis
                </Button>
              </div>
            </Card>
          ))}
        </div>
  
        {isAnalyzing && results.length > 0 && (
  <div className="flex justify-center my-6  ">
    <div className="flex flex-col  animate-pulse w-full">
     
      <div className="flex flex-col items-center gap-2 ">
        <Skeleton className="h-6 w-[100%] rounded-md" />
        <Skeleton className="h-6 w-[100%] rounded-md" />
        <Skeleton className="h-6 w-[100%] rounded-md" />
      </div>
    </div>
  </div>
)}

  
        {isComplete && results.length === 0 && (
          <div className="text-center py-10">
            <XCircle size={40} className="mx-auto text-gray-400 mb-2" />
            <p className="text-gray-500">No results to display.</p>
            <p className="text-sm text-gray-400 mt-1">Try a different repository or template.</p>
          </div>
        )}
  
        {/* Empty div for scroll anchor */}
        {isAnalyzing && <div id="scroll-anchor" className="h-10"></div>}
      </ScrollArea>
  
      <div className="p-3 sm:p-4 border-t bg-gray-50 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3 sticky bottom-0 z-10">
        <div className="text-xs text-gray-500">
          {results.length > 0 && (
            <div className="flex items-center gap-1">
              <FileBadge size={14} />
              <span>Analyzed {results.length} file{results.length !== 1 ? 's' : ''}</span>
            </div>
          )}
        </div>
        <div className="flex gap-2 w-full sm:w-auto">
          {isAnalyzing ? (
            <Button
              onClick={stopAnalysis}
              variant="destructive"
              size="sm"
              className="h-9 w-full sm:w-auto"
            >
              <XCircle size={16} className="mr-1" /> Stop Analysis
            </Button>
          ) : (
            <>
             
              <Button
                onClick={() => setIsModalOpen(false)}
                variant="secondary"
                size="sm"
                className="h-9 w-1/2 sm:w-auto"
              >
                Close
              </Button>
            </>
          )}
        </div>
      </div>
    </DialogContent>
  </Dialog>
  </>
  );
};

export default GithubRepoAnalyzerChatbot;