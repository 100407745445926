import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetClose, // Import SheetClose
} from "@/components/ui/sheet";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuLink,
} from "@/components/ui/navigation-menu";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Menu, Phone, Mail, Download, MapPin, Bookmark, User, Home, ChevronRight, DownloadCloudIcon } from "lucide-react";
import { cn } from '@/lib/utils';
import { toast } from "sonner";

const API_URL = `${import.meta.env.VITE_APP_API_URL}`;

interface FormData {
  name: string;
  mobile: string;
  subject: string;
  location: string;
  message: string;
}

interface MenuItem {
  label: string;
  path: string;
  subMenu?: MenuItem[];
}

const navigationItems: MenuItem[] = [
  { label: "Home", path: "/" },
  { label: "Study", path: "/study" },
  {
    label: "OJT",
    path: "#",
    subMenu: [
      { label: "Full-Stack Foundation", path: "/foundation-training-program-in-agra" },
      { label: "Full-Stack Development", path: "/full-stack-web-development-training-in-agra" },
      { label: "Front-End Development", path: "/front-end-developer-training-in-agra" },
      { label: "Back-End Development", path: "/back-end-web-developer-training-in-agra" },
      { label: "UI/UX Designing Program", path: "/graphic-designing-training-program-in-agra" },
      { label: "Advanced Digital Marketing Program", path: "/digitalmarketing" }
    ]
  },
  {
    label: "OJD",
    path: "#",
    subMenu: [
      { label: "BCA Degree Program", path: "/bca-training-program-in-agra" },
      { label: "MCA Degree Program", path: "/mca-training-program-in-agra" }
    ]
  },
  { label: "Club Facilitation", path: "/Club" },
  { label: "Blogs", path: "/blogs" },
  { label: "Team", path: "/teams" },
  { label: "About us", path: "/about" },
  { label: "Contact us", path: "/contact" },
  {
    label: "Assignment",
    path: "https://course.skillyards.com",
  
  }
];

const programOptions = [
  "Full-Stack Foundation",
  "Full-Stack Development",
  "Front-End Development",
  "Back-End Development",
  "UI/UX Designing Program",
  "Advanced Digital Marketing",
  "BCA Degree Program",
  "MCA Degree Program",
  "Other"
];

const socialLinks = [
  { icon: "facebook", url: "https://www.facebook.com/skillyards" },
  { icon: "twitter", url: "https://twitter.com/Skillyardss" },
  { icon: "instagram", url: "https://www.instagram.com/skillyardss" },
  { icon: "linkedin", url: "https://www.linkedin.com/company/skillyards/mycompany" }
];

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const TopBarLink: React.FC<{ href: string; icon?: React.ReactNode; text: string }> = ({
    href,
    icon,
    text
  }) => (
    <a
      href={href}
      className="flex items-center space-x-2 text-sm text-white hover:text-yellow-400 transition-colors p-0"
    >
      {icon && <span className="text-yellow-400">{icon}</span>}
      <span>{text}</span>
    </a>
  );

  const BrochureFormDialog: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
      name: "",
      mobile: "",
      subject: "",
      location: "",
      message: ""
    });

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      try {
        const res = await axios.post(`${API_URL}/user/contact`, { formData });
        if (res.status === 201) {
          setFormData({
            name: "",
            mobile: "",
            subject: "",
            location: "",
            message: ""
          });
          setIsOpen(false);
          downloadFile();
        }
      } catch (error) {
        console.error(error);
      }
    };

    return (
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <span  className="text-sm hover:text-yellow-400 cursor-pointer">Download Brochure</span>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[400px]  p-2">
      <DialogHeader>
        <DialogTitle className="text-2xl font-bold flex items-center">
          <Download className=" text-yellow-500" />
          Download Brochure
        </DialogTitle>
      </DialogHeader>

      <form onSubmit={handleSubmit} className="space-y-1 mt-2">
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Full Name</label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <User className="h-5 w-5 text-gray-400" />
            </div>
            <Input
              name="name"
              placeholder="Enter Name"
              className="pl-10"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
              pattern="[A-Za-z\s]+"
            />
          </div>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Mobile Number</label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Phone className="h-5 w-5 text-gray-400" />
            </div>
            <Input
              name="mobile"
              placeholder="1234567890"
              className="pl-10"
              value={formData.mobile}
              onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
              required
              pattern="\d{10}"
              maxLength={10}
            />
          </div>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Program</label>
          <Select
            value={formData.subject}
            onValueChange={(value) => setFormData({ ...formData, subject: value })}
          >
            <SelectTrigger className="w-full">
              <div className="flex items-center gap-2">
                <Bookmark className="h-5 w-5 text-gray-400" />
                <SelectValue placeholder="Select Your Program" />
              </div>
            </SelectTrigger>
            <SelectContent>
              {programOptions.map((option) => (
                <SelectItem key={option} value={option}>
                  {option}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Location</label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MapPin className="h-5 w-5 text-gray-400" />
            </div>
            <Input
              name="location"
              placeholder="Your City"
              className="pl-10"
              value={formData.location}
              onChange={(e) => setFormData({ ...formData, location: e.target.value })}
              required
              pattern="[A-Za-z\s]+"
            />
          </div>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Message (Optional)</label>
          <div className="relative">
            <div className="absolute top-3 left-3 pointer-events-none">
              <Mail className="h-5 w-5 text-gray-400" />
            </div>
            <Textarea
              name="message"
              placeholder="Any specific requirements or questions?"
              className="pl-10 min-h-[100px]"
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
            />
          </div>
        </div>

        <Button 
          type="submit" 
          className="w-full bg-yellow-500 hover:bg-yellow-600 text-white font-medium py-2 flex items-center justify-center gap-2"
        >
          <Download className="h-5 w-5" />
          Download Now
        </Button>
      </form>
    </DialogContent>
      </Dialog>
    );
  };

   const downloadFile = () => {
    toast.success('Broucher is being downloaded... ')
    const link = document.createElement("a");
    link.href = "/Skillyards_Brochure.pdf";
    link.download = "SkillyardsBrochure";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const [activeItem, setActiveItem] = React.useState('');

  return (
    <header className="w-full bg-white shadow-md position sticky top-0 z-50">
      {/* Top Bar */}
      <div className="hidden lg:block">
        <div className="flex justify-end items-center space-x-6 bg-[#313130] text-white px-2">
          <TopBarLink href="/verify" text="Verify Certificate" />
          <BrochureFormDialog />
          <TopBarLink href="/aptitude-test" text="Aptitude Test" />
          <TopBarLink
            href="tel:+91 7060100562"
            icon={<Phone size={16} />}
            text="+91 7060100562"
          />
          <TopBarLink
            href="mailto:info@skillyards.com"
            icon={<Mail size={16} />}
            text="info@skillyards.com"
          />
        </div>
      </div>

      {/* Main Navigation */}
      <div className="border-b">
        <div className="px-2">
          <div className="flex items-center justify-between">
            {/* Logo */}
            <Link to="/" className="flex-shrink-0">
              <img
                src="Web_logo/front_weblogo.webp"
                alt="Skillyards"
                className="h-12 w-auto"
                
              />
            </Link>

            {/* Desktop Navigation */}
            <div className="hidden lg:block">
              <NavigationMenu>
                <NavigationMenuList>
                  {navigationItems.map((item) => (
                    <NavigationMenuItem key={item.label}>
                      {item.subMenu ? (
                        <>
                          <NavigationMenuTrigger>{item.label}</NavigationMenuTrigger>
                          <NavigationMenuContent>
                            <ul className="grid w-[300px] p-2">
                              {item.subMenu.map((subItem) => (
                                <li key={subItem.label}>
                                  <NavigationMenuLink asChild>
                                    <Link
                                      to={subItem.path}
                                      className="block select-none space-y-1 rounded-md p-4 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground hover:bg-yellow-400"
                                      onClick={() => setIsOpen(false)} // Close the menu on click
                                    >
                                      <div className="text-sm font-medium leading-none">
                                        {subItem.label}
                                      </div>
                                    </Link>
                                  </NavigationMenuLink>
                                </li>
                              ))}
                            </ul>
                          </NavigationMenuContent>
                        </>
                      ) : (
                        <NavigationMenuLink asChild>
                          <Link
                            to={item.path}
                            className="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                            onClick={() => setIsOpen(false)} // Close the menu on click
                          >
                            {item.label}
                          </Link>
                        </NavigationMenuLink>
                      )}
                    </NavigationMenuItem>
                  ))}
                </NavigationMenuList>
              </NavigationMenu>
            </div>

            {/* Mobile Menu Button */}
            <Sheet>
      <SheetTrigger asChild className="lg:hidden">
        <Button 
          variant="ghost" 
          size="icon"
          className="hover:bg-gray-100 transition-colors"
        >
          <Menu className="h-6 w-6" />
        </Button>
      </SheetTrigger>
      <SheetContent 
        side="right" 
        className="w-[300px] sm:w-[400px] bg-gradient-to-b from-white to-gray-50"
      >
        <SheetHeader className="border-b pb-4">
          <SheetTitle className="flex items-center gap-2">
            <Home className="h-5 w-5 text-blue-600" />
            <span className="text-xl font-semibold">Skillyards</span>
          </SheetTitle>
        </SheetHeader>

        <nav className="flex flex-col space-y-1 mt-6">
          {navigationItems.map((item) => (
            <div key={item.label} className="group">
              {item.subMenu ? (
                <div className="space-y-1">
                  <button
                    onClick={() => setActiveItem(activeItem === item.label ? '' : item.label)}
                    className="w-full flex items-center justify-between p-3 rounded-lg hover:bg-gray-100 transition-colors"
                  >
                    <span className="font-medium text-gray-700 group-hover:text-blue-600 transition-colors">
                      {item.label}
                    </span>
                    <ChevronRight 
                      className={cn(
                        "h-4 w-4 text-gray-500 transition-transform duration-200",
                        activeItem === item.label && "rotate-90"
                      )} 
                    />
                  </button>
                  
                  <div className={cn(
                    "grid transition-all duration-200",
                    activeItem === item.label ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
                  )}>
                    <div className="overflow-hidden">
                      <div className="pl-4 py-1 space-y-1">
                        {item.subMenu.map((subItem) => (
                          <SheetClose asChild key={subItem.label}>
                            <Link
                              to={subItem.path}
                              className="block p-2 text-sm text-gray-600 rounded-md hover:bg-blue-50 hover:text-blue-600 transition-colors"
                            >
                              {subItem.label}
                            </Link>
                          </SheetClose>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <SheetClose asChild>
                  <Link
                    to={item.path}
                    className="flex items-center p-3 rounded-lg text-gray-700 hover:bg-gray-100 hover:text-blue-600 transition-colors"
                  >
                    {item.label}
                  </Link>
                </SheetClose>
              )}
            </div>
          ))}
        </nav>
      

        <div className="absolute bottom-0 left-0 right-0 p-4 border-t bg-gray-50">
          <SheetClose asChild>
           <div className="text-center flex gap-2 " onClick={downloadFile} >Download Broucher
            <span><DownloadCloudIcon/></span>
           </div>
           
          </SheetClose>
        </div>
      </SheetContent>
    </Sheet>
          </div>
        </div>
      </div>

      {/* Social Links */}
      <div className="hidden lg:flex justify-center border-t">
        {socialLinks.map((link) => (
          <a
            key={link.icon}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-600 hover:text-blue-600 transition-colors"
          >
            <i className={`fa fa-${link.icon}`} />
          </a>
        ))}
      </div>
    </header>
  );
};

export default Header;