import  {  lazy, Suspense } from "react";
import './digitalmarketing.css';

import award from "../../assets/digital_icon/award.webp";
import coin from "../../assets/digital_icon/coin.webp";
import computer from "../../assets/digital_icon/computer.webp";
import gift from "../../assets/digital_icon/gift.webp";
import microchip from "../../assets/digital_icon/microchip.webp";
import supriceBox from "../../assets/digital_icon/surprise-box.webp";

import bg1 from "../../assets/digital_icon/ai_tool.webp";
import bg2 from "../../assets/digital_icon/incorp.webp";
import bg3 from "../../assets/digital_icon/certificate.webp";
import bg4 from "../../assets/digital_icon/Top-Marketing.webp";
import bg5 from "../../assets/digital_icon/bnous.webp";
import bg6 from "../../assets/digital_icon/surprise.webp";
import cr1 from "../../assets/digital_icon/certificate/1.webp";
import cr2 from "../../assets/digital_icon/certificate/2.webp";
import cr3 from "../../assets/digital_icon/certificate/3.webp";

import rupansh from "../../assets/digital_icon/rupansh.webp";

import { Helmet } from "react-helmet";
import { FileText } from "lucide-react";
import { Link } from "react-router-dom";
import OurPartner from "../home/OurPartner";
import TypewriterComponent from "typewriter-effect";
import { Button } from "../ui/button";

// Lazy-loaded components
const WhoCanJoin = lazy(() => import("../fullStackDevelopment/whoCanJoin"));
const FlipCard = lazy(() => import("../fullStackDevelopment/flipingCard"));
const Carousel = lazy(() => import("../fullStackDevelopment/crousel"));
const LearnCard = lazy(() => import("../fullStackDevelopment/learncards"));
const TrainerCard = lazy(() => import("../fullStackDevelopment/trainerSection"));
const FAQComponent = lazy(() => import("../fullStackDevelopment/faqSection"));
const AnimatedCounter = lazy(() => import("../fullStackDevelopment/numberSpinner"));

// Type definitions
interface StatItemProps {
  value: string;
  label: string;
  backValue: string;
}

interface CardData {
  id: number;
  front: string;
  back: string;
  img: string;
  bgimg: string;
}

interface LearnCardData {
  id: number;
  title: string;
  des: { id: number; top: string }[];
}

interface TrainerData {
  id: number;
  name: string;
  des: string;
  img: string;
}

interface FAQData {
  question: string;
  answer: string;
}

// StatItem Component
const StatItem: React.FC<StatItemProps> = ({ value, label, backValue }) => {
  return (
    <div className="bg-[#5E5F5E] w-full rounded-xl gap-3 lg:p-2 shadow-lg text-center p-0 m-0 lg:w-[30%]">
      <p className="text-3xl text-white font-bold m-2 p-0">
        <Suspense fallback={<span>{backValue}</span>}>
          <AnimatedCounter backValue={backValue} value={Number(value)} />
        </Suspense>
      </p>
      <p className="text-xl text-white m-2 p-0">{label}</p>
    </div>
  );
};

// Main Component
const ConnectWithMarketDigital: React.FC = () => {
  // const [fadeOut, setFadeOut] = useState<boolean>(false);
  // const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  // const [show, setShow] = useState<boolean>(false);
  // const [showEnd, setShowEnd] = useState<boolean>(false);
 

  const images = [
    { titel: "Startup India", heading: "Skillyards Versatility is recognised by Startup India", src: cr2, alt: 'Image 1' },
    { titel: "Best Digital Marketing Agency", heading: "SN Digitech, Skilllyards’ Parent Company, was awarded One of the Best Digital Marketing Agencies in New York", src: cr1, alt: 'Image 2' },
    { titel: "MSME", heading: "Skillyards recognized by MSME India for training excellence.", src: cr3, alt: 'Image 2' },
  ];

  // const statsData = [
  //   { value: "15 +", label: "Years in Business" },
  //   { value: "300 +", label: "Happy Clients" },
  //   { value: "30 +", label: "Domain Expertise" },
  //   { value: "100 +", label: "Employees" },
  //   { value: "360°", label: "Integrated Marcom Agency" },
  //   { value: "98 %", label: "Campaign Success Rate" },
  // ];

  const cardData: CardData[] = [
    { id: 1, front: "AI Incorporation", back: "AI Incorporation", img: microchip, bgimg: bg1 },
    { id: 2, front: "AI Tools", back: "20+ Free AI Tools to maximise productivity in marketing", img: computer, bgimg: bg2 },
    { id: 3, front: "Certification", back: "Free Certification Upon Completing the Workshop", img: award, bgimg: bg3 },
    { id: 4, front: "Paid Tools for FREE", back: "Chance to get access to 5+ Paid Tools for Free", img: coin, bgimg: bg4 },
    { id: 5, front: "Bonus Templates", back: "Multiple Marketing Calendars to save time arranging everything", img: gift, bgimg: bg5 },
    { id: 6, front: "Surprise Bonuses", back: "Unique Surprise Bonuses for Top Participants", img: supriceBox, bgimg: bg6 },
  ];

  const learnCards: LearnCardData[] = [
    {
      id: 1,
      title: "What is Digital Marketing?",
      des: [
        { id: 11, top: "What is Digital Marketing?" },
        { id: 222, top: "Scope of Digital Marketing" },
        { id: 333, top: "Introduction to Ads" },
        { id: 444, top: "Introduction to Tools" },
        { id: 555, top: "Introduction to Landing Pages" },
      ],
    },
    {
      id: 2,
      title: "Meta Ads Audit",
      des: [
        { id: 666, top: "Introduction to Meta Ads Auditing" },
        { id: 777, top: "Key Metrics for Meta Ads Audit" },
        { id: 888, top: "Tools for Meta Ads Audits" },
        { id: 999, top: "Common Issues and Fixes" },
        { id: 1000, top: "Preparing Meta Ads Audit Report" },
      ],
    },
    {
      id: 3,
      title: "Website Audit",
      des: [
        { id: 1010, top: "Introduction to Website Audits" },
        { id: 1111, top: "Technical Audit" },
        { id: 1212, top: "SEO Audit" },
        { id: 1313, top: "Content Audit" },
        { id: 1414, top: "Performance and Security Audit" },
      ],
    },
  ];

  const trainer: TrainerData[] = [
    {
      id: 1,
      name: "Mr. Rupansh Srivastava",
      des: "Mr. Rupansh Srivastava is a skilled Digital Marketing Specialist and Trainer at Skillyards. With more than 5 years of experience and over 100 clients served, he offers practical knowledge and expert guidance.",
      img: rupansh,
    },
  ];

  const faqs: FAQData[] = [
    {
      question: "What will I learn in this website audit with AI workshop?",
      answer: "In this workshop, you'll learn how to use artificial intelligence tools to audit your website comprehensively. We'll cover how to analyze website performance, improve SEO, and enhance user experience. By the end, you'll be able to identify and fix issues to make your website more effective and user-friendly.",
    },
    {
      question: "Do I need any prior knowledge of website audits or AI?",
      answer: "No prior knowledge is needed! This workshop is designed for beginners. We'll start with the basics of website audits in a simple, easy-to-understand way. You'll be guided step-by-step, so everyone can follow along and learn.",
    },
    {
      question: "How can housewives benefit from this AI workshop?",
      answer: "Housewives can learn valuable skills to improve their personal blogs or small business websites. This workshop will teach you how to use AI to check your website's performance, enhance its visibility on search engines, and create a better visitor experience. It's a great way to make your online presence more effective and even attract more customers.",
    },
  ];

  // const handleSubmit = (e: React.FormEvent) => {
  //   e.preventDefault();
  //   setIsSubmitting(true);
  //   setTimeout(() => {
  //     setIsSubmitting(false);
  //   }, 2000);
  // };

  // const handleClose = () => setShow(false);
  // const showOpen = () => setShow(true);

  

  // const openM = () => {
  //   setShowEnd(true);
  // };
  const badge=['Digital Marketing', 'SEO', 'Social Media', 'Content Strategy', 'Analytics']
  return (
    <div>
      <Helmet>
        <title>Digital Marketing Training | Learn AI-Powered Marketing</title>
        <meta name="description" content="Master digital marketing with AI integration. Join our workshop to learn SEO, WordPress, Shopify, and social media marketing from industry experts." />
       
        <meta name="keywords" content="digital marketing, AI marketing, SEO training, WordPress development, social media marketing" />
        <link rel="canonical" href="https://skillyards.com/digitalmarketing" />
      </Helmet>
      <div className={` transition-opacity duration-500 ease-out`}>
        {/* Hero Section */}
        <div className="relative p-2 md:h-[60vh] lg:min-h-[100vh]">
          <div className="absolute inset-0 h-[50vh] mt-[100px] lg:mt-0 md:h-[60vh] lg:h-full bg-cover bg-center background-image"></div>
          <div className="absolute inset-0"></div>
          <div className="relative z-10 flex flex-col justify-center text-gray-100 top-[20px] left-0 md:top-20 lg:left-3 lg:pt-[10%]">
            <div className="flex flex-col text-center mt-5 sm:p-6 mb-8 sm:mb-0">
              <p className="mb-2 px-2 text-gray-800 text-2xl leading-tight tracking-tight xs:text-3xl sm:text-6xl md:text-3xl lg:text-4xl font-extrabold font-montserrat text-left">
                Master the Skills using AI <br /> needed to become a successful
              </p>
              <p className="text-xl leading-snug xs:text-2xl sm:text-6xl md:text-2xl lg:text-3xl font-bold px-2 text-[#FFB93F] font-montserrat text-left">
                <TypewriterComponent
                  options={{
                    strings: [
                      "SEO EXPERT",
                      "WORDPRESS DEVELOPER",
                      "SHOPIFY DEVELOPER",
                      "PERFORMANCE MARKETER",
                      "SOCIAL MEDIA MARKETER",
                    ],
                    autoStart: true,
                    loop: true,
                    delay: 50,
                    deleteSpeed: 30,
                  }}
                />
              </p>
            </div>
          </div>
        </div>

        {/* Integrate AI with Digital Marketing Section */}
        <div className="mt-[330px] sm:mt-[100px] lg:mt-0">
          <section className="bg-[#414047] py-16 md:py-20 lg:py-20">
            <div className="max-w-7xl mx-auto px-6 sm:px-8">
              <header className="text-center mb-16 md:mb-20">
                <div className="flex flex-col gap-2 text-center">
                  <h1 className="font-montserrat text-4xl sm:text-5xl md:text-6xl lg:text-5xl text-white font-bold tracking-wide leading-tight">
                    Integrate AI with Digital Marketing
                  </h1>
                  <p className="text-lg sm:text-xl md:text-2xl lg:text-xl leading-relaxed font-montserrat text-white/90 mt-4 md:mt-6 text-left w-full">
                    In a survey by Foundation AI, more than <span className="font-semibold">87% of marketers</span> use AI in their processes.
                    AI isn't a threat to marketers unless you know how to use it. Integrating AI Tools, Charts, and Tactics can turn out to
                    be strenuous for digital marketers who are just starting out their journeys.
                  </p>
                </div>
              </header>
              <div className="flex flex-wrap sm:flex-wrap justify-center gap-6 p-0 m-0 md:my-5 lg:my-2">
                <StatItem value="5" backValue="+ Years" label="Highly Experienced Trainers" />
                <StatItem value="100" backValue="+" label="Clients Served by Trainers" />
                <StatItem value="95" backValue="%" label="Campaign Success Rate" />
              </div>
            </div>
          </section>
        </div>

        {/* Benefits Section */}
        <section className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 md:py-16">
          <div className="flex flex-col gap-6 sm:gap-8 md:gap-12">
            <div className="text-center flex flex-col justify-center items-center gap-1">
              <h2 className="font-bold text-3xl sm:text-3xl md:text-3xl lg:text-3xl mb-0">
                Benefits You'll Get From Workshop
              </h2>
              <div className="border-[3px] w-[30%] sm:w-[40%] md:w-[30%] lg:w-[20%] border-yellow-400 mt-2" />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6 sm:gap-8 lg:gap-y-32">
              {cardData.map((elm) => (
                <div key={elm.id} className="flex justify-center items-center">
                  <Suspense fallback={<div>Loading...</div>}>
                    <FlipCard data={elm} />
                  </Suspense>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Carousel Section */}
        <div className="flex flex-col-reverse md:flex-row justify-center items-center gap-5 p-4 md:p-6 mt-4">
          <Suspense fallback={<div>Loading...</div>}>
            <div className="w-full">
              <Carousel images={images} />
            </div>
          </Suspense>
        </div>

        {/* Our Partner Section */}
        <div>
          <Suspense fallback={<div>Loading...</div>}>
            <OurPartner />
          </Suspense>
        </div>

        {/* What You'll Learn Section */}
        <div className="flex flex-col my-12 m-1">
          <div className="text-center mt-2 flex flex-col justify-center items-center gap-1">
            <h2 className="text-3xl sm:text-3xl md:text-3xl lg:text-3xl mb-0">What you'll Learn</h2>
            <div className="border-[3px] w-[30%] sm:w-[40%] md:w-[30%] lg:w-[20%] border-yellow-400 mt-2" />
          </div>
          <div className="flex flex-wrap justify-evenly gap-4 mt-5">
            {learnCards.map((elm) => (
              <div key={elm.id} className="w-full md:w-1/3 lg:w-1/4 p-2">
                <Suspense fallback={<div>Loading...</div>}>
                  <LearnCard data={elm} />
                </Suspense>
              </div>
            ))}
          </div>
        </div>

        {/* Trainer Section */}
        <div className="mx-0 px-0 sm:px-6 lg:px-10 py-8">
          <div className="text-center mb-8">
            <h2 className="font-bold text-3xl">Meet Our Trainer</h2>
            <div className="border-[3px] border-yellow-400 w-32 sm:w-40 lg:w-48 mx-auto mt-2" />
          </div>
          <div className="px-2">
            {trainer.map((elm) => (
              <div key={elm.id} className="flex justify-center items-center lg:px-12">
                <Suspense fallback={<div>Loading...</div>}>
                  <TrainerCard badge={badge} name={elm.name} des={elm.des} img={elm.img} />
                </Suspense>
              </div>
            ))}
          </div>
        </div>

        {/* Who Can Join Section */}
        <div className="text-center font-montserrat mt-5">
          <div className="mt-5">
            <Suspense fallback={<div>Loading...</div>}>
              <WhoCanJoin />
            </Suspense>
          </div>
        </div>

        {/* Syllabus Section */}
        <div className="flex justify-center items-center p-2 sm:p-4 m-2 sm:m-4 mt-4">
    <Link to="/digital-marketing-training-in-agra" className="relative group">
      <div className="absolute -inset-1 bg-gradient-to-r from-amber-300 via-yellow-400 to-amber-500 rounded-lg blur opacity-70 group-hover:opacity-100 transition duration-500"></div>
      <Button className="relative rounded-3xl flex items-center gap-3 px-6 sm:px-10 py-3 sm:py-4 bg-gradient-to-r from-amber-300 via-yellow-400 to-amber-200 text-gray-900 text-lg sm:text-xl font-medium hover:scale-105 active:scale-95 transition">
        <FileText className="w-5 h-5 sm:w-6 sm:h-6 transition-transform group-hover:rotate-12 group-hover:scale-110" />
        View Syllabus
      </Button>
    </Link>
  </div>

        {/* FAQ Section */}
        <div className="flex justify-center items-center">
          <div className="w-[90%]">
            <Suspense fallback={<div>Loading...</div>}>
              <FAQComponent faqs={faqs} />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectWithMarketDigital;