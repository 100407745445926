import React, { useState } from "react";
import { User, LogOut, Key, Loader2 } from "lucide-react";
import { toast } from "sonner";
import OTPModal from "../../lib/otp";
import axios from "axios";

const api = `${import.meta.env.VITE_APP_API_URL}`;

const AdminHeader: React.FC = () => {
  const [profileVisible, setProfileVisible] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendingOtpEmail = async () => {
    setIsLoading(true);
    try {
      const res = await axios.post(`${api}/admin/forgotPassword`);
      if (res.data.error===false) {
        toast.success("OTP sent to your email address");
        setShowConfirmDialog(false);
        setShowOTPModal(true);
      } else {
        throw new Error(res.data.message || "Failed to send OTP");
      }
    } catch (error) {
      toast.error(error instanceof Error ? error.message : "Failed to send OTP");
      setShowConfirmDialog(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handlerLogOut = () => {
    localStorage.removeItem("token_tr");
    localStorage.removeItem("name");
    toast.success("Logged out successfully!");
    setTimeout(() => {
      window.location.href = "/admin/login";
    }, 500);
  };

  const toggleProfileVisibility = () => {
    setProfileVisible(!profileVisible);
  };

  const handleOTPVerify = async (otp: string, email: string, password: string, confirmPassword: string) => {
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
  
    try {
      // Send OTP, email, and new password to the backend for verification and password reset
      const resetRes = await axios.patch(`${api}/admin/resetPassword`, {
        otp,
        email,
         password
      });
  
      if (resetRes.data.error===false) {
        toast.success("Password reset successfully!");
        setProfileVisible(false);
        setShowOTPModal(false);
       // setShowPasswordResetModal(false);
      } else {
        throw new Error(resetRes.data.message || "Failed to reset password");
      }
    } catch (error) {
      toast.error(error instanceof Error ? error.message : "Failed to reset password");
    }
  };
  
  return (
    <>
      <div className="fixed top-0 left-0 w-full bg-yellow-400 flex justify-between items-center p-3 z-40 shadow-md">
        <div className="flex items-center gap-4">
          <div className="flex items-center">
            <img
              src="../Web_logo/front_weblogo.jpeg"
              alt="logo"
              className="w-36"
            />
          </div>
        </div>
        <div className="flex items-center gap-6">
          <div className="relative">
            <button
              onClick={toggleProfileVisibility}
              className="p-2 hover:bg-yellow-500 rounded-full transition-colors"
            >
              <User size={24} />
            </button>
            {profileVisible && (
              <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg divide-y divide-gray-100">
                <div className="p-4">
                  <span className="font-semibold text-gray-900">Administrator</span>
                </div>
                <div className="p-2">
                  <button
                    onClick={() => setShowConfirmDialog(true)}
                    className="w-full flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-lg transition-colors"
                  >
                    <Key size={18} />
                    <span>Change Password</span>
                  </button>
                  <button
                    onClick={handlerLogOut}
                    className="w-full flex items-center gap-2 px-4 py-2 text-sm text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                  >
                    <LogOut size={18} />
                    <span>Logout</span>
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="text-lg font-semibold">Admin</div>
        </div>
      </div>

      {showConfirmDialog && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-semibold mb-4">Change Password</h2>
            <p className="mb-4">
              We'll send an OTP to your registered email address to verify it's you.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowConfirmDialog(false)}
                disabled={isLoading}
                className="px-4 py-2 text-gray-700 hover:bg-gray-50 rounded-lg transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={sendingOtpEmail}
                disabled={isLoading}
                className="px-4 py-2 bg-blue-600 text-white hover:bg-blue-700 rounded-lg transition-colors flex items-center"
              >
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Sending OTP...
                  </>
                ) : (
                  "Send OTP"
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {showOTPModal && (
        <OTPModal
          onVerify={handleOTPVerify}
          onCancel={() => {
            setShowOTPModal(false);
            setProfileVisible(false);
          }}
        />
      )}
    </>
  );
};

export default AdminHeader;
