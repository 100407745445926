import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Percent } from 'lucide-react';
import axios from 'axios';

const api=`${import.meta.env.VITE_APP_API_URL}`

// Define course type options
type CourseType = 'fullstackCourse'  | 'digitalmarketing';

// Interface for form data
interface FormData {
  adminId: string;
  courseType: CourseType | '';
  couponCode: string;
  discountPercentage: string;
  expiryDate: string;
}

const CreateCoupon: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    adminId: "66bda2779a616306a54c19c5", // Predefined
    courseType: '',
    couponCode: '',
    discountPercentage: '',
    expiryDate: '',
  });

  const handleSubmit =async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    
     try {
      await axios.post(`${api}/admin/add-coupon`, formData)
      
     } catch (error) {
        console.log(error);
        
     }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Get today's date in YYYY-MM-DD format for min date attribute
  const today = new Date().toISOString().split('T')[0];

  return (
    <Card className="w-full ">
      <CardHeader>
        <CardTitle>Create New Coupon</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Admin ID - Readonly */}
          <div className="space-y-2">
            <Label htmlFor="adminId">Admin ID</Label>
            <Input
              id="adminId"
              value={formData.adminId}
              readOnly
              className="bg-gray-100"
            />
          </div>

          {/* Course Type - Select */}
          <div className="space-y-2">
            <Label htmlFor="courseType">Course Type</Label>
            <Select 
              value={formData.courseType}
              onValueChange={(value: CourseType) => setFormData(prev => ({ ...prev, courseType: value }))}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select course type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="digitalmarketing">Digital-Marketing</SelectItem>
                <SelectItem value="fullstack">FullStack-Course</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {/* Coupon Code */}
          <div className="space-y-2">
            <Label htmlFor="couponCode">Coupon Code</Label>
            <Input
              id="couponCode"
              name="couponCode"
              value={formData.couponCode}
              onChange={handleInputChange}
              placeholder="Enter coupon code"
            />
          </div>

          {/* Discount Percentage */}
          <div className="space-y-2">
            <Label htmlFor="discountPercentage">Discount Percentage</Label>
            <div className="relative">
              <Input
                id="discountPercentage"
                name="discountPercentage"
                type='number'
                min="0"
                max="100"
                value={formData.discountPercentage}
                onChange={handleInputChange}
                className="pr-8"
                placeholder="Enter discount percentage"
              />
              <Percent className="absolute right-2 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>

          {/* Expiry Date */}
          <div className="space-y-2">
            <Label htmlFor="expiryDate">Expiry Date</Label>
            <Input
              id="expiryDate"
              name="expiryDate"
              type="date"
              value={formData.expiryDate}
              onChange={handleInputChange}
              min={today}
              className="w-full"
            />
          </div>

          {/* Submit Button */}
          <Button type="submit" className="w-full">
            Create Coupon
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default CreateCoupon;