import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../components/Home";
import FullstackFoundationTrainingCOM from "@/components/ojt/FullstackFoundationTrainingCOM";
import FrontEndWebDevelopment from "@/components/ojt/FrontEndWebDevelopment";
import BackEndWebDevelopment from "@/components/ojt/BackEndWebDevelopment";
import UIUXDesign from "@/components/ojt/UIUXDesign";
import BCA from "@/components/ojd/BCA";
import MCA from "@/components/ojd/MCA";
import Club from "@/components/Club";
import About from "@/components/About";
import ContactUs from "@/components/ContactUs";
import Team from "@/components/Team";
import Blogs from "@/components/Blogs";
import SingleBlogPage from "@/components/blog/SingleBlog";
import StudyMaterial from "@/components/StudyMaterial";
import SingleCategoryContent from "@/components/study/SingleCategoryContent";
import ContentInfo from "@/components/study/ContentInfo";
import AdminLogin from "@/components/admin/adminLogin";
import AdminRoute from "@/components/admin/adminRoute";
import ConnectWithMarket from "@/components/fullStackDevelopment/fullStackLanding";
import ConnectWithMarketDigital from "@/components/digitalMarketing/digitalMarketingLanding";
import DigitalMarketing from "@/components/ojt/digitalMarketing";
import AutoDownload from "@/components/AutoDownload";
import AnalysisResults from "@/lib/ai-analysis";
import AptitudeTest from "@/components/AptitudeTest";
import SingleAptitudeTest from "@/components/aptitude/SingleAptitudeTest";
import TestResults from "@/components/aptitude/TestResult";

import { PrivateRoute } from "@/lib/private-route";


export default function AllRoutes() {
  const routes = [
  
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/foundation-training-program-in-agra",
      element: <FullstackFoundationTrainingCOM />,
    },
    {
      path: "/digital-marketing-training-in-agra",
      element: <DigitalMarketing />,
    },
    {
      path: "/full-stack-web-development-training-in-agra",
      element: <ConnectWithMarket />,
    },
    {
      path: "/front-end-developer-training-in-agra",
      element: <FrontEndWebDevelopment />,
    },
    {
      path: "/back-end-web-developer-training-in-agra",
      element: <BackEndWebDevelopment />,
    },
    {
      path: "/graphic-designing-training-program-in-agra",
      element: <UIUXDesign />,
    },
    {
      path: "/digitalmarketing",
      element: <ConnectWithMarketDigital />,
    },
    {
      path: "/mca-training-program-in-agra",
      element: <BCA />,
    },
    {
      path: "/bca-training-program-in-agra",
      element: <MCA />,
    },
    {
      path: "/Club",
      element: <Club />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/contact",
      element: <ContactUs />,
    },
    {
      path: "/teams",
      element: <Team />,
    },
    {
      path: "/blogs",
      element: <Blogs />,
    },
    {
      path: "/blogs/:titel",
      element: <SingleBlogPage />,
    },
    {
      path: "/study",
      element: <StudyMaterial />,
    },
    {
      path: "/full-stack-found-pdf",
      element: <AutoDownload />,
    },
    {
      path: "/full_stack_pdf",
      element: <AutoDownload />,
    },
    {
      path: "/Ux-pdf",
      element: <AutoDownload />,
    },
    {
      path: "/digital-marketing-pdf",
      element: <AutoDownload />,
    },
    {
      path: "/front-end-pdf",
      element: <AutoDownload />,
    },
    {
      path: "/back-end-pdf",
      element: <AutoDownload />,
    },
    {
      path: "/study/:subject",
      element: <SingleCategoryContent />
    },
    {
      path: "/study/:subject/content/:titel",
      element: <ContentInfo />,
    },
    {
      path: "/admin/login",
      element: <AdminLogin />,
    },
    {
      path: "/app/*",
      element: <AdminRoute />
    },
    {
      path:"/ai-result",
      element:<AnalysisResults/>
    },
    {
      path: "/aptitude-test",
      element: <AptitudeTest />
    },
    {
      path: "/aptitude-test/:id",
      element:<PrivateRoute><SingleAptitudeTest /></PrivateRoute>
    },{
      path: '/aptitude-test-result/:id',
      element: <TestResults />
    }
  ];

  return (
    <Suspense fallback={<>...loading</>}>
      
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
      
    </Suspense>
  );
}