import { Helmet } from "react-helmet";
import BgDisplayItems from "../common/BgDisplayItem";
import FAQ from "../common/FAQ";
import SemesterInfoCard from "../common/SemesterInfoCard";

const BCA = () => {
  const bcaInfo = {
    semester1: {
      image: "assets/images/courses/python.png", // Replace with actual image path
      heading: "MCA Semester 1",
      subheading: "Introduction to Programming and Computer Fundamentals",
      data: [
        "Fundamentals of Computer ( Python Programming )",
        "Basic Computer Operations",
        "Basics of Networking",
        "Understanding of Operating System",
        "System architecture",
        "Design Systems",
        "OOPS Programming using C++ / C",
        "Web Dev using HTML/CSS",
        "Javascript",
      ],
    },
    semester2: {
      image: "assets/images/courses/database.jpg", // Replace with actual image path
      heading: "MCA Semester 2",
      subheading: "Data Structures and Algorithms",
      data: [
        "PHP/Laravel",
        "Node JS",
        "Introduction to RDBMS",
        "MySQL",
        "Mongo DB",
      ],
    },
    semester3: {
      image: "assets/images/courses/course6.png", // Replace with actual image path
      heading: "MCA Semester 3",
      subheading: "Database Management Systems",
      data: [
        "Front-end based projects",
        "Dev Tools ( docker , github, Kubernetes, AWS, GCP & Azure )",
        "Working with Rest APIs",
        "API Development & Implementation",
        "Exception handling",
      ],
    },
    semester4: {
      image: "assets/images/courses/course2.png", // Replace with actual image path
      heading: "MCA Semester 4",
      subheading: "Project Based Learning",
      data: [
        "Full Stack based projects",
        "Implementation & Deployement",
        "Cloud Handling / File Management",
        "Global Coding Standards ( GCS )",
        "User Experience Design",
        "Prototype to Code",
        "Framework Standards",
      ],
    },
    // Add more semesters as needed
  };

  const FAQ_ITEMS = [
    {
      question: "Is EMI available in the On-The Job MCA Degree Program?",
      answer: `Yes, there is an EMI option available for the On-The-Job MCA Program, helping students to avoid feeling the pressure to pay for the program in one go.`,
    },
    {
      question: "Is the curriculum similar to the one taught in the colleges?",
      answer: `The curriculum for not only the On-The Job MCA Degree Program, but for all programs, is designed and regularly updated by our team of experienced developers according to the industry norms and standards. This allows trainees to be regularly aware of the new updates and changes.`,
    },
    {
      question:
        "Are there opportunities to meet new people and grow my network available?",
      answer: `Yes, trainees work on projects with regular contact with international and national clients, this helps them build a better network. Also, the Skillyards Club Facilitation helps to contribute to trainees’ network as they engage in regular personality development, leadership skill, public speaking, and language fluency activities with industry professionals and leaders.`,
    },
  ];

  const staticItem = {
    id: 1,
    title: "Get Placed in Top MNCs!",
    subtitle: "With the On-The Job BCA, MCA, or BBA Degree Program",
    description: [
      "Project-based training with Skillyards Club Facilitation",
      "Paid Internship with stipend up to ₹15,000/month",
      "Guaranteed Placement up to 10 LPA in Top MNCs",
    ],
    buttonText: "Join Now",
    buttonAction: "#",
    backgroundImage: "Web_logo/Background/mca.png",
  };

  // Additional information sections
  const additionalInfo = [
    {
      subheading: "Why Choose Skillyards?",
      items: [
        "Industry-aligned curriculum",
        "Experienced mentors and trainers",
        "Guaranteed placements in top MNCs",
      ],
    },
    {
      subheading: "Key Features of the Program",
      items: [
        "Hands-on project-based learning",
        "Paid internships with stipends",
        "Certifications from industry leaders",
      ],
    },
    {
      subheading: "Skillyards SkillPresent",
      items: [
        "Technical Search Engine Optimization (SEO)",
        "How does Artificial Intelligence work in websites",
      ],
    },
    {
      subheading: "Additional Advantages",
      items: [
        "6 months paid internship as a Junior Developer Intern",
        "Stipend of up to ₹15,000/ month",
        "Guaranteed in-house placement with a package of up to 10 LPA",
      ],
    },
  ];

  // Convert the bcaInfo object into an array of semesters
  const semesters = Object.values(bcaInfo);

  return (
    <div>
      <Helmet>
        {/* Character Encoding */}
        <meta charSet="utf-8" />
        <title>
          MCA Degree Program in Agra | UGC Certified with Placements Up to 10
          LPA
        </title>

        {/* Browser Compatibility */}
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Meta Description */}
        <meta
          name="description"
          content="Advance your career with the Best MCA Degree Program in Agra. Enroll at a UGC A+ Grade Certified University with guaranteed placements up to 10 LPA. Join Skillyards and excel in IT and software development!"
        />

        {/* Keywords */}
        <meta
          name="keywords"
          content="MCA Degree Program Agra, Best MCA College Agra, UGC Certified MCA Agra, Skillyards MCA Course, MCA Placement Agra, Software Development Course Agra"
        />

        {/* Author */}
        <meta name="author" content="Skillyards" />

        {/* Canonical URL */}
        <link
          rel="canonical"
          href="https://skillyards.com/mca-training-program-in-agra"
        />

        {/* Open Graph (OG) Meta Tags */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="MCA Degree Program in Agra | UGC Certified with Placements Up to 10 LPA"
        />
        <meta
          property="og:description"
          content="Join the top MCA Degree Program in Agra at a UGC A+ Grade Certified University. Guaranteed placements up to 10 LPA with Skillyards."
        />
        <meta
          property="og:url"
          content="https://skillyards.com/mca-training-program-in-agra"
        />
        <meta
          property="og:image"
          content="https://skillyards.com/assets/images/mca-program.jpg"
        />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="MCA Degree Program in Agra | UGC Certified with Placements Up to 10 LPA"
        />
        <meta
          name="twitter:description"
          content="Upgrade your skills with the leading MCA Degree Program in Agra. UGC Certified with placements up to 10 LPA. Join Skillyards today!"
        />
        <meta
          name="twitter:image"
          content="https://skillyards.com/assets/images/mca-program.jpg"
        />
      </Helmet>
      {/* Hero Section */}
      <BgDisplayItems item={staticItem} />

      {/* BCA Program Heading */}
      <h1 className="text-4xl font-bold text-center mb-8">MCA Program
      <div className="w-[11rem] h-1 bg-yellow-400 mx-auto mt-2 mb-8"></div>

      </h1>

      {/* Semester Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8 px-4">
        {semesters.map((semester, index) => (
          <SemesterInfoCard
            key={index}
            imageSrc={semester.image}
            heading={semester.heading}
            subheading={semester.subheading}
            data={semester.data}
          />
        ))}
      </div>

      {/* Additional Information Sections */}
      <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8 px-4">
        {additionalInfo.map((section, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              {section.subheading}
            </h2>
            <ul className="space-y-2">
              {section.items.map((item, idx) => (
                <li key={idx} className="text-gray-700">
                  <span className="mr-2">•</span>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* FAQ Section */}
      <div className="mt-12">
        <FAQ faqItems={FAQ_ITEMS} />
      </div>
    </div>
  );
};

export default BCA;
