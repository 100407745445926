import React from 'react';
import { Helmet } from 'react-helmet';
import { Card, CardContent } from "@/components/ui/card";
import { LinkedinIcon, Users, Briefcase, GraduationCap } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

interface TeamMember {
  name: string;
  role: string;
  bio: string;
  image: string;
  linkedin?: string;
}

const LeadershipTeam: TeamMember[] = [
  {
    name: "Shubham Saraswat",
    role: "CEO of SN Digitech Pvt Ltd",
    bio: "Being the proud Founder & CEO of SN Digitech Pvt Ltd, a leading immersive IT development agency with a global footprint spanning India and the USA & armed with a degree in Computer Science, he embarked on a journey to bridge the gap between innovation and technology.",
    image: "Founder/Shubham.png",
    linkedin: "https://www.linkedin.com/in/shubhamsndigitech/"
  },
  {
    name: "Akash Sharma",
    role: "Founder & CEO of Skillyards",
    bio: "Having worked in the Edutech industry for multiple years, he has conducted meet-up with over a thousand students during the course of his career. His journey, starting as an education counsellor, uncovered a vital need for industry-relevant skills in Bharat's youth.",
    image: "Founder/Akash.png",
    linkedin: "https://www.linkedin.com/in/akash-sharma-1a241b136/"
  },
  {
    name: "Sandeep Kaushik",
    role: "Co-Founder of Skillyards",
    bio: "As a seasoned sales professional with over a decade of invaluable experience within the dynamic realm of the Plastic Industry, his journey as a visionary entrepreneur took shape as a co-founder of Skillyards.",
    image: "Founder/sandeepsir.png",
    linkedin: "https://www.linkedin.com/in/sandeep-kaushik-2869a487/"
  }
];

const CoreTeam: TeamMember[] = [
  {
    name: "Garvit Bhatnagar",
    role: "Content Marketer Executive & Trainer",
    bio: "Garvit Bhatnagar, author of 'The Perpetual Thoughts' and co-author of over seven published books, brings over a year of expertise to his role and is currently the Content Marketer Executive and Club Trainer at Skillyards.",
    image: "Founder/team/Garvit.png",
    linkedin: "https://www.linkedin.com/in/garvitbhatnagar/"
  },
  {
    name: "Ashish Thakur",
    role: "Team leader - Design & Marketing",
    bio: "Ashish, the Senior Graphic Designer at Skillyards, with over 2 years of experience, thrives in the designing field with a keen enthusiasm. Known for handling numerous projects, he combines creativity with his expertise to deliver innovative solutions.",
    image: "Founder/team/Ashish.png",
    linkedin: "https://www.linkedin.com/in/ashish-thakur-834b70249/"
  },
  {
    name: "Pratik Sharma",
    role: "Financial Officer",
    bio: "Pratik Sharma, with over 4 years of experience in accounts and business strategies excels as our Financial Officer. His expertise ensures robust financial health for our company.",
    image: "Founder/team/Pratik.png",
    linkedin: "https://www.linkedin.com/in/pratik-sharma-65bb6a258/"
  },
  {
    name: "Dinkle Singh",
    role: "Business Development Executive",
    bio: "With over 5 years of diverse experience, Dinkle Singh excels as a Business Development Executive at Skillyards with a keen interest in marketing.",
    image: "Founder/team/Dinkle.png",
    linkedin: "https://www.linkedin.com/in/dinkle-singh-43a023252/"
  }
];

const Trainees: TeamMember[] = [
  {
    name: "Harsh Vardhan",
    role: "Full-Stack Trainee",
    bio: "",
    image: "Founder/student/harsh.png",
    linkedin: "https://www.linkedin.com/in/harshvardhan232002/"
  },
  {
    name: "Girish Kumar",
    role: "Full-Stack Trainee",
    bio: "",
    image: "Founder/student/girish.png",
    linkedin: "https://www.linkedin.com/in/girishkumar7077/"
  }
  // Add other trainees similarly
];

const TeamMemberCard = ({ member }: { member: TeamMember }) => (
  <Card className="h-full hover:shadow-lg transition-shadow duration-300">
    <CardContent className="pt-6">
      <div className="flex flex-col items-center">
        <div className="relative w-32 h-32 mb-4">
          <img
            src={member.image}
            alt={member.name}

            className="rounded-full object-cover w-full h-full"
          />
          {member.linkedin && (
            <a
              href={member.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className="absolute bottom-0 right-0 bg-blue-600 p-2 rounded-full hover:bg-blue-700 transition-colors"
            >
              <LinkedinIcon className="w-4 h-4 text-white" />
            </a>
          )}
        </div>
        <h3 className="text-xl font-semibold mb-1">{member.name}</h3>
        <p className="text-sm text-muted-foreground mb-4">{member.role}</p>
        {member.bio && (
          <p className="text-sm text-center text-muted-foreground">{member.bio}</p>
        )}
      </div>
    </CardContent>
  </Card>
);

const TeamSection = ({ title, icon, description, members }: {
  title: string;
  icon: React.ReactNode;
  description: string;
  members: TeamMember[];
}) => (
  <div className="space-y-6">
    <div className="text-center max-w-3xl mx-auto">
    <div className="flex flex-col items-center">
  <div className="flex items-center justify-center gap-2 mb-4">
    {icon}
    <h2 className="text-3xl font-bold">{title}</h2>
  </div>
  <div className="w-[10rem] h-1 bg-yellow-400 mb-8"></div>
</div>

      
      <p className="text-muted-foreground">{description}</p>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
      {members.map((member, index) => (
        <TeamMemberCard key={index} member={member} />
      ))}
    </div>
  </div>
);

export default function Team() {
  return (
    <div className="min-h-screen bg-background">
       <Helmet>
    {/* Character Encoding */}
    <meta charSet="utf-8" />
  
    {/* Page Title */}
    <title>Skillyards - Meet Our Team of Innovators and Leaders</title>
  
    {/* Compatibility */}
    <meta httpEquiv="x-ua-compatible" content="ie=edge" />
  
    {/* Viewport Meta */}
    <meta name="viewport" content="width=device-width, initial-scale=1" />
  
    {/* Meta Description */}
    <meta 
      name="description" 
      content="Discover the incredible team at Skillyards. From our visionary leadership to dedicated sales executives and passionate trainees, meet the people revolutionizing education and skill development." 
    />
  
    {/* Keywords */}
    <meta 
      name="keywords" 
      content="Skillyards team, leadership, innovators, sales executives, trainees, edutech leaders, skill development experts, practical training team" 
    />
  
    {/* Author */}
    <meta name="author" content="Skillyards Team" />
  
    {/* Canonical Link */}
    <link rel="canonical" href="https://www.skillyards.com/teams" />
  
    {/* Open Graph Tags for Social Media */}
    <meta property="og:title" content="Skillyards - Meet Our Team of Innovators and Leaders" />
    <meta 
      property="og:description" 
      content="Explore the exceptional team behind Skillyards, including our leaders, executives, and trainees, who drive innovation and excellence in education." 
    />
    <meta property="og:image" content="https://www.skillyards.com/assets/images/team-banner.jpg" />
    <meta property="og:url" content="https://www.skillyards.com/teams" />
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="Skillyards" />
  
    {/* Twitter Card for Social Media */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Skillyards - Meet Our Team of Innovators and Leaders" />
    <meta 
      name="twitter:description" 
      content="Get to know the people behind Skillyards! Meet our talented leaders, executives, and trainees dedicated to revolutionizing education and skill-building." 
    />
    <meta name="twitter:image" content="https://www.skillyards.com/assets/images/team-banner.jpg" />
    <meta name="twitter:site" content="@Skillyards" />
  
    {/* Favicon and Icons */}
    <link rel="apple-touch-icon" sizes="180x180" href="Web_logo/favicon/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="Web_logo/favicon/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="Web_logo/favicon/favicon-16x16.png" />
    <link rel="mask-icon" href="Web_logo/favicon/safari-pinned-tab.svg" color="#5bbad5" />
  
    {/* Additional Meta */}
    <meta name="robots" content="index, follow" />
  </Helmet>

      <div className="relative h-64 mb-16">
        <img
          src="Web_logo/Background/team.png"
          alt="Team Banner"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white">Our Team</h1>
        </div>
      </div>

      <div className="container mx-auto px-4 py-12 space-y-16">
        <Tabs defaultValue="leadership" className="w-full">
          <TabsList className="grid w-full grid-cols-3 mb-8">
            <TabsTrigger value="leadership">Leadership</TabsTrigger>
            <TabsTrigger value="core-team">Core Team</TabsTrigger>
            <TabsTrigger value="trainees">Trainees</TabsTrigger>
          </TabsList>
          
          <TabsContent value="leadership">
            <TeamSection
              title="Leadership Team"
              icon={<Briefcase className="w-8 h-8" />}
              description="The founders of Skillyards Versatility, known for their diverse expertise, have redefined skill development by blending practical knowledge with modern technology."
              members={LeadershipTeam}
            />
          </TabsContent>
          
          <TabsContent value="core-team">
            <TeamSection
              title="Core Team"
              icon={<Users className="w-8 h-8" />}
              description="Thriving on a unique bond, blending creative minds and technical expertise, we transform challenges into opportunities fostering an environment where innovation flourishes."
              members={CoreTeam}
            />
          </TabsContent>
          
          <TabsContent value="trainees">
            <TeamSection
              title="Our Trainees"
              icon={<GraduationCap className="w-8 h-8" />}
              description="Meet our dedicated trainees, turning their skills into success. Watch them grow and shine as they embrace new challenges and opportunities."
              members={Trainees}
            />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}