type Payload = {
    name: string;
    email?: string; // Ensure this is provided
    mobile?: string;
    subject?: string;
    message?: string; //
    location?: string;
};





export async function NeoDove(data: Payload): Promise<void> {
    const api = "https://713a1e2d-9d44-432a-b93c-452ee60c7b58.neodove.com/integration/custom/49a3f722-82a5-4ce0-99d2-c5c82d829aa7/leads";
    try {
        const res = await fetch(api, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
        }

        console.log("NeoDove post method success");
    } catch (error) {
        console.error("Error in NeoDove post method:", error);
    }
}

