import { Helmet } from "react-helmet";
import BgDisplayItems from "../common/BgDisplayItem";
// import DisplayInfo from "../common/DisplayInfo";
import FAQ from "../common/FAQ";
import CombinedDisplay from "../common/mergecontentDisplay";
const UIUXDesign = () => {
  const staticItem = {
    id: 1,
    title: "UI/UX Design Training In Agra",
    subtitle: "With the On-The Job BCA, MCA, or BBA Degree Program",
    description: [
      "Project-based training with Skillyards Club Facilitation",
      "Paid Internship with stipend up to ₹15,000/month",
      "Guaranteed Placement up to 10 LPA in Top MNCs",
    ],
    buttonText: "Join Now",
    buttonAction: "#",
    backgroundImage: "/Web_logo/Background/UIUX.png",
  };

  const simpleItem = {
    heading: "UI/UX Design Training Program",
    subheading: "Duration: 6 months Training + 3 Months Internship ",
    data: {
      dataHeading1: {
        title: "Introduction to UI/UX design",
        items: [
          "Basic understanding about the difference between UI and UX mobile application and web application.",
        ],
      },
      dataHeading2: {
        title: "Type of software used for UI/UX design",
        items: [
          "For UI/UX design, Figma, Adobe XD, For prototyping, Figma, Protopie, For research and surveys, Google doc, Notion, Google surveys, For user testing, Maze,",
        ],
      },
      dataHeading3: {
        title: "Conducting user research",
        items: [
          "How to create Q&A",
          "How to gather pain points",
          "Quantitive research",
        ],
      },
      dataHeading4: {
        title: "Research Analysis",
        items: [
          "Understand research and pain points and then define the problem",
        ],
      },
      dataHeading5: {
        title: "User Persona and empathy map",
        items: [
          "Create atleast 2-3 user persona according to the pain points gathered from the research",
          "Draw empathy map according to the needs",
        ],
      },
      dataHeading6: {
        title: "Mental model and user flows",
        items: [
          "Creating task flow for the particular task to be done by the user which is related to the Problem statement.",
        ],
      },
      dataHeading7: {
        title: "Information Architecture and navigation",
        items: [
          "How many number of screens are there and how many discussion taking option are available for the user to navigate through that screens. ",
        ],
      },
      dataHeading8: {
        title: "Content strategy and Wireframing",
        items: [
          "Pen and paper Wireframing then low fidelity Wireframing where designer can design write and finalise the content to be used in the application which has been used by the user.",
        ],
      },
      dataHeading9: {
        title: "Interaction design & Wireframing",
        items: [
          "Next step is low fidelity to high fidelity sketches where we are going to add the images and the colours according to the brand guidelines.",
        ],
      },
      dataHeading10: {
        title: "Setting Up your grid",
        items: [
          "Setting up the grind to make the alignment look identical on whole product to increase the visual hierarchy of the product.",
        ],
      },
      dataHeading11: {
        title: "UI components & fidelity wireframes",
        items: [
          "Make Component's of Buttons, decide variants, pollens and properties so that when user is try to interact with the product then we can make the product more engaging and enhance the ux of product.",
        ],
      },
      dataHeading12: {
        title: "UI design & prototyping tools",
        items: [
          "Replace the high-fidelity sketch button with the components that we have made in all the final screens.",
          "Applying interactions meaning the connection of CTAs, screens, banners etc. to the next screens a=or the popups.",
        ],
      },
      dataHeading13: {
        title: "Internship",
        items: ["3 Months paid internship", "Stipend of upto INR 15000"],
      },
    },
  };

  const FAQ_ITEMS = [
    {
      question: "Is EMI available in the UI/UX Design Training Program?",
      answer: `Yes, EMI option is available in the UI/UX Design Training Program so that the students do not feel the need to submit the program fees in one go.`,
    },
    {
      question:
        "On what type of projects will I work on in the UI/UX Design Training Program?",
      answer: `You will get to work on real-world client projects such as designing mobile applications, website designing, and more!`,
    },
    {
      question:
        "What softwares will I be trained on in the UI/UX Design Training Program?",
      answer: `In the UI/UX Design Training Program, you will learn how to use Figma, Protopie, Notion, and other premium software applications.`,
    },
  ];

  const images = [
    "assets/full-stack-webp/pexels-picjumbo-com-55570-196644_3_11zon_10_11zon.webp",
    "assets/full-stack-webp/pexels-picjumbo-com-55570-196645_5_11zon_11_11zon.webp",
    "assets/full-stack-webp/pexels-tranmautritam-326514_4_11zon_13_11zon.webp",
    "assets/full-stack-webp/pexels-divinetechygirl-1181345_1_11zon_2_11zon.webp",
  ];
  return (
    <div>
      <Helmet>
        {/* Character Encoding */}
        <meta charSet="utf-8" />

        {/* Page Title */}
        <title>
          Best UI/UX Design Training in Agra | Learn Wireframing & Prototyping |
          Skillyards
        </title>

        {/* Browser Compatibility */}
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />

        {/* Viewport for Responsiveness */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Meta Description */}
        <meta
          name="description"
          content="Master UI/UX Design with our top-rated training program in Agra. Learn wireframing, prototyping, user research, and design tools like Figma and Adobe XD. Enroll today and craft stunning user interfaces and experiences!"
        />

        {/* Keywords */}
        <meta
          name="keywords"
          content="UI UX design training in Agra, best UI UX design course Agra, UI UX training institute, wireframing and prototyping course, Figma training Agra, Adobe XD training, user interface design course, user experience design training, UI UX certification program, Skillyards UI UX design"
        />

        {/* Author */}
        <meta name="author" content="Skillyards" />

        {/* Canonical URL */}
        <link
          rel="canonical"
          href="https://www.skillyards.com/ui-ux-design-training-in-agra"
        />

        {/* Open Graph (OG) Meta Tags for Social Sharing */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Best UI/UX Design Training in Agra | Learn Wireframing & Prototyping | Skillyards"
        />
        <meta
          property="og:description"
          content="Explore our expert-led UI/UX Design Training in Agra. Learn wireframing, prototyping, user research, and design tools like Figma & Adobe XD. Enroll now and advance your design career!"
        />
        <meta
          property="og:url"
          content="https://www.skillyards.com/ui-ux-design-training-in-agra"
        />
        <meta
          property="og:image"
          content="https://www.skillyards.com/assets/images/ui-ux-design-training-banner.jpg"
        />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Best UI/UX Design Training in Agra | Learn Wireframing & Prototyping | Skillyards"
        />
        <meta
          name="twitter:description"
          content="Join Skillyards' UI/UX Design Training in Agra and master wireframing, prototyping, and design tools like Figma and Adobe XD. Boost your career in design!"
        />
        <meta
          name="twitter:image"
          content="https://www.skillyards.com/assets/images/ui-ux-design-training-banner.jpg"
        />
      </Helmet>
      <BgDisplayItems item={staticItem} />
      <div>
        {/* <DisplayInfo item={simpleItem} /> */}
        <CombinedDisplay item={simpleItem} images={images} />
      </div>
      <FAQ faqItems={FAQ_ITEMS} />
    </div>
  );
};

export default UIUXDesign;
