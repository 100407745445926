import { useLocation } from "react-router-dom";
import "./App.css";
import Header from "../src/components/Header";
import AllRoutes from "../src/routes/allroutes";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import CareerCounselingModal from "./lib/carrierModal";
// import Notification from "./components/Notification";
import PaymentContinuationModal from "./lib/payment-reminder-modal";
import { GetFromLocal } from "./lib/get-local";
import ChatBot from "./lib/chat-bot";
import { AuthProvider } from "./lib/auth-context";

function App() {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [pathname]); // Triggered whenever the route changes

  const shouldHideHeaderFooter = pathname.startsWith("/app") || pathname === "/admin/login";

  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false); // New state for payment modal

  useEffect(() => {
    const hasShownModal = sessionStorage.getItem("hasShownModal");
    if (!hasShownModal) {
      setShowModal(true);
      sessionStorage.setItem("hasShownModal", "true");
    }
  }, []);

  useEffect(() => {
   
    const timer = setTimeout(() => {
      setShowPaymentModal(true);
    }, 60000); 

    return () => clearTimeout(timer); // Cleanup timeout if component unmounts
  }, []);

  const handleCloseModal = () => setShowModal(false);

  const userDetails = GetFromLocal();
  // console.log(userDetails, "check");

  return (
    <>
      <div>
        {userDetails?.paymentStatus === false && showPaymentModal ? (
          <PaymentContinuationModal />
        ) : (
          <CareerCounselingModal show={showModal} handleClose={handleCloseModal} />
        )}

        {/* Conditionally render Header and Footer */}
        {!shouldHideHeaderFooter && <Header />}
        {/* <Notification /> */}
        <AuthProvider>
        <AllRoutes />
        </AuthProvider>
        {!location.pathname.includes('/app') && !location.pathname.includes('aptitude-test') && <ChatBot />}



        {!shouldHideHeaderFooter && <Footer />}
      </div>
    </>
  );
}

export default App;
