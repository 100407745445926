import React, { useState } from 'react';
import { User, Mail, Phone, MapPin, MessageSquare, Send, Loader2, CheckCircle2, Copy, Check, CupSodaIcon } from 'lucide-react';
import TypewriterComponent from 'typewriter-effect';
import axios, { AxiosResponse } from 'axios';
import { Button } from '../../components/ui/button'; // Assuming shadcn has a Button component
import { Input } from '../../components/ui/input'; // Assuming shadcn has an Input component
import { Textarea } from '../../components/ui/textarea'; // Assuming shadcn has a Textarea component
import { useLocation } from 'react-router-dom';
import SaveToLocal from '@/lib/save-local';

// Declare Razorpay on the Window interface
declare global {
  interface Window {
    Razorpay: new (options: RazorpayOptions) => RazorpayInstance;
  }
}

interface RazorpayOptions {
  key: string;
  amount: string;
  currency: string;
  name: string;
  description: string;
  image: string;
  order_id: string;
  handler: (response: RazorpayPaymentResponse) => void;
  prefill: {
    name: string;
    email: string;
    contact: string;
  };
  notes: {
    address: string;
  };
  theme: {
    color: string;
  };
}

interface RazorpayPaymentResponse {
  discount:number;
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
}

interface RazorpayInstance {
  open: () => void;
}

interface WorkshopRegistrationProps {
  bg?: boolean;
  heading?: string;
  textarr?: string[];
  fot?: string;
}

interface FormData {
  name: string;
  email: string;
  mobile: string;
  city: string;
  message: string;
  role:string,
  couponCode:string,
  enrollmentType:string,
  amount:number
}

interface RazorpayOrderResponse {
  order: {
    amount: number;
    id: string;
  };
  user: {
    _id: string;
  };
  finalAmount:number;
  discount:number
}
type ErrorType = {
  response?: {
    data: {
      error: string;
      message:string
    };
  };
  message: string;
};

const WorkshopRegistration: React.FC<WorkshopRegistrationProps> = ({ bg, heading, textarr, fot }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState('');
  const [copied, setCopied] = useState(false);
  const location=useLocation()

  const {pathname}=location
  

  const api = `${import.meta.env.VITE_APP_API_URL}/payment/`;

  const careerOptions = [
    "STUDENTS",
    "WORKING PROFESSIONALS",
    "HOUSEWIVES",
    "ENTREPRENEURS",
    "FREELANCERS",
    "TECH ENTHUSIASTS",
  ];

  const initialFormState: FormData = {
    name: "",
    email: "",
    mobile: "",
    city: "",
    message: "",
    role:"",
    couponCode:"",
    enrollmentType:"",
    amount:0,

  };

  const [formData, setFormData] = useState<FormData>(initialFormState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    
    setFormData(prev => {
      // Correct way to check multiple paths
      const isDigitalMarketing = pathname === "/digitalmarketing" || 
                                pathname === "/digital-marketing-training-in-agra";
      
      // Determine if it's a workshop based on new or existing value
      const isWorkshop = name === "enrollmentType" 
        ? value === "workshop" 
        : prev.enrollmentType === "workshop";
      
      // Determine role based on path
      const role = isDigitalMarketing ? "digitalmarketing" : "fullstack";
      
      // Calculate amount based on enrollment type and course
      const amount = isWorkshop 
        ? 99 
        : (isDigitalMarketing ? 60000 : 125000);
  
      return {
        ...prev,
        [name]: value,
        role,
        amount
      };
    });
    
    setError('')
  };
  

  const validateForm = (): string => {
    if (!formData.name.trim()) return "Name is required";
    if (/\d/.test(formData.name)) return "Name should not contain numbers";
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) return "Valid email is required";
    if (!formData.mobile.trim() || !/^\+?[1-9]\d{9,11}$/.test(formData.mobile.replace(/\s+/g, '')))
      return "Valid phone number is required";
    if (!formData.city.trim()) return "City is required";
    if(!formData.enrollmentType) return "Please select an enrollment type";
    return "";
  };

  const loadRazorpayScript =async (): Promise<boolean> => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => {
        resolve(false);
        setError("Failed to load payment gateway. Please check your internet connection.");
      };
      document.body.appendChild(script);
    });
  };

  const handlePayment = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }
  
   
    
   
    SaveToLocal(formData)


    setIsSubmitting(true);
    try {
      const scriptLoaded = await loadRazorpayScript();
      if (!scriptLoaded) {
        throw new Error("Razorpay SDK failed to load");
      }


      const orderResponse: AxiosResponse<RazorpayOrderResponse> = await axios.post(
        `${api}create-razorpay-order`,
        formData,
        {
          headers: {
            "Content-Type": "application/json"
          },
        }
      );

      if (!orderResponse.data?.order) {
        throw new Error("Failed to create order");
      }

      const { id } = orderResponse.data.order;
      const { _id } = orderResponse.data.user;

      const finalAmount = orderResponse.data?.finalAmount;
      const discount=orderResponse.data?.discount
      console.log(orderResponse, "orderResponse");


      const options: RazorpayOptions = {
        key: "rzp_live_Y8Pe5kKuBA8pNN",
        amount: finalAmount.toString(),
        currency: "INR",
        name: "Skillyards Versatility",
        description: "Workshop Registration Payment",
        image: "/Web_logo/front_weblogo.jpeg",
        order_id: id,
        handler: async (response: RazorpayPaymentResponse) => {
          try {
            const paymentResult = await axios.post(`${api}verify-payment`, {
              orderData: {
                order_creation_id: id,
                userId: _id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
                amount: finalAmount,
                currency: "INR",
                couponCode: formData.couponCode.toUpperCase(),
                discount:discount
              },
              header: {
                "Content-type": "application/json"
              }
            });
            SaveToLocal(formData)

            if (paymentResult.data?.success) {
              setFormData(initialFormState);

              setSuccess(paymentResult.data?.paymentId);
              localStorage.removeItem('formData')
              setError('');
            }
          } catch (error) {
            console.error(error);
            setError("Payment verification failed. Please contact support.");
          }
        },
        prefill: {
          name: formData.name,
          email: formData.email,
          contact: formData.mobile,
        },
        notes: {
          address: formData.city,
        },
        theme: {
          color: "#FFB93F",
        }
      };

      const paymentObject = new window.Razorpay(options); // No more TypeScript error
      paymentObject.open();
    } catch (error) {

      const err=error as ErrorType
// console.log(err.response?.data?.message);


      setError(err.response?.data?.message || err.message || "An unexpected error occurred");
      

    } finally {
      setIsSubmitting(false);
    }
  };

  const copyPaymentId = async () => {
    await navigator.clipboard.writeText(success);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className={`  min-h-screen ${bg ? "bg-gradient-to-br from-slate-200 to-gray-900" : "bg-white"} py-12 lg:px-12`}>
      <div className="mx-2 w-full">
        <div className="flex flex-col lg:flex-row gap-8 items-center">
          <div className="lg:w-1/2 w-full space-y-3 text-center lg:text-center">
            <h2 className="font-montserrat text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 transition-all duration-300 ease-in-out">
              {heading || "Who Can Join This Workshop?"}
            </h2>

            <div className="h-20 pt-0 flex items-center justify-center mx-auto">
              <div className="flex items-center justify-center text-2xl text-center md:text-3xl lg:text-4xl font-bold text-amber-400">
                <TypewriterComponent
                  options={{
                    strings: textarr || careerOptions,
                    autoStart: true,
                    loop: true,
                    delay: 75,
                  }}
                />
              </div>
            </div>

            <p className={`text-lg ${!fot ? "text-black" : "text-white"} text-center`}>
              {!fot ? "Join our comprehensive workshop designed for individuals from all walks of life. Transform your skills and unlock new opportunities" : fot}
            </p>
              {/* Error now appears inside the form */}
              {error && (
              <div className="mx-6 mt-8 flex items-center gap-3 p-4 bg-red-50 text-red-600 rounded-lg border border-red-100">
                <div className="h-2 w-2 rounded-full bg-red-600" />
                <p className="text-sm font-medium">{error.toString()}</p>
              </div>
            )}
          </div>

          <div className={`w-full lg:w-[50%] rounded-2xl shadow-xl hover:shadow-2xl transition-shadow duration-300`}>
           
 

 {success ? (
        <div className="text-center space-y-6">
          <CheckCircle2 size={48} className="mx-auto text-green-500" />
          <h3 className="text-2xl font-bold text-gray-900">Payment Successful!</h3>
          
          <div className="flex flex-col items-center gap-4">
            <p className="text-gray-600">Thank you for registering for our workshop.</p>

            <div className="bg-gray-50 border border-gray-200 rounded-lg p-4 w-full max-w-md">
              <div className="text-sm text-gray-500 mb-2">Payment ID</div>
              <div className="flex items-center justify-between gap-2 bg-white border border-gray-200 rounded-md p-3">
                <span className="font-mono text-gray-700">{success}</span>
                <Button
                  onClick={copyPaymentId}
                  className={`flex items-center gap-2 px-3 py-1.5 rounded-md transition-all duration-200 ${
                    copied
                      ? 'bg-green-50 text-green-600 border border-green-200'
                      : 'bg-gray-50 text-gray-600 border border-gray-200 hover:bg-gray-100'
                  }`}
                  aria-label="Copy payment ID"
                >
                  {copied ? (
                    <>
                      <Check className="w-4 h-4" />
                      <span className="text-sm font-medium">Copied!</span>
                    </>
                  ) : (
                    <>
                      <Copy className="w-4 h-4" />
                      <span className="text-sm font-medium">Copy</span>
                    </>
                  )}
                </Button>
              </div>
            </div>

            <p className="text-gray-600">Our team will contact you shortly with further details.</p>
          </div>
        </div>
      ) : (
        <div className="relative">
          <div className="bg-white rounded-xl shadow-lg border border-gray-100">
          

            <form onSubmit={handlePayment} className="p-6 space-y-8">
              {/* Personal Information Section */}
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Personal Information</h3>
                <div className="grid md:grid-cols-2 gap-6">
                  <div className="space-y-2">
                    <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                      <User size={18} className="text-amber-500" />
                      Name
                    </label>
                    <Input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Your full name"
                      className="shadow-sm"
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                      <Mail size={18} className="text-amber-500" />
                      Email
                    </label>
                    <Input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="example@gmail.com"
                      className="shadow-sm"
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                      <Phone size={18} className="text-amber-500" />
                      Phone
                    </label>
                    <Input
                      type="tel"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                      placeholder="+91 8650003501"
                      className="shadow-sm"
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                      <MapPin size={18} className="text-amber-500" />
                      City
                    </label>
                    <Input
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      placeholder="Your city"
                      className="shadow-sm"
                    />
                  </div>
                </div>
              </div>

              {/* Course Selection Section */}
              <div  >
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Enrollment Options</h3>
                <div className=" flex justify-around ">
                  <label className="block p-4 border rounded-lg hover:bg-gray-50 cursor-pointer transition-colors">
                    <div className="flex items-center space-x-3">
                      <input
                        type="radio"
                        id="workshop"
                        name="enrollmentType"
                        value="workshop"
                        defaultChecked={false}
                        onChange={handleChange}
                        className="h-4 w-4 text-amber-500 border-gray-300 focus:ring-amber-500"
                      />
                      <div>
                        <p className="font-medium text-gray-900">Workshop Only</p>
                        {/* <p className="text-sm text-gray-500">₹99 - Access to live workshop session</p> */}
                      </div>
                    </div>
                  </label>

                  <label className="block p-4 border rounded-lg hover:bg-gray-50 cursor-pointer transition-colors">
                    <div className="flex items-center space-x-3">
                      <input
                        type="radio"
                        id="fullCourse"
                        name="enrollmentType"
                        value="fullCourse"
                        defaultChecked={false}
                        onChange={handleChange}
                        className="h-4 w-4 text-amber-500 border-gray-300 focus:ring-amber-500"
                      />
                      <div>
                        <p className="font-medium text-gray-900">Full Course</p>
                        {/* <p className="text-sm text-gray-500">
                          {pathname === "/digitalmarketing" ? "₹90,000" : "₹1,25,000"} - Complete course access
                        </p> */}
                      </div>
                    </div>
                  </label>
                </div>
              </div>

              {/* Coupon Section */}
             { formData.enrollmentType=="fullCourse"? <div className="space-y-2">
                <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                  <CupSodaIcon size={18} className="text-amber-500" />
                  Apply Coupon Code
                </label>
                <Input
                  type="text"
                  name="couponCode"
                  value={formData.couponCode}
                  onChange={handleChange}
                  placeholder="Enter Coupon Code to get an instant upto 10% discount"
                  className="shadow-sm"
                />
              </div>:null}

              {/* Message Section */}
              <div className="space-y-2">
                <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                  <MessageSquare size={18} className="text-amber-500" />
                  Message (Optional)
                </label>
                <Textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={4}
                  placeholder="Any questions or special requirements?"
                  className="shadow-sm"
                />
              </div>

              {/* Submit Button */}
              <Button
                type="submit"
                disabled={isSubmitting}
                className="w-full bg-yellow-400 text-white py-3 px-6 rounded-lg flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-yellow-500 transition-colors shadow-sm"
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="animate-spin" size={20} />
                    Processing...
                  </>
                ) : (
                  <>
                    <Send size={20} />
                    Join the Workshop!!
                  </>
                )}
              </Button>
            </form>
          </div>
        </div>
      )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkshopRegistration;