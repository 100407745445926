
const OurPartner = () => {
    const images = [
      "./Compuny/aaaaa.webp",
      "./Compuny/Boat.webp",
      "./Compuny/sndigitech.webp",
      "./Compuny/genpact.webp",
      "./Compuny/Infosys.webp",
      "./Compuny/nvidia.webp",
      "./Compuny/Zoho.webp",
      "./Compuny/Tcl.webp"
    ];
  
    // Combine images and their duplicates for seamless looping
    const loopImages = [...images, ...images];
  
    return (
      <div className="py-16 overflow-hidden">
        <h2 className="text-3xl font-bold text-center mb-2 text-black">Our Placement Partners</h2>
        <div className="w-48 h-1 bg-yellow-400 mx-auto mb-8"></div>
        <div className="relative overflow-hidden">
          <div className="marquee">
            <div className="marquee-track">
              {loopImages.map((image, index) => (
                <div
                  key={index}
                  className="min-w-[150px] mx-4 flex items-center justify-center"
                >
                  <img
                    src={image}
                    alt={`Partner ${index}`}
                    className="w-32 h-32 object-contain rounded-lg"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default OurPartner;
  