import axios from "axios";
import { toast } from "sonner";

const API_BASE_URL = `${import.meta.env.VITE_APP_API_URL}`;

type BlogContent = {
  heading: string;
  tablename: string;
  discription: string;
  discription2: string;
  images: string | File | null;
}

type BlogData = {
  id: string;
  title: string;
  krywords: string;
  discription: string;
  images: string | File | null;
  imagediscription: string;
  tableImage: string | File | null;
  status: "Approved" | "Not-Approved";
  content: BlogContent[];
}

type ApiResponse = {
  data: {
    _id: string;
  };
}

export const updateBlog = async (
  blogData: BlogData,
  token: string,
  contentData: BlogContent[]
): Promise<string> => {
  const formData = new FormData();

  if (blogData.images) {
    formData.append("images", blogData.images);
  }
  if (blogData.tableImage) {
    formData.append("tableImage", blogData.tableImage);
  }

  Object.entries(blogData).forEach(([key, value]) => {
    if (key !== "images" && key !== "tableImage" && key !== "content" && value !== null) {
      formData.append(key, String(value));
    }
  });

  contentData.forEach((content, index) => {
    formData.append(`content[${index}][heading]`, content.heading);
    formData.append(`content[${index}][tablename]`, content.tablename);
    formData.append(`content[${index}][discription]`, content.discription);
    formData.append(`content[${index}][discription2]`, content.discription2);
    if (content.images) {
      formData.append(`content[${index}][images]`, content.images);
    }
  });

  // Log FormData entries for debugging
  const formDataObject: { [key: string]: any } = {};
  formData.forEach((value, key) => {
    formDataObject[key] = value;
  });
  console.log("Full data before sending to the database:", formDataObject);

  try {
    const { data } = await axios<ApiResponse>({
      method: "put",
      url: `${API_BASE_URL}/admin/blog/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });

    console.log("API response:", data);
    toast.success("Blog updated successfully");
    return data.data._id;

  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message = error.response?.data?.message || "Failed to update blog";
      console.error("API error:", message);
      toast.error(message);
      throw new Error(message);
    }

    console.error("Unexpected error:", error);
    toast.error("An unexpected error occurred");
    throw new Error("An unexpected error occurred");
  }
};
