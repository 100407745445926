import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import BgDisplayItems from "./common/BgDisplayItem";
import { useState } from "react";
import { toast } from "sonner";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Helmet } from "react-helmet";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { NeoDove } from "@/lib/neo-dove";
// import { N } from "node_modules/framer-motion/dist/types.d-6pKw1mTI";

// Zod schema for form validation
const formSchema = z.object({
  name: z.string()
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name cannot exceed 50 characters"),
  mobile: z.string()
    .min(10, "Mobile number must be 10 digits")
    .max(10, "Mobile number must be 10 digits")
    .regex(/^[0-9]+$/, "Must be a valid mobile number"),
  subject: z.enum([
    "Full-Stack Foundation",
    "Full-Stack Development",
    "Front-End Development",
    "Back-End Development",
    "UI/UX Designing Program",
    "Advanced Digital Marketing",
    "BCA Degree Program",
    "MCA Degree Program",
    "Other"
  ], {
    required_error: "Please select a career path",
  }),
  location: z.string()
    .min(2, "Location must be at least 2 characters")
    .max(100, "Location cannot exceed 100 characters"),
  message: z.string()
    .min(5, "Message must be at least 10 characters")
    .max(150, "Message cannot exceed 150 characters"),
});

// Type inference from the schema
type FormData = z.infer<typeof formSchema>;

// Environment variable for API URL
const API_URL = `${import.meta.env.VITE_APP_API_URL}`;

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  
  // Initialize form with React Hook Form and Zod resolver
  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      mobile: "",
      subject: undefined,
      location: "",
      message: "",
    },
  });

  // Available programs for the select input
  const programs = [
    "Full-Stack Foundation",
    "Full-Stack Development",
    "Front-End Development",
    "Back-End Development",
    "UI/UX Designing Program",
    "Advanced Digital Marketing",
    "BCA Degree Program",
    "MCA Degree Program",
    "Other"
  ] as const;

  // Form submission handler
  const onSubmit = async (data: FormData) => {
    setLoading(true);
    toast.loading("Submitting your message...");
    
    try {
      //console.log(data, "data before api")
      const response = await fetch(`${API_URL}/user/contact`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ formData: data }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to submit the form.");
      }

      toast.success("Congrats! You've taken the first step to become an IT Expert with Skillyards.");
      form.reset();
    } catch (error) {
      console.error("Error:", error);
      toast.error(error instanceof Error ? error.message : "Failed to submit your message. Please try again.");
    } finally {
      setLoading(false);
      toast.dismiss();
    }

    //  NeoDove('https://713a1e2d-9d44-432a-b93c-452ee60c7b58.neodove.com/integration/custom/fcf7cee0-54b3-4481-a143-fab4d6c050f1/leads',data)
    NeoDove(data)
  };

  // Static content for the hero section
  const staticItem = {
    id: 1,
    title: "Contact Us",
    subtitle: "With the On-The Job BCA, MCA, or BBA Degree Program",
    description: [
      "Project-based training with Skillyards Club Facilitation",
      "Paid Internship with stipend up to ₹15,000/month",
      "Guaranteed Placement up to 10 LPA in Top MNCs",
    ],
    buttonText: "Join Now",
    buttonAction: "#",
    backgroundImage: "Web_logo/Background/contactus.jpg",
  };

  return (
    <>
      <Helmet>
        <title>Contact Skillyards - Get Career Guidance & Support</title>
        <meta 
          name="description" 
          content="Connect with Skillyards for personalized career counseling, IT training, and professional development. Reach out via phone, email, or contact form."
        />
        <meta 
          name="keywords" 
          content="Skillyards contact, IT career counseling, professional training, career support, contact form"
        />
        <link 
          rel="canonical" 
          href="https://skillyards.com/contact" 
        />
        <meta property="og:title" content="Contact Skillyards - Career Support" />
        <meta 
          property="og:description" 
          content="Get in touch with Skillyards for expert career guidance and IT training opportunities."
        />
        <meta property="og:url" content="https://skillyards.com/contact" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="p-0 m-0">
        <BgDisplayItems item={staticItem} />

        <div className="w-full -mt-12 p-0 h-auto md:h-[800px] bg-gray-300">
          <div className="mx-auto max-w-5xl px-4 py-8 md:py-0 flex flex-col justify-center">
            <h1 className="text-2xl md:text-3xl font-bold mb-6 text-gray-900 pt-10 mt-5 text-center py-10">
              Get In Touch
            </h1>
            <div className="grid md:grid-cols-2 gap-8">
              {/* Contact Information Column */}
              <div className="space-y-6 md:space-y-8">
                <div className="space-y-4 md:space-y-20">
                  <div className="flex items-start gap-2">
                    <img src="/social-icon/6.svg" alt="office" className="h-8 w-8 md:h-10 md:w-10 mt-1 rounded-3xl"/>
                    <div>
                      <h2 className="text-base md:text-lg font-semibold text-gray-800">Our Office</h2>
                      <p className="text-gray-600 text-xs md:text-sm">
                        D-24, behind Conrad's School <br className="hidden md:block"/> 
                        Nirbhay Nagar, Agra, Uttar Pradesh 282007
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start gap-2">
                    <img src="/social-icon/5.svg" alt="phone" className="h-8 w-8 md:h-10 md:w-10 mt-1 rounded-3xl"/>
                    <div>
                      <h2 className="text-base md:text-lg font-semibold text-gray-800">Phone Number</h2>
                      <p className="text-gray-600 text-xs md:text-sm">+91 7060100562</p>
                    </div>
                  </div>
                  <div className="flex items-start gap-2">
                    <img src="/social-icon/7.svg" alt="email" className="h-8 w-8 md:h-10 md:w-10 mt-1 rounded-3xl"/>
                    <div>
                      <h2 className="text-base md:text-lg font-semibold text-gray-800">Email Address</h2>
                      <p className="text-gray-600 text-xs md:text-sm">info@skillyards.com</p>
                    </div>
                  </div>
                </div>

                {/* Social Media Links */}
                <div className="space-y-2 md:space-y-8">
                  <h3 className="text-base md:text-lg font-semibold text-gray-800">Follow Us:</h3>
                  <div className="flex gap-2 md:gap-4">
                    <a href="https://www.facebook.com/skillyards" className="transition-colors h-7 w-7 md:h-9 md:w-9">
                      <img src="/social-icon/9.svg" alt="social" className="rounded-3xl"/> 
                    </a>
                    <a href="https://twitter.com/Skillyardss" className="transition-colors h-7 w-7 md:h-9 md:w-9">
                      <img src="/social-icon/10.svg" alt="social" className="rounded-3xl"/>
                    </a>
                    <a href="https://www.instagram.com/skillyardss" className="transition-colors h-7 w-7 md:h-9 md:w-9">
                      <img src="/social-icon/8.svg" alt="social" className="rounded-3xl"/>
                    </a>
                    <a href="https://www.linkedin.com/company/skillyards/mycompany" className="transition-colors h-7 w-7 md:h-9 md:w-9">
                      <img src="/social-icon/11.svg" alt="social" className="rounded-3xl"/>
                    </a>
                  </div>
                </div>
              </div>

              {/* Contact Form Column */}
              <div className="bg-white p-4 md:p-8 rounded-xl shadow-lg">
                <h2 className="text-xl md:text-2xl font-bold text-yellow-400 mb-4 md:mb-6">
                  Send a Message
                </h2>
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3 md:space-y-4">
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input placeholder="Your Name" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="mobile"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input placeholder="Phone Number" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="subject"
                      render={({ field }) => (
                        <FormItem>
                          <Select onValueChange={field.onChange} value={field.value}>
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select Career Path" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {programs.map((program) => (
                                <SelectItem key={program} value={program}>
                                  {program}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="location"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input placeholder="Your Location" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="message"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Textarea
                              placeholder="Share your career aspirations and goals (max 150 characters)"
                              className="resize-none"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <Button
                      className="hover:bg-yellow-300 bg-yellow-400 w-full"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Submitting..." : "Book Free Career Counseling"}
                    </Button>
                  </form>
                </Form>
              </div>
            </div>
          </div>
        </div>

        {/* Google Maps Section */}
        <div className="mx-auto w-[90vw] md:w-[80vw] py-8 md:py-14 -mt-[50px] md:-mt-[150px]">
          <div className="w-full h-[250px] md:h-[350px]">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3548.198809921703!2d77.9793065745937!3d27.212907247127017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3974776a3f3b61d9%3A0xc26cc82e5a39a7fc!2sSkillyards%20Versatility%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1737187422382!5m2!1sen!2sin"
              className="w-full h-full border-0"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;