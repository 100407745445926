import React from 'react';
import { Helmet } from 'react-helmet';

interface CardProps {
  imageSrc: string;
  title: string;
  description: string;
}

const Card: React.FC<CardProps> = ({ imageSrc, title, description }) => {
  return (
    <div className="bg-white rounded-xl shadow-2xl overflow-hidden transform transition-transform duration-300 hover:scale-105 hover:shadow-3xl">
      <div className="flex justify-center items-center p-6">
        <img
          src={imageSrc}
          alt={title}
          className="w-24 h-24 object-contain" // Adjusted image size and object-fit
          loading="lazy" // Lazy load images for better performance
        />
      </div>
      <div className="p-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-3">{title}</h2>
        <p className="text-gray-600 leading-relaxed">{description}</p>
      </div>
    </div>
  );
};

const Club = () => {
  const cardData = [
    {
      imageSrc: "Web_logo/Icon/persanal.png",
      title: "Personality Development",
      description: "Regular Personality Development activities organized by industry experts to increase self-awareness and stress management.",
    },
    {
      imageSrc: "Web_logo/Icon/Public-talk-amico.png",
      title: "Public Speaking",
      description: "Activities to boost confidence and communication skills, enabling professional presentation.",
    },
    {
      imageSrc: "Web_logo/Icon/speaking.png",
      title: "Language Fluency: English",
      description: "Activities to develop English language fluency and improve client communication.",
    },
    {
      imageSrc: "Web_logo/Icon/leadership.png",
      title: "Leadership Skill",
      description: "Leadership activities to develop team handling and growth-oriented qualities.",
    },
  ];

  return (
    <>

<Helmet>
                
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="x-ua-compatible" content="ie=edge" />
                <title>Skillyards Club Facilitation</title>
                <meta name="description"
                    content="Learn to communicate your codes with Skillyards Club Facilitation!" />
                <meta name="keywords" content="Skillyards, Club Facilitation, educational, powerhouse" />
                <meta name="author" content="Skillyards" />


                <link rel="apple-touch-icon" sizes="180x180" href="Web_logo/favicon/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="Web_logo/favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="Web_logo/favicon/favicon-16x16.png" />
                <link rel="mask-icon" href="Web_logo/favicon/safari-pinned-tab.svg" color="#5bbad5" />
                <link rel="canonical" href="https://www.skillyards.com/club" />
            </Helmet>
    
    
    
    <div className="bg-gradient-to-br from-blue-50 to-purple-50 py-16">
      {/* Section 1: Text and Image */}
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center gap-12 mb-16">
          <div className="w-full md:w-1/2">
            <div className="text-5xl font-bold text-gray-800 mb-6">
           <div> <p >Learn to Communicate </p><p className='mt-3'> Your Codes with  </p><p className="text-yellow-400 mt-3">Skillyards Club!</p> <p className='mt-3'> Facilitation! </p> </div>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <img
              src="Web_logo/skillcub.png" // Replace with actual image path
              alt="Skillyards Club"
              className="w-full h-auto rounded-lg" // Added rounded corners and shadow
            />
          </div>
        </div>

        {/* Section 2: Heading and Description */}
        <div className="text-center mb-16">
          <h1 className="text-5xl font-bold text-gray-800 mb-6">
          Welcome To <span className="text-yellow-400">Skillyards Club</span> Facilitation
      <div className="w-[50vw] h-1 bg-yellow-400 mx-auto mt-2 mb-8"></div>

          </h1>
          <p className="text-base text-gray-600 text-justify max-w-3xl mx-auto">
          According to several researches, sharing our ideas and opinions with others is the best method to remember them and put them into practice. In fact, taking part in discussions enhances our retention by 70%. It is proven that in students' learning outcomes, there is an increase of 20% in exam scores of students engaging in group discussions and 0 if students listened to lectures only.

This is why at Skillyards, we are aware of the fact as to why practical development is a key to professionalism. But nowadays, it is common to identify that even well-trained and skilled IT experts lack in putting codes into words. Skillyards Facilitation Club aims to bridge this communication gap.
          </p>
        </div>

        {/* Section 3: Image, Heading, and Description Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {cardData.map((card, index) => (
            <Card
              key={index}
              imageSrc={card.imageSrc}
              title={card.title}
              description={card.description}
            />
          ))}
        </div>
      </div>
    </div>
    </>  );
};

export default Club;