import { Helmet } from "react-helmet";
import BgDisplayItems from "../common/BgDisplayItem";
// import DisplayInfo from "../common/DisplayInfo";
import FAQ from "../common/FAQ";
// import ImageDisplays from "../common/ImageDisplays";
import CombinedDisplay from "../common/mergecontentDisplay";

const BackEndWebDevelopment = () => {
  const staticItem = {
    id: 1,
    title: "Back-End Development Training In Agra",
    subtitle: "With the On-The Job BCA, MCA, or BBA Degree Program",
    description: [
      "Project-based training with Skillyards Club Facilitation",
      "Paid Internship with stipend up to ₹15,000/month",
      "Guaranteed Placement up to 10 LPA in Top MNCs",
    ],
    buttonText: "Join Now",
    buttonAction: "#",
    backgroundImage: "/Web_logo/Background/backend.png",
  };

  const simpleItem = {
    heading: "Back-End Development Training Program",
    subheading: "Duration: 6 Months Training + 3 Months Internship ",
    data: {
      dataHeading1: {
        title: "Basic Fundamentals",
        items: ["Introduction ", "Local environment setup "],
      },
      dataHeading2: {
        title: "Development",
        items: [
          "Node JS",
          "OOPS Concept",
          "Express JS",
          "Restful API",
          "CRUD Operation",
          "Real-Time Response Api",
          "Session Maintan",
          "Secure Backend (API)",
          "Authentication (JWT)",
          "Cookies maintain",
          "Secured user data",
          "Secure Api ",
        ],
      },
      dataHeading3: {
        title: "Database",
        items: ["NoSQL (MongoDB)", "SQL (MySQL)"],
      },
      dataHeading4: {
        title: "Dev’s Tools",
        items: ["Docker", "GitHub (Git)", "Kubernetes", "AWS", "GCP & Azure"],
      },
      dataHeading5: {
        title: "Project Practice",
        items: ["Industry Level", "Company Projects"],
      },
      dataHeading6: {
        title: "Internship",
        items: ["3 Months paid internship", "Stipend of upto INR 15000"],
      },
    },
  };

  const images = [
    "assets/full-stack-webp/pexels-mizunokozuki-12899157_8_11zon.webp",
    "assets/full-stack-webp/pexels-picjumbo-com-55570-196644_3_11zon_10_11zon.webp",
    "assets/full-stack-webp/pexels-tranmautritam-326514_4_11zon_13_11zon.webp",
    "assets/full-stack-webp/pexels-pixabay-276452_12_11zon.webp",
  ];

  const FAQ_ITEMS = [
    {
      question: "Is EMI available in the Back-End Training Program?",
      answer: `Yes, EMI option is available in the Back-End Training Program so that the students do not feel the need to submit the program fees in one go.`,
    },
    {
      question:
        "Can I learn Back-End Development without having a computer science degree?",
      answer: `Absolutely Yes! At Skillyards we focus more on practical knowledge rather than pursuing any degree. This is why it is very easy for students to learn back-end development without having a computer background or even a degree.`,
    },
    {
      question:
        "What are the languages that are taught in the Back-End Development Training Program?",
      answer: `The program covers NodeJS, Express JS, MySQL, and more to help students maintain a diversified portfolio of skills.`,
    },
  ];
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Best Back-End Development Training in Agra</title>
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Develop expertise in back-end systems with our Back-End Development Training in Agra & get to learn topics like APIs, OOPS, GitHub & much more"
        />
        <meta
          name="keywords"
          content="freelance backend developer, freelance backend, back end web developer course, backend developer c#, backend nodejs developer, back and front end, back and front end developer, back end developer front end developer, back end developer in hindi, back end development using python"
        />
        <meta name="author" content="Skillyards" />
        <link
          rel="canonical"
          href="https://www.skillyards.com/back-end-web-developer-training-in-agra"
        />
      </Helmet>
      <BgDisplayItems item={staticItem} />
      <div>
        {/* <DisplayInfo item={simpleItem} />
                <ImageDisplays images={images} /> */}
        <CombinedDisplay item={simpleItem} images={images} />
      </div>
      <FAQ faqItems={FAQ_ITEMS} />
    </div>
  );
};

export default BackEndWebDevelopment;
