// BlogContentForm.tsx

import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { toast } from "sonner";
import { BlogData, ContentItem } from "../../types";

// Define the ContentFormData type
interface ContentFormData {
  tablename: string;
  heading: string;
  blogImage: File | null; // File object for the form
  discription: string;
  discription2: string;
}

interface BlogContentFormProps {
  blogData: BlogData;
  setBlogData: React.Dispatch<React.SetStateAction<BlogData>>;
  token?: string;
  contentData: ContentItem[];
  setContentData: React.Dispatch<React.SetStateAction<ContentItem[]>>;
}

const BlogContentForm: React.FC<BlogContentFormProps> = ({
  setBlogData,
  setContentData,
}) => {
  const [contentForm, setContentForm] = useState<ContentFormData>({
    tablename: "",
    heading: "",
    blogImage: null,
    discription: "",
    discription2: "",
  });

  const handleFormChange = (field: keyof ContentFormData, value: string | File | null) => {
    setContentForm((prev) => ({ ...prev, [field]: value }));
  };

  const saveContent = () => {
    // Validate required fields
    if (!contentForm.heading.trim()) {
      toast.error("Please provide a heading");
      return;
    }

    // Transform ContentFormData into ContentItem
    const newContentItem: ContentItem = {
      _id: Math.random().toString(36).substring(2, 9), // Generate a temporary ID
      heading: contentForm.heading,
      tablename: contentForm.tablename,
      discription: contentForm.discription,
      discription2: contentForm.discription2,
      images: contentForm.blogImage ? URL.createObjectURL(contentForm.blogImage) : "", // Convert File to URL
    };

    // Update the blog data
    setBlogData((prev) => ({
      ...prev,
      content: [...prev.content, newContentItem],
    }));

    // Update the content data
    setContentData((prev) => [...prev, newContentItem]);

    // Reset the form
    setContentForm({
      tablename: "",
      heading: "",
      blogImage: null,
      discription: "",
      discription2: "",
    });

    toast.success("Content saved successfully!");
  };

  return (
    <div className="blog-content-form bg-white p-6 rounded-lg shadow-md w-full mx-auto">
      <h2 className="text-2xl font-semibold mb-6 text-gray-800">Add Blog Content</h2>

      <div className="space-y-4">
        <div>
          <Label>Table of Content Name</Label>
          <Input
            type="text"
            placeholder="Table of content name"
            value={contentForm.tablename}
            onChange={(e) => handleFormChange("tablename", e.target.value)}
          />
        </div>

        <div>
          <Label>Heading</Label>
          <Input
            type="text"
            placeholder="Heading"
            value={contentForm.heading}
            onChange={(e) => handleFormChange("heading", e.target.value)}
          />
        </div>

        <div>
          <Label>Blog Image</Label>
          <Input
            type="file"
            accept="image/*"
            onChange={(e) =>
              handleFormChange("blogImage", e.target.files?.[0] || null)
            }
          />
        </div>

        <div>
          <Label>Description</Label>
          <CKEditor
            editor={ClassicEditor}
            data={contentForm.discription}
            onChange={(_, editor) =>
              handleFormChange("discription", editor.getData())
            }
          />
        </div>

        <div>
          <Label>Out of Container Description</Label>
          <CKEditor
            editor={ClassicEditor}
            data={contentForm.discription2}
            onChange={(_, editor) =>
              handleFormChange("discription2", editor.getData())
            }
          />
        </div>

        <Button onClick={saveContent} className="w-full">
          Add Content
        </Button>
      </div>
    </div>
  );
};

export default BlogContentForm;