
import React, { useEffect, useState } from "react";
import { Users, BookOpen, BadgeCheck, Wallet } from "lucide-react";
import axios from "axios";
// import { NotificationContainer, NotificationManager } from "react-notifications";
// import "react-notifications/lib/notifications.css";
import checkAuth from "../authentication/auth";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"; // Assuming shadcn/ui is used
import { toast } from "sonner";

const api = `${import.meta.env.VITE_APP_API_URL}`;
// const api = "http://localhost:8000/api";

interface Certificate {
  id: string;
  // Add other fields as needed
}

const Dashboard: React.FC = () => {
  const token = checkAuth()||"";
  const [certificates, setCertificates] = useState<Certificate[]>([]);

  const expireSession = (message: string) => {
    if (message === "Session Expired") {
      localStorage.removeItem("token_tr");
      localStorage.removeItem("name");
      toast.warning("Session expired. Redirecting to login...", {
        duration: 3000, // Show for 3 seconds
      }); // Warning toast
      setTimeout(() => {
        window.location.assign("/admin/login");
      }, 500);
    } else {
      toast.error(message); // Error toast for other messages
    }
  };

  const getCertificates = async () => {
    try {
      toast.loading("Fetching certificates..."); // Loading toast
      const res = await axios(`${api}/admin/certificate`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });
      if (res.status === 200) {
        setCertificates(res.data.data);
        toast.success("Certificates fetched successfully!"); // Success toast
      }
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error) && error.response) {
        expireSession(error.response.data.message);
      } else {
        expireSession("Something went wrong");
      }
    } finally {
      toast.dismiss(); // Dismiss loading toast
    }
  };

  useEffect(() => {
    if (token) {
      getCertificates();
    }
  }, [token]);


  const cardData = [
    { title: "Students", count: 0, icon: <Users size={32} /> },
    { title: "Certificates", count: certificates.length, icon: <BadgeCheck size={32} /> },
    { title: "Total Transactions", count: 0, icon: <Wallet size={32} /> },
    { title: "Booked Courses", count: 0, icon: <BookOpen size={32} /> },
  ];

  return (
    <div className="p-6">
     {/* // <NotificationContainer /> */}
      <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Admin Dashboard</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
        {cardData.map((card, index) => (
          <Card key={index} className="shadow-md hover:shadow-lg transition-shadow">
            <CardHeader className="flex items-center justify-center p-4">
              <div className="text-blue-500">{card.icon}</div>
            </CardHeader>
            <CardContent className="text-center">
              <CardTitle className="text-lg font-semibold">{card.title}</CardTitle>
              <p className="text-3xl font-bold mt-2">{card.count}</p>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;