import React from "react";
import { Helmet } from "react-helmet";

import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card"; // shadcn components
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@/components/ui/accordion"; // shadcn components

import WorkshopRegistration from "../fullStackDevelopment/whoCanJoin";
import { BookOpen, MonitorPlay, Paintbrush, Video, FileText, LineChart, Share, Search, Globe, Mail, Globe2, Users, TrendingUp, Smartphone, FileCode,  DollarSign,  Link, Shield, UserCheck,  BriefcaseMedical, Share2, Clipboard } from 'lucide-react';
const DigitalMarketing: React.FC = () => {
  const faqItems = [
    {
      question:
        "Is EMI available in the Advanced Digital Marketing Training Program?",
      answer:
        "Yes, EMI option is available in the Advanced Digital Marketing Training Program so that the students do not feel the need to submit the program fees in one go.",
    },
    {
      question:
        "Are there any additional certifications provided in the Advanced Digital Marketing Training Program?",
      answer:
        "Yes, you will earn 15+ globally recognized certifications including Meta, Google Ads, Semrush, Hubspot, and more to add to your resume and growth!",
    },
    {
      question: "Who can get enrolled in this program?",
      answer:
        "Regardless of the occupation of a person – from a housewife to a business owner, or from the 12th student to the postgraduate, the training program in advanced digital marketing is open to everyone. The curriculum is carefully planned so that people of different needs can be suitably provided for.",
    },
  ];

  const curriculum = [
    {
      title: "Basics of Marketing and Branding",
      icon: BookOpen,
      items: [
        "What is Marketing?",
        "Introduction to Marketing Mix",
        "What are the channels of marketing?",
        "Commencing with branding",
        "Getting started with Online Market Research",
      ],
    },
    {
      title: "Introduction to Digital Marketing",
      icon: MonitorPlay,
      items: [
        "The positive change in online marketing",
        "What are online marketing channels?",
        "What are cases in Digital campaigns?",
        "Understanding the essentials of digital marketing strategy",
      ],
    },
    {
      title: "Graphics Designing for Business",
      icon: Paintbrush,
      items: [
        "Fundamentals of graphic designing",
        "Toolkits of design marketing",
        "Getting started with designing online documents",
      ],
    },
    {
      title: "Video Marketing Fundamentals",
      icon: Video,
      items: [
        "Introduction to video marketing",
        "Why is Video Marketing essential?",
        "What are the types of online videos?",
        "How to create a live explainer video?",
      ],
    },
    {
      title: "Professional Blogging",
      icon: FileText,
      items: [
        "Kickstarting with blogging",
        "What is the difference between personal and professional blogging?",
        "What are the best practices in blogging?",
        "How to set up your blog- Live",
      ],
    },
    {
      title: "Advanced Web Analytics",
      icon: LineChart,
      items: [
        "How to set up segment, filter, and reports",
        "Process of creating a custom URL",
        "Introduction to Google Tag Manager",
      ],
    },
    {
      title: "Social Media Optimization for Business",
      icon: Share,
      items: [
        "Kickstarting the journey of Social Media Optimization",
        "Getting to know different social media networks",
        "How to optimize your Facebook pages for business",
        "How to optimize your business presence on Twitter",
        "Optimize your LinkedIn profile",
        "How to filter your niche for an advanced profile?",
      ],
    },
    {
      title: "Search Engine Optimization (SEO)",
      icon: Search,
      items: [
        "Introduction to SEO",
        "How to select and map the best keywords?",
        "How to optimize your content?",
        "Knowing more about Onpage SEO and HTML Tag optimization",
        "Getting ahead with Off Page SEO & building your link",
        "Local Search Engine Optimization",
      ],
    },
    {
      title: "Online Advertising and Google Adwords",
      icon: Globe,
      items: [
        "Starting with paid marketing",
        "What are programmatic and direct ads?",
        "What are Search ads?",
        "Kickstarting with Google Adwords search (PPC) advertising",
        "What are the best practices in search campaigns?",
      ],
    },
    {
      title: "Email Marketing",
      icon: Mail,
      items: [
        "Getting started in Email Marketing",
        "Why is email marketing essential?",
        "Starting to write effective emails",
        "Creating email strategies",
        "Knowing more about email marketing tools",
        "How to do email marketing reporting and analysis for clients?",
      ],
    },
    {
      title: "Website Planning and Creation",
      icon: Globe2,
      items: [
        "How to buy domain & hosting?",
        "A guide to uploading and customizing HTML pages",
        "How to create a WordPress website using hands-on training",
        "Setting up SEO, website, and Google Analytics",
        "Advancing with WordPress",
      ],
    },
    {
      title: "Lead Generation and Marketing Automation",
      icon: Users,
      items: [
        "Getting started with Lead Generation",
        "What are lead journey funnels?",
        "What are the 4 pillars of lead generation?",
        "How to create converting landing pages?",
        "Getting a little help from marketing automation tools live",
      ],
    },
    {
      title: "Conversion Rate Optimization (CRO)",
      icon: TrendingUp,
      items: [
        "Kickstarting with CRO",
        "Reasons why CRO is essential",
        "What Are the Best Practices for A/B Testing on Multiple Analytics Variants?",
        "Analyzing Heat maps",
      ],
    },
    {
      title: "Mobile Marketing",
      icon: Smartphone,
      items: [
        "Getting to know about the mobile landscape in India",
        "Kickstarting with the types- SMS & WhatsApp marketing",
        "What is Mobile Website Optimization?",
        "What are the tools to setup a mobile app?",
        "How to optimize your app on the app store?",
      ],
    },
    {
      title: "Advanced Content Marketing",
      icon: FileCode,
      items: [
        "What is content marketing?",
        "Mastering the basics of inbound marketing?",
        "How to generate content ideas for business?",
        "Writing effective content",
        "How to start with promoting content?",
      ],
    },
    {
      title: "Video Marketing Fundamentals",
      icon: Video,
      items: [
        "Introduction to video marketing",
        "Why is Video Marketing essential?",
        "What are the types of online videos?",
        "How to create a live explainer video?",
      ],
    },
    {
      title: "Social Media Strategy & Management",
      icon: Share2,
      items: [
        "How to create a Social Media Calendar?",
        "What are calendar-based posts?",
        "What are the tools used for posting on social media?",
        "How to build a good social media community?",
        "Mastering social media monitoring techniques",
        "Managing online reputation",
      ],
    },
    {
      title: "Advanced Search Advertising",
      icon: Search,
      items: [
        "What are dynamic ads?",
        "What are call-only campaigns?",
        "Setting up conversion tracking",
        "Analyzing live campaigns",
        "Getting started with Advanced Bid Management",
        "What are Bing ads?",
      ],
    },
    {
      title: "Google Display Network Advertising",
      icon: Globe,
      items: [
        "How to get started with Google Display Networks?",
        "Kickstarting your journey with Google Display Planner tool",
        "How to launch a display campaign",
        "What are the best practices for display campaigns?",
        "What are app install campaigns?",
      ],
    },
    {
      title: "Social Media Advertising",
      icon: Share2,
      items: [
        "Creating effective social media ads",
        "Getting started with advertising on Facebook & Instagram",
        "Advertising on X (Twitter)",
        "Advertising on LinkedIn",
      ],
    },
    {
      title: "Online Money Making",
      icon: DollarSign,
      items: [
        "Top ways to generate income online",
        "How to identify your niche",
        "Create a revenue stream through YouTube",
        "Top strategies to generate income using Google AdSense",
        "Getting used to with Affiliate Marketing Models",
        "Domain flipping - One of the best paying streams",
      ],
    },
    {
      title: "Affiliate Marketing",
      icon: Link,
      items: [
        "Getting ahead with affiliate marketing",
        "How to set affiliate marketing as a good revenue stream?",
        "Essentials of becoming an affiliate marketer",
        "Affiliate marketing methods that have shown proven results",
        "How to choose a good affiliate program?",
        "Maximizing success with affiliate marketing programs",
      ],
    },
    {
      title: "Online Reputation Management",
      icon: Shield,
      items: [
        "Tools to effectively manage your online reputation",
        "Strategies to grow your online reputation",
        "Why is corporate reputation so important in this digital age?",
      ],
    },
    {
      title: "Interview Preparation",
      icon: UserCheck,
      items: [
        "Factors to consider when choosing your role",
        "Steps you can follow to select the right job for yourself",
        "The ultimate checklist for preparing for your company role and JD",
        "Navigating your interview day - Dos & Don'ts",
      ],
    },
    {
      title: "Tools & Certifications",
      icon: Clipboard,
      items: [
        "30+ tools including Semrush, Buzzsumo, Ahrefs, Hubspot and many more",
        "15+ globally recognized certifications such as Google Adwords, Meta, Semrush, Hubspot",
      ],
    },
    {
      title: "Internship",
      icon: BriefcaseMedical,
      items: [
        "3 Months paid internship",
        "Stipend of up to INR 15000",
      ],
    }
  ];

  return (
    <>
      <Helmet>
        {/* Character Encoding */}
        <meta charSet="utf-8" />

        {/* Page Title */}
        <title>
          Best Digital Marketing Training in Agra | 15+ Certifications |
          Skillyards
        </title>

        {/* Browser Compatibility */}
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />

        {/* Responsive Viewport */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Meta Description */}
        <meta
          name="description"
          content="Join the Best Digital Marketing Training in Agra at Skillyards. Master SEO, PPC, Social Media Marketing, Dropshipping, Email Marketing, and more with 15+ globally recognized certifications. Enroll now to boost your career with hands-on training and expert guidance."
        />

        {/* Keywords */}
        <meta
          name="keywords"
          content="digital marketing training in Agra, best digital marketing course Agra, SEO training Agra, PPC course Agra, social media marketing institute Agra, dropshipping course Agra, email marketing training, content marketing course, affiliate marketing training, Google Ads certification, Facebook Ads training, advanced digital marketing training, Skillyards Agra, digital marketing certifications, online marketing bootcamp"
        />

        {/* Author */}
        <meta name="author" content="Skillyards" />

        {/* Canonical URL */}
        <link
          rel="canonical"
          href="https://skillyards.com/digital-marketing-training-in-agra"
        />

        {/* Open Graph (OG) Meta Tags */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Best Digital Marketing Training in Agra | 15+ Certifications | Skillyards"
        />
        <meta
          property="og:description"
          content="Enroll in Skillyards' Digital Marketing Training in Agra. Master SEO, PPC, Social Media Marketing, Dropshipping, and more with 15+ globally recognized certifications. Start your career in digital marketing today!"
        />
        <meta
          property="og:url"
          content="https://skillyards.com/digital-marketing-training-in-agra"
        />
        <meta
          property="og:image"
          content="https://skillyards.com/assets/images/digital-marketing-course-banner.jpg"
        />
        <meta property="og:site_name" content="Skillyards" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Best Digital Marketing Training in Agra | Skillyards | 15+ Certifications"
        />
        <meta
          name="twitter:description"
          content="Join Skillyards' expert-led Digital Marketing Training in Agra. Gain 15+ certifications and master SEO, PPC, Social Media Marketing, Dropshipping, and more. Enroll now!"
        />
        <meta
          name="twitter:image"
          content="https://skillyards.com/assets/images/digital-marketing-course-banner.jpg"
        />
        <meta name="twitter:site" content="@Skillyards" />
        <meta name="twitter:creator" content="@Skillyards" />

        {/* Favicon */}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="Web_logo/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="Web_logo/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="Web_logo/favicon/favicon-16x16.png"
        />
        <link
          rel="mask-icon"
          href="Web_logo/favicon/safari-pinned-tab.svg"
          color="#5bbad5"
        />
      </Helmet>

      <div className="container mx-auto px-4 py-12">
        <WorkshopRegistration />
        <Card className="mb-8">
          <CardHeader>
            <CardTitle className="text-3xl font-bold  text-center">
              Advanced Digital Marketing Training Program
            </CardTitle>
            <CardDescription className="text-lg text-center">
              Duration: 6 Months Training + 3 Months Internship
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-6 grid sm:grid-cols-3">
              {curriculum.map((section, index) => (
                <div key={index}>
                  <h3 className="text-xl font-semibold flex items-center gap-2">
                    <section.icon className="w-5 h-5" /> {section.title}
                  </h3>
                  <ul className="list-disc list-inside mt-2">
                    {section.items.map((item, i) => (
                      <li key={i} className="text-gray-700">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="text-3xl font-bold text-center">
              Frequently Asked Questions
            </CardTitle>
          </CardHeader>
          <CardContent>
            <Accordion type="single" collapsible className="w-full">
              {faqItems.map((faq, index) => (
                <AccordionItem key={index} value={`item-${index}`}>
                  <AccordionTrigger>{faq.question}</AccordionTrigger>
                  <AccordionContent>{faq.answer}</AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default DigitalMarketing;
