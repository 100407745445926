import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardHeader, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { toast } from 'sonner';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

interface User {
  id: string;
  name: string;
  email: string;
  notificationsToken?: string;
}

interface NotificationPayload {
  title: string;
  body: string;
  users: string[];
  url?: string;
  image?: string;
  icon?: string;
}

export default function SendPushNotification() {
  const [users, setUsers] = useState<User[]>([]);
  const [formData, setFormData] = useState({
    title: '',
    body: '',
    url: '',
    image: '',
    icon: ''
  });
  const [loading, setLoading] = useState(false);

  const api = `${import.meta.env.VITE_APP_API_URL}`;

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${api}/admin/get-token`);

      if (response.data?.data) {
        const validUsers = response.data.data.filter(
          (user: User) => user.notificationsToken
        );
        setUsers(validUsers);
        
        if (validUsers.length === 0) {
          toast.warning('No users with valid notification tokens found');
        }
      } else {
        throw new Error(response.data.message || 'Failed to fetch users');
      }
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'Failed to fetch users');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const validateForm = (): boolean => {
    if (!formData.title.trim()) {
      toast.error('Notification title is required');
      return false;
    }

    if (!formData.body.trim()) {
      toast.error('Notification body is required');
      return false;
    }

    if (formData.url && !isValidUrl(formData.url)) {
      toast.error('Please enter a valid URL');
      return false;
    }

    if (formData.image && !isValidUrl(formData.image)) {
      toast.error('Please enter a valid image URL');
      return false;
    }

    if (formData.icon && !isValidUrl(formData.icon)) {
      toast.error('Please enter a valid icon URL');
      return false;
    }

    return true;
  };

  const isValidUrl = (url: string): boolean => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const getValidTokens = (): string[] => {
    return users
      .map(user => user.notificationsToken!)
      .filter(Boolean);
  };

  const resetForm = () => {
    setFormData({
      title: '',
      body: '',
      url: '',
      image: '',
      icon: ''
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) return;

    const validTokens = getValidTokens();
    if (validTokens.length === 0) {
      toast.error('No valid FCM tokens found for users');
      return;
    }

    const notificationPayload: NotificationPayload = {
      title: formData.title,
      body: formData.body,
      users: validTokens,
      ...(formData.url && { url: formData.url }),
      ...(formData.image && { image: formData.image }),
      ...(formData.icon && { icon: formData.icon })
    };

    setLoading(true);
    try {
      const response = await axios.post(
        `${api}/admin/send-notification`,
        notificationPayload
      );

      if (response.data.success) {
        toast.success(`Successfully sent notifications to ${response.data.successCount} users`);
        resetForm();
        fetchUsers();
      } else {
        throw new Error(response.data.message || 'Failed to send notifications');
      }
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'Failed to send notifications');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="max-w-4xl mx-auto">
      <CardHeader>
        <h1 className="text-2xl font-bold">Send Push Notifications</h1>
        <p className="text-sm text-gray-500">
          Sending to {users.length} users with valid notification tokens
        </p>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-6">
          <Tabs defaultValue="basic">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="basic">Basic Details</TabsTrigger>
              <TabsTrigger value="media">Media & Actions</TabsTrigger>
            </TabsList>
            
            <TabsContent value="basic" className="space-y-4 mt-4">
              <div>
                <label className="block mb-2 font-medium">Notification Title</label>
                <Input
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  placeholder="Enter notification title"
                />
              </div>

              <div>
                <label className="block mb-2 font-medium">Notification Body</label>
                <Textarea
                  name="body"
                  value={formData.body}
                  onChange={handleInputChange}
                  rows={3}
                  placeholder="Enter notification message"
                />
              </div>

              <div>
                <label className="block mb-2 font-medium">Redirect URL</label>
                <Input
                  name="url"
                  value={formData.url}
                  onChange={handleInputChange}
                  placeholder="https://example.com/page"
                />
                <p className="text-sm text-gray-500 mt-1">
                  Optional. Users will be redirected to this URL when clicking the notification.
                </p>
              </div>
            </TabsContent>
            
            <TabsContent value="media" className="space-y-4 mt-4">
              <div>
                <label className="block mb-2 font-medium">Notification Image URL</label>
                <Input
                  name="image"
                  value={formData.image}
                  onChange={handleInputChange}
                  placeholder="https://example.com/image.jpg"
                />
                <p className="text-sm text-gray-500 mt-1">
                  Optional. Large image shown in the expanded notification (best supported on Android).
                </p>
              </div>

              <div>
                <label className="block mb-2 font-medium">Notification Icon URL</label>
                <Input
                  name="icon"
                  value={formData.icon}
                  onChange={handleInputChange}
                  placeholder="https://example.com/icon.png"
                />
                <p className="text-sm text-gray-500 mt-1">
                  Optional. Small icon displayed in the notification bar.
                </p>
              </div>
              
              {formData.image && (
                <div className="mt-4 border p-4 rounded-md">
                  <p className="font-medium mb-2">Image Preview</p>
                  <img 
                    src={formData.image} 
                    alt="Notification preview" 
                    className="max-h-40 rounded border" 
                    onError={(e) => {
                      e.currentTarget.src = "/api/placeholder/200/100";
                      toast.error("Failed to load image preview - check URL");
                    }}
                  />
                </div>
              )}
            </TabsContent>
          </Tabs>

          <Button
            type="submit"
            disabled={loading || users.length === 0}
            className="w-full"
          >
            {loading ? 'Sending...' : `Send to ${users.length} Recipients`}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}