import React, { useEffect, useState, useRef } from "react";
import { Phone, MapPin, Calendar, Mail, CreditCard, ChevronDown, ChevronUp, Filter, RotateCcw, LayoutDashboard } from "lucide-react";
import axios from "axios";
import Pagination from "../pagination";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"; // shadcn Select
import { Button } from "@/components/ui/button"; // shadcn Button
import { Card, CardHeader, CardTitle } from "@/components/ui/card"; // shadcn Card
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "@/components/ui/table"; // shadcn Table

interface Payment {
  paymentId: string;
  razorpayOrderId: string;
  razorpayPaymentId: string;
  amount: number;
  currency: string;
  paymentDate: string;
  paymentStatus?: boolean;
  couponCode?: string;
  discount?: number;
}

interface Contact {
  location: any;
  userId: string;
  name: string;
  mobile: string;
  email: string;
  city: string;
  role: string;
  enrollmentType: string;
  payments: Payment[];
}

const DigitalMarketingDashboard: React.FC = () => {
  const [contact, setContact] = useState<Contact[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});
  const api = `${import.meta.env.VITE_APP_API_URL}/payment/payment-details`;
  const originalData = useRef<Contact[]>([]);

  const totalPages = Math.ceil(contact.length / itemsPerPage);
  const currentItems = contact.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const toggleRow = (id: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const formatDateTime = (datetime: string) => {
    const dateObj = new Date(datetime);
    const date = dateObj.getDate();
    const month = dateObj.toLocaleString("default", { month: "short" });
    let hours = dateObj.getHours();
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${date}-${month}, ${hours}:${minutes} ${ampm}`;
  };

  const handleFilterChange = (value: string) => {
    if (value === "completed") {
      setContact(originalData.current.filter((elm) => elm.payments[0]?.paymentStatus));
    } else if (value === "pending") {
      setContact(originalData.current.filter((elm) => !elm.payments[0]?.paymentStatus));
    } else {
      setContact(originalData.current);
    }
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(api);
      console.log(res.data.data);
      setContact(res.data.data);
      originalData.current = res.data.data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="w-full">
      {loading ? <p>Loading...</p> : null}
      <Card className="w-full mb-8">
        <CardHeader>
          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3">
            <div className="flex items-center space-x-2">
              <LayoutDashboard className="w-5 h-5 text-blue-600" />
              <CardTitle className="text-xl font-semibold text-gray-800">Digital Marketing Controller</CardTitle>
            </div>

            <div className="flex flex-wrap items-center gap-3 w-full sm:w-auto">
              <Select onValueChange={handleFilterChange}>
                <SelectTrigger className="w-[200px]">
                  <Filter className="w-4 h-4 text-gray-500 mr-2" />
                  <SelectValue placeholder="Filter" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="completed">Payment Done</SelectItem>
                  <SelectItem value="pending">Pending</SelectItem>
                </SelectContent>
              </Select>

              <Button variant="outline" onClick={() => setContact(originalData.current)}>
                <RotateCcw className="w-4 h-4 mr-2" />
                Reset
              </Button>
            </div>
          </div>
        </CardHeader>
      </Card>

      {/* Desktop View */}
      <Card className="hidden lg:block">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>S.No</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Number</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>City</TableHead>
              <TableHead>Role</TableHead>
              <TableHead>Enrollment Type</TableHead>
              <TableHead>Payment Date</TableHead>
              <TableHead>Payment Status</TableHead>
              <TableHead>Payment ID</TableHead>
              <TableHead>Coupon Code</TableHead>
              <TableHead>Discount</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {currentItems.map((record, i) => (
              <TableRow key={record.userId}>
                <TableCell>{(currentPage - 1) * itemsPerPage + i + 1}</TableCell>
                <TableCell>{record.name}</TableCell>
                <TableCell>{record.mobile}</TableCell>
                <TableCell>{record.email}</TableCell>
                <TableCell>{record.city}</TableCell>
                <TableCell>{record.role}</TableCell>
                <TableCell>{record.enrollmentType}</TableCell>
                <TableCell>
                  {record.payments[0]?.paymentDate ? formatDateTime(record.payments[0].paymentDate) : "N/A"}
                </TableCell>
                <TableCell>
                  <span
                    className={`px-3 py-1 text-xs font-medium text-white rounded-full ${
                      record.payments[0]?.paymentStatus ? "bg-red-400" : "bg-green-400"
                    }`}
                  >
                    {record.payments[0]?.paymentStatus ? "Pending" : "Done"}
                  </span>
                </TableCell>
                <TableCell>{record.payments[0]?.razorpayPaymentId || "N/A"}</TableCell>
                <TableCell>{record.payments[0]?.couponCode || "N/A"}</TableCell>
                <TableCell>{record.payments[0]?.discount !== undefined ? `${record.payments[0].discount}%` : "N/A"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </Card>

      {/* Mobile View */}
      <div className="md:hidden space-y-4">
        {currentItems.map((record, i) => (
          <Card key={record.userId} className="p-4">
            <div className="flex justify-between items-center mb-3">
              <span className="text-xs font-medium text-gray-500">
                #{(currentPage - 1) * itemsPerPage + i + 1}
              </span>
              <div
                className={`px-3 py-1 text-xs font-medium text-white rounded-full ${
                  record.payments[0]?.paymentStatus ? "bg-green-400" : "bg-red-400"
                }`}
              >
                {record.payments[0]?.paymentStatus ? "Done" : "Pending"}
              </div>
            </div>

            <div className="space-y-3">
              <div className="font-medium text-gray-900">{record.name}</div>
              <div className="space-y-2">
                <div className="flex items-center text-sm text-gray-500 space-x-2">
                  <Phone className="w-4 h-4" />
                  <span>{record.mobile}</span>
                </div>
                <div className="flex items-center text-sm text-gray-500 space-x-2">
                  <Mail className="w-4 h-4" />
                  <span className="break-all">{record.email}</span>
                </div>
                <div className="flex items-center text-sm text-gray-500 space-x-2">
                  <MapPin className="w-4 h-4" />
                  <span>{record.city}</span>
                </div>
                <div className="flex items-center text-sm text-gray-500 space-x-2">
                  <LayoutDashboard className="w-4 h-4" />
                  <span>{record.role}</span>
                </div>
                <div className="flex items-center text-sm text-gray-500 space-x-2">
                  <LayoutDashboard className="w-4 h-4" />
                  <span>{record.enrollmentType}</span>
                </div>
              </div>

              {record.payments[0]?.paymentStatus && (
                <div className="space-y-2">
                  <div className="flex items-center text-sm text-gray-500 space-x-2">
                    <Calendar className="w-4 h-4" />
                    <span>{formatDateTime(record.payments[0].paymentDate)}</span>
                  </div>
                  <div className="flex items-center text-sm text-gray-500 space-x-2">
                    <CreditCard className="w-4 h-4" />
                    <span className="break-all">{record.payments[0].razorpayPaymentId}</span>
                  </div>
                  <div className="flex items-center text-sm text-gray-500 space-x-2">
                    <CreditCard className="w-4 h-4" />
                    <span className="break-all">{record.payments[0].couponCode}</span>
                  </div>
                  <div className="flex items-center text-sm text-gray-500 space-x-2">
                    <CreditCard className="w-4 h-4" />
                    <span className="break-all">{record.payments[0].discount !== undefined ? `${record.payments[0].discount}%` : "N/A"}</span>
                  </div>
                </div>
              )}
            </div>

            {record.location?.length > 100 && (
              <Button
                variant="ghost"
                onClick={() => toggleRow(record.userId)}
                className="mt-2 text-blue-500 text-sm flex items-center"
              >
                {expandedRows[record.userId] ? (
                  <>Show less <ChevronUp className="w-4 h-4 ml-1" /></>
                ) : (
                  <>Show more <ChevronDown className="w-4 h-4 ml-1" /></>
                )}
              </Button>
            )}
          </Card>
        ))}
      </div>

      {currentItems.length === 0 && (
        <Card className="flex flex-col items-center justify-center py-8 text-gray-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12 text-gray-400 mb-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 14l2-2m0 0l2-2m-2 2l2 2m-2-2l-2-2m-6 2a9 9 0 1118 0 9 9 0 01-18 0z"
            />
          </svg>
          <p className="text-lg font-semibold">No Matching Records Found</p>
          <p className="text-sm text-gray-400 mt-1">Please try adjusting your filters or search criteria.</p>
        </Card>
      )}
    </div>
  );
};

export default DigitalMarketingDashboard;
