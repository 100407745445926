
import  { useState } from 'react';
import { 
  ChevronRight, 
  Image as ImageIcon,
  Info,
  
  ChevronsUpDown,
  X
} from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  Dialog,
  DialogContent,
  DialogClose,
} from "@/components/ui/dialog";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { ScrollArea } from "@/components/ui/scroll-area";

interface DataSection<T> {
  title: string;
  items: T[];
}

interface GenericObject<T> {
  heading: string;
  subheading?: string;
  images?: string[];
  data?: {
    [key: string]: DataSection<T>;
  };
}

interface CombinedDisplayProps<T> {
  item: GenericObject<T>;
  images?: string[];
}

const CombinedDisplay = <T,>({ item, images }: CombinedDisplayProps<T>) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [openSection, setOpenSection] = useState<string | null>(null);

  const toggleSection = (key: string) => {
    setOpenSection(openSection === key ? null : key);
  };

  // console.log(images, 'hello');
  

  return (
    <div className="max-w-7xl mx-auto p-4">
      <Card className="bg-gradient-to-br from-white to-gray-50">
        <CardHeader>
          <div className="flex items-start justify-between flex-col md:flex-row md:items-center gap-4">
            <div>
              <CardTitle className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 to-orange-600">
                {item.heading}
              </CardTitle>
              {item.subheading && (
                <p className="text-gray-600 mt-2 text-lg">
                  {item.subheading}
                </p>
              )}
            </div>
            {images && (
              <Badge variant="secondary" className="flex items-center gap-2">
                <ImageIcon size={14} />
                {images.length} Images
              </Badge>
            )}
          </div>
        </CardHeader>

        <CardContent>
          <div className="grid lg:grid-cols-5 gap-6">
            {/* Data Sections */}
            <div className="lg:col-span-3 space-y-4">
              {item.data && Object.entries(item.data).map(([key, section]) => (
                <Collapsible
                  key={key}
                  open={openSection === key}
                  onOpenChange={() => toggleSection(key)}
                >
                  <Card>
                    <CollapsibleTrigger className="w-full">
                      <div className="flex items-center justify-between p-4 hover:bg-gray-50 transition-colors">
                        <div className="flex items-center gap-2">
                          <Info className="w-5 h-5 text-blue-500" />
                          <h3 className="font-semibold text-lg">
                            {section.title}
                          </h3>
                        </div>
                        <ChevronsUpDown className="w-4 h-4 text-gray-500" />
                      </div>
                    </CollapsibleTrigger>
                    <CollapsibleContent>
                      <Separator />
                      <ScrollArea className="h-[300px] p-4">
                        <div className="space-y-2">
                          {section.items.map((dataItem, index) => (
                            <div
                              key={index}
                              className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors"
                            >
                              <pre className="text-sm whitespace-pre-wrap">
                                {JSON.stringify(dataItem, null, 2)}
                              </pre>
                            </div>
                          ))}
                        </div>
                      </ScrollArea>
                    </CollapsibleContent>
                  </Card>
                </Collapsible>
              ))}
            </div>

            {/* Image Gallery */}
            {images && (
              <div className="lg:col-span-2">
                <div className="grid grid-cols-2 gap-4">
                  {images.map((imageUrl, index) => (
                    <div
                      key={index}
                      className="group relative aspect-square rounded-lg overflow-hidden cursor-pointer"
                      onClick={() => setSelectedImage(imageUrl)}
                    >
                      <img
                        src={imageUrl}
                        alt={`Gallery image ${index + 1}`}
                        className="object-cover w-full h-full transition-transform group-hover:scale-110"
                      />
                      <div className="absolute inset-0 bg-black/30 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                        <ChevronRight className="w-6 h-6 text-white" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </CardContent>
      </Card>

      {/* Image Preview Dialog */}
      <Dialog open={!!selectedImage} onOpenChange={() => setSelectedImage(null)}>
        <DialogContent className="max-w-[20%] ">
          <DialogClose className="absolute right-5 top-5">
            <X className="w-4 h-4" />
          </DialogClose>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Preview"
              className="w-full h-auto rounded-lg"
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CombinedDisplay;