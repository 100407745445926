import React, { useState } from "react";
import { ChevronDown } from "lucide-react";

interface FAQItem {
  question: string;
  answer: string;
}

interface FAQProps {
  faqItems: FAQItem[];
  learnMoreLink?: string;
}

const FAQ: React.FC<FAQProps> = ({ faqItems }) => {
  const [activeIndexes, setActiveIndexes] = useState<boolean[]>(
    new Array(faqItems.length).fill(false)
  );

  const toggleItem = (index: number) => {
    setActiveIndexes((prev) => {
      const newIndexes = [...prev];
      newIndexes[index] = !newIndexes[index];
      return newIndexes;
    });
  };

  return (
    <section className="bg-white py-12 px-4">
      <div className="container mx-auto max-w-6xl">
        <h2 className="text-3xl font-bold text-center text-black mb-4">
          Frequently Asked Questions
        </h2>
        <div className="w-64 h-1 bg-yellow-400 mx-auto mb-6"></div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-start auto-rows-min">
          {faqItems.map((item, index) => (
            <div 
              key={index} 
              className="bg-white rounded-lg shadow-sm self-start"
            >
              <div
                onClick={() => toggleItem(index)}
                role="button"
                tabIndex={0}
                className={`
                  w-full flex justify-between items-center 
                  p-4 cursor-pointer
                  transition-colors duration-300
                  ${activeIndexes[index] 
                    ? 'bg-yellow-50 text-yellow-600' 
                    : 'hover:bg-gray-50 text-gray-700'}
                `}
              >
                <span className="font-medium text-base">{item.question}</span>
                <ChevronDown 
                  className={`text-gray-500 w-4 h-4 transition-transform duration-300 ${
                    activeIndexes[index] ? 'rotate-180' : ''
                  }`} 
                />
              </div>

              <div 
                className={`
                  overflow-hidden transition-all duration-300
                  ${activeIndexes[index] ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}
                `}
              >
                <div className="px-4 pb-4 text-gray-600 text-sm">
                  {item.answer}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
