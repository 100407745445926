import { Helmet } from "react-helmet";
import BgDisplayItems from "../common/BgDisplayItem";

import FAQ from "../common/FAQ";

import CombinedDisplay from "../common/mergecontentDisplay";

const FullstackFoundationTrainingCOM = () => {
  const staticItem = {
    id: 1,
    title: "Full-Stack Foundation Training In Agra",

    backgroundImage: "Web_logo/Background/fullstackfoun.png",
  };

  const simpleItem = {
    heading: "Full-Stack Foundation Training Program!",
    subheading: "Duration: 18 Months Training + 6 Months Internship",
    data: {
      dataHeading1: {
        title: "Basic Fundamentals",
        items: ["Introduction", "Local environment setup"],
      },
      dataHeading2: {
        title: "Front-End Development",
        items: [
          "HTML / HTML5",
          "CSS / CSS3",
          " JavaScript",
          "Bootstrap /Bootstrap5",
          "JavaScript framework",
          "Global CSS",
          "React JS",
          "React Hooks",
          "Redux",
          "API Integration",
          "Animated UI",
        ],
      },
      dataHeading3: {
        title: "Logical Thinking (DSA)",
        items: ["Array", "String", "Link-List"],
      },
      dataHeading4: {
        title: "Back-End Development",
        items: [
          "Node JS",
          "Express JS",
          "OOPS Concept",
          "Restful API",
          "CRUD Operation",
          "Session Maintaince",
          "Secure Backend (API)",
          "Authentication (JWT)",
          "Cookies maintaince",
          "Secured user data",
        ],
      },
      dataHeading5: {
        title: "Database",
        items: ["NoSQL (MongoDB)", "SQL (MySQL)"],
      },
      dataHeading6: {
        title: "Dev’s Tools",
        items: ["Docker", "GitHub (Git)", "Kubernetes", "AWS", "GCP & Azure"],
      },
      dataHeading7: {
        title: "Project Practice",
        items: ["Industry Level", "Company Projects"],
      },
      dataHeading8: {
        title: "Code Optimization",
        items: ["Minimum line of code", "Time complexity", "Space complexity"],
      },
      dataHeading9: {
        title: "Internship",
        items: ["6 Months paid internship", "Stipend of upto INR 15000"],
      },
    },
  };

  const images = [
    "assets/full-stack-webp/11zon_compressed/freepik__expand__68433_1_11zon.webp",
    "assets/full-stack-webp/pexels-peaky-29445974_9_11zon.webp",
    "assets/full-stack-webp/pexels-fotios-photos-16129703_3_11zon.webp",
    "assets/full-stack-webp/pexels-goumbik-574073_4_11zon.webp",
  ];

  const FAQ_ITEMS = [
    {
      question:
        "Is EMI available in the Full-Stack Foundation Training Program?",
      answer: `Yes, EMI option is available in the Full-Stack Foundation Training Program so that the students do not feel the need to submit the program fees in one go.`,
    },
    {
      question:
        "Does the Full-Stack Foundation Program include training on soft?",
      answer: `Not only in the Full-Stack Foundation Program , all our programs include training on soft skills such as Personality Development, Language Fluency, Public Speaking & Leadership through the Skillyards Club Facilitation.`,
    },
    {
      question: "What benefit will I get from this training program?",
      answer: `Not only practical training on client projects, but you will also get a paid internship with a stipend of up to 15000 per month and a 100% Guaranteed Job with a package of up to 10 LPA in our own company.`,
    },
  ];

  return (
    <div>
      <Helmet>
  {/* Character Encoding */}
  <meta charSet="utf-8" />

  {/* Page Title */}
  <title>
    Best Full-Stack Development Training in Agra | Master MERN Stack | Skillyards
  </title>

  {/* Browser Compatibility */}
  <meta httpEquiv="x-ua-compatible" content="ie=edge" />

  {/* Responsive Viewport */}
  <meta name="viewport" content="width=device-width, initial-scale=1" />

  {/* Meta Description */}
  <meta
    name="description"
    content="Join the Best Full-Stack Development Training in Agra at Skillyards. Master the MERN Stack (MongoDB, Express.js, React, Node.js) and build dynamic web applications with hands-on projects. Enroll now to kickstart your career as a full-stack developer."
  />

  {/* Keywords */}
  <meta
    name="keywords"
    content="full stack development training in Agra, MERN stack course Agra, full stack developer training, web development course Agra, MongoDB training, Express.js course, React.js training, Node.js certification, full stack web development, Skillyards Agra, full stack developer certification, web development bootcamp, full stack programming course"
  />

  {/* Author */}
  <meta name="author" content="Skillyards" />

  {/* Canonical URL */}
  <link
    rel="canonical"
    href="https://skillyards.com/full-stack-development-training-in-agra"
  />

  {/* Open Graph (OG) Meta Tags */}
  <meta property="og:type" content="website" />
  <meta
    property="og:title"
    content="Best Full-Stack Development Training in Agra | Master MERN Stack | Skillyards"
  />
  <meta
    property="og:description"
    content="Enroll in Skillyards' Full-Stack Development Training in Agra. Master the MERN Stack (MongoDB, Express.js, React, Node.js) and build dynamic web applications with hands-on projects. Start your career as a full-stack developer today!"
  />
  <meta
    property="og:url"
    content="https://skillyards.com/full-stack-development-training-in-agra"
  />
  <meta
    property="og:image"
    content="https://skillyards.com/assets/images/full-stack-course-banner.jpg"
  />
  <meta property="og:site_name" content="Skillyards" />

  {/* Twitter Meta Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta
    name="twitter:title"
    content="Best Full-Stack Development Training in Agra | Skillyards | Master MERN Stack"
  />
  <meta
    name="twitter:description"
    content="Join Skillyards' expert-led Full-Stack Development Training in Agra. Master the MERN Stack (MongoDB, Express.js, React, Node.js) and build dynamic web applications. Enroll now!"
  />
  <meta
    name="twitter:image"
    content="https://skillyards.com/assets/images/full-stack-course-banner.jpg"
  />
  <meta name="twitter:site" content="@Skillyards" />
  <meta name="twitter:creator" content="@Skillyards" />

  {/* Favicon */}
  <link
    rel="apple-touch-icon"
    sizes="180x180"
    href="Web_logo/favicon/apple-touch-icon.png"
  />
  <link
    rel="icon"
    type="image/png"
    sizes="32x32"
    href="Web_logo/favicon/favicon-32x32.png"
  />
  <link
    rel="icon"
    type="image/png"
    sizes="16x16"
    href="Web_logo/favicon/favicon-16x16.png"
  />
  <link
    rel="mask-icon"
    href="Web_logo/favicon/safari-pinned-tab.svg"
    color="#5bbad5"
  />
</Helmet>
      <BgDisplayItems item={staticItem} />
      <div>
        {/* <DisplayInfo item={simpleItem} />
                <ImageDisplays images={images} /> */}

        <CombinedDisplay item={simpleItem} images={images} />
      </div>
      <FAQ faqItems={FAQ_ITEMS} />
    </div>
  );
};

export default FullstackFoundationTrainingCOM;
