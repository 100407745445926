import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AutoDownload = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (isDownloading) return; // Prevent multiple downloads

    // Extract the file name from the pathname
    const pathSegments = location.pathname.split('/');
    const fileName = pathSegments[pathSegments.length - 1].replace('-pdf', '.pdf');

    // Construct the file URL
    const fileUrl = `/syllabus-pdf/${fileName}`;

    console.log("File URL:", fileUrl);
    console.log("File Name:", fileName);

    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);

    setIsDownloading(true); // Set downloading state to true
    link.click();

    // Simulate download completion (you might want to use a more robust method)
    setTimeout(() => {
      document.body.removeChild(link);
      setIsDownloading(false); // Reset downloading state
      navigate('/'); // Redirect to home page
    }, 2000); // Adjust timeout as needed

  }, [location.pathname, isDownloading, navigate]);

  return (
    <div>
      {isDownloading ? <p>Downloading...</p> : <p>Your file is downloading...</p>}
    </div>
  );
};

export default AutoDownload;
