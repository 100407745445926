
export interface FormData {
    name: string;
    email: string;
    mobile: string;
    city: string;
    message: string;
    role: string;
    paymentStatus?: boolean;
}

export function GetFromLocal(): FormData | null  {
    // Retrieve the JSON string from localStorage
    const jsonData = localStorage.getItem('formData');
    
    // Check if data exists
    if (!jsonData) {
       console.warn('No form data found in localStorage');
       return null
        
    }

    try {
        // Parse the JSON string back to an object
        const formData: FormData = JSON.parse(jsonData);
        return formData;
    } catch (error) {
        console.error('Error parsing form data:', error);
        return null;
    }
}
