import { useLocation } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";

// Interfaces
interface Option {
    text: string;
    isCorrect: boolean;
    _id: string;
}

interface Question {
    question: string;
    options: Option[];
    _id: string;
}

interface McqTest {
    _id: string;
    title: string;
    questions: Question[];
    createdAt: string;
    updatedAt: string;
    __v: number;
}

const TestResults = () => {
    const location = useLocation();
    const { test, selectedOptions } = location.state as { test: McqTest; selectedOptions: Record<string, string> };

    if (!test || !selectedOptions) {
        return (
            <div className="flex flex-col justify-center items-center h-screen">
                <div className="text-red-500 text-xl font-semibold">No test data available</div>
                <p className="text-gray-500 mt-2">Please go back and retry the test</p>
            </div>
        );
    }

    // Calculate results
    const totalQuestions = test.questions.length;
    let correctCount = 0;
    let incorrectCount = 0;

    test.questions.forEach((question) => {
        const selectedOptionId = selectedOptions[question._id];
        const correctOption = question.options.find((opt) => opt.isCorrect);
        if (selectedOptionId && correctOption && selectedOptionId === correctOption._id) {
            correctCount++;
        } else if (selectedOptionId) {
            incorrectCount++;
        }
    });

    const unansweredCount = totalQuestions - (correctCount + incorrectCount);
    const percentage = totalQuestions > 0 ? (correctCount / totalQuestions) * 100 : 0;
    
    // Determine score status for visual feedback
    const getScoreStatus = () => {
        if (percentage >= 80) return { color: "text-green-600", message: "Excellent!" };
        if (percentage >= 60) return { color: "text-blue-600", message: "Good job!" };
        if (percentage >= 40) return { color: "text-yellow-600", message: "Keep practicing!" };
        return { color: "text-red-600", message: "Needs improvement" };
    };
    
    const scoreStatus = getScoreStatus();
    
    // Get color for progress bar
    const getProgressColor = () => {
        if (percentage >= 80) return "#10b981"; // green
        if (percentage >= 60) return "#3b82f6"; // blue
        if (percentage >= 40) return "#f59e0b"; // yellow
        return "#ef4444"; // red
    };

    // Determine scholarship eligibility
    const getScholarshipInfo = () => {
        if (percentage >= 75) return { percent: 35, color: "text-green-600", bgColor: "bg-green-100" };
        if (percentage >= 50) return { percent: 25, color: "text-blue-600", bgColor: "bg-blue-100" };
        if (percentage >= 33) return { percent: 15, color: "text-yellow-600", bgColor: "bg-yellow-100" };
        return { percent: 0, color: "text-gray-600", bgColor: "bg-gray-100" };
    };
    
    const scholarshipInfo = getScholarshipInfo();

    return (
        <div className="container mx-auto px-4 py-6 md:py-8 max-w-4xl">
            <Card className="shadow-xl rounded-lg overflow-hidden border-0">
                <CardHeader className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white p-6">
                    <CardTitle className="text-2xl md:text-3xl font-bold tracking-tight">{test.title} - Results</CardTitle>
                </CardHeader>
                <CardContent className="p-0">
                    {/* Score Summary Section */}
                    <div className="bg-white p-6 border-b">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 items-center">
                            {/* Circular Progress */}
                            <div className="flex justify-center">
                                <div className="w-40 h-40 flex flex-col items-center justify-center border-4 rounded-full" style={{ borderColor: getProgressColor() }}>
                                    <span className="text-3xl font-bold" style={{ color: getProgressColor() }}>{percentage.toFixed(0)}%</span>
                                    <span className="text-sm text-gray-500">Score</span>
                                </div>
                            </div>
                            
                            {/* Stats */}
                            <div className="md:col-span-2 bg-gray-50 p-4 rounded-lg">
                                <h2 className="text-xl font-semibold mb-3 text-gray-800 flex items-center">
                                    <span>Performance Summary</span>
                                    <span className={`ml-2 text-sm font-medium ${scoreStatus.color}`}>
                                        ({scoreStatus.message})
                                    </span>
                                </h2>
                                <div className="grid grid-cols-2 gap-3">
                                    <div className="flex flex-col">
                                        <span className="text-sm text-gray-500">Total Questions</span>
                                        <span className="text-xl font-bold">{totalQuestions}</span>
                                    </div>
                                    <div className="flex flex-col">
                                        <span className="text-sm text-gray-500">Correct Answers</span>
                                        <span className="text-xl font-bold text-green-600">{correctCount}</span>
                                    </div>
                                    <div className="flex flex-col">
                                        <span className="text-sm text-gray-500">Incorrect Answers</span>
                                        <span className="text-xl font-bold text-red-600">{incorrectCount}</span>
                                    </div>
                                    <div className="flex flex-col">
                                        <span className="text-sm text-gray-500">Unanswered</span>
                                        <span className="text-xl font-bold text-gray-600">{unansweredCount}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Scholarship Information */}
                    <div className={`p-6 ${scholarshipInfo.bgColor} border-b`}>
                        <h2 className="text-xl font-semibold mb-3 text-gray-800">Scholarship Eligibility</h2>
                        {scholarshipInfo.percent > 0 ? (
                            <div className="flex flex-col md:flex-row md:items-center gap-4">
                                <div className={`text-3xl font-bold ${scholarshipInfo.color}`}>{scholarshipInfo.percent}%</div>
                                <div className="flex-1">
                                    <p className="text-gray-700">
                                        Congratulations! Based on your score of <span className="font-semibold">{percentage.toFixed(1)}%</span>, 
                                        you are eligible for a <span className={`font-semibold ${scholarshipInfo.color}`}>{scholarshipInfo.percent}% scholarship</span>.
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <p className="text-gray-700">
                                Based on your current score of <span className="font-semibold">{percentage.toFixed(1)}%</span>, 
                                you are not eligible for a scholarship at this time. A minimum score of 33% is required for scholarship eligibility.
                            </p>
                        )}
                         {/* <div className="mt-3 bg-white p-3 rounded-lg shadow-sm">
                            <h3 className="font-medium text-gray-800 mb-2">Scholarship Criteria:</h3>
                            <ul className="space-y-1 text-sm">
                                <li className="flex items-center">
                                    <div className={`w-3 h-3 rounded-full mr-2 ${percentage >= 33 && percentage < 50 ? 'bg-yellow-500' : 'bg-gray-300'}`}></div>
                                    <span>33-50% score: <span className="font-medium">15% scholarship</span></span>
                                </li>
                                <li className="flex items-center">
                                    <div className={`w-3 h-3 rounded-full mr-2 ${percentage >= 50 && percentage < 75 ? 'bg-blue-500' : 'bg-gray-300'}`}></div>
                                    <span>50-75% score: <span className="font-medium">25% scholarship</span></span>
                                </li>
                                <li className="flex items-center">
                                    <div className={`w-3 h-3 rounded-full mr-2 ${percentage >= 75 ? 'bg-green-500' : 'bg-gray-300'}`}></div>
                                    <span>75-100% score: <span className="font-medium">35% scholarship</span></span>
                                </li>
                            </ul>
                        </div> */}
                    </div> 

                    {/* Question Review Header */}
                    <div className="bg-gray-100 p-4 border-b">
                        <h2 className="text-lg font-semibold text-gray-800">Question Review</h2>
                    </div>

                    {/* Detailed Results */}
                    <div className="divide-y">
                        {test.questions.map((question, qIndex) => {
                            const selectedOptionId = selectedOptions[question._id];
                            const correctOption = question.options.find((opt) => opt.isCorrect);
                            const isAnsweredCorrectly = selectedOptionId && correctOption && selectedOptionId === correctOption._id;
                            
                            return (
                                <div key={question._id} className="p-4 md:p-6">
                                    <div className="flex items-start gap-3 mb-3">
                                        <div className={`flex items-center justify-center w-8 h-8 rounded-full text-white font-bold ${isAnsweredCorrectly ? 'bg-green-500' : selectedOptionId ? 'bg-red-500' : 'bg-gray-400'}`}>
                                            {qIndex + 1}
                                        </div>
                                        <h3 className="text-lg font-medium text-gray-800">
                                            {question.question}
                                        </h3>
                                    </div>
                                    
                                    <div className="ml-11 space-y-2">
                                        {question.options.map((option) => {
                                            const isSelected = selectedOptionId === option._id;
                                            const isCorrect = option.isCorrect;
                                            
                                            let optionClass = "border rounded-md p-3 transition-colors";
                                            
                                            if (isSelected && isCorrect) {
                                                optionClass += " border-green-500 bg-green-50";
                                            } else if (isSelected && !isCorrect) {
                                                optionClass += " border-red-500 bg-red-50";
                                            } else if (isCorrect) {
                                                optionClass += " border-green-500 bg-green-50";
                                            } else {
                                                optionClass += " border-gray-200";
                                            }

                                            return (
                                                <div key={option._id} className={optionClass}>
                                                    <div className="flex items-center gap-3">
                                                        <Checkbox
                                                            id={`${question._id}-${option._id}`}
                                                            checked={isSelected}
                                                            disabled={true}
                                                            className={`h-5 w-5 ${isSelected && isCorrect ? 'text-green-500' : isSelected ? 'text-red-500' : ''}`}
                                                        />
                                                        <Label
                                                            htmlFor={`${question._id}-${option._id}`}
                                                            className="text-base text-gray-700 cursor-default"
                                                        >
                                                            {option.text}
                                                        </Label>
                                                        
                                                        {/* Status indicator */}
                                                        {isSelected && isCorrect && (
                                                            <span className="ml-auto text-green-600 text-sm font-medium">Correct</span>
                                                        )}
                                                        {isSelected && !isCorrect && (
                                                            <span className="ml-auto text-red-600 text-sm font-medium">Incorrect</span>
                                                        )}
                                                        {!isSelected && isCorrect && (
                                                            <span className="ml-auto text-blue-600 text-sm font-medium">Correct Answer</span>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default TestResults;