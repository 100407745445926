import { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { 
  BookOpen, 
  Clock, 
  BrainCircuit,
  ArrowRight, 
  FileText, 
  Calendar,
  RefreshCw,
  Search,
  CheckCircle,
  BarChart,
  Brain,
  Lightbulb
} from "lucide-react";
import { motion } from "framer-motion";

// Define TypeScript interfaces based on your Mongoose schema
 const api=`${import.meta.env.VITE_APP_API_URL}`
interface McqTest {
  _id: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

const AptitudeTest = () => {
  const [tests, setTests] = useState<McqTest[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState("");

  async function getApiData() {
    try {
      setLoading(true);
      const response = await fetch(`${api}/get-all-mcq`);
      if (!response.ok) {
        throw new Error('Failed to fetch MCQ tests');
      }
      const data: McqTest[] = await response.json();
      setTests(data);
      console.log('API Response:', data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getApiData();
  }, []);

  // Format date to a readable format
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    });
  };

  // Filter tests based on search query
  const filteredTests = tests.filter(test => 
    test.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-gradient-to-br from-blue-50 to-indigo-100">
        <div className="w-16 h-16 border-4 border-yellow-500 border-t-transparent rounded-full animate-spin mb-4"></div>
        <p className="text-xl font-medium text-black">Loading aptitude tests...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-red-50">
        <div className="p-6 bg-white rounded-xl shadow-lg max-w-md">
          <h2 className="text-2xl font-bold text-red-600 mb-2">Error</h2>
          <p className="text-gray-700">{error}</p>
          <Button 
            onClick={getApiData} 
            className="mt-4 bg-red-500 hover:bg-red-600 flex items-center gap-2"
          >
            <RefreshCw size={16} />
            Try Again
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8 gap-4">
          <div>
            <motion.h1 
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-yellow-500"
            >
              Aptitude Assessment Center
            </motion.h1>
            <p className="text-gray-600 mt-2 flex items-center gap-2">
              <BrainCircuit size={18} className="text-yellow-400" />
              <span>Select a test to begin your assessment</span>
            </p>
          </div>
          
          <div className="relative w-full md:w-auto">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
            <input
              type="text"
              placeholder="Search tests..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-10 pr-4 py-2 w-full md:w-64 rounded-lg border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
          </div>
        </div>
        
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Card className="mb-6 shadow-lg border-none overflow-hidden">
            <CardHeader className="bg-gradient-to-r from-yellow-400 to-yellow-500 text-white p-6">
              <div className="flex items-center gap-3">
                <Lightbulb size={24} className="text-white" />
                <CardTitle className="text-2xl font-bold">Available Tests</CardTitle>
              </div>
              <CardDescription className="text-black mt-1">
                Choose from our selection of aptitude assessments
              </CardDescription>
            </CardHeader>
            <CardContent className="p-0">
              {filteredTests.length > 0 ? (
                <div className="divide-y divide-gray-200">
                  {filteredTests.map((test, index) => (
                    <motion.div 
                      key={test._id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: index * 0.1 }}
                      whileHover={{ backgroundColor: "#f9fafb" }}
                      className="p-6 transition-all"
                    >
                      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                        <div>
                          <div className="flex items-center gap-2">
                            <Brain size={20} className="text-yellow-500" />
                            <h3 className="text-xl font-semibold text-gray-800">{test.title}</h3>
                          </div>
                          <div className="flex items-center mt-2 text-gray-600 text-sm gap-4">
                            <div className="flex items-center gap-1">
                              <Calendar size={14} />
                              <span>Created: {formatDate(test.createdAt)}</span>
                            </div>
                            <div className="flex items-center gap-1">
                              <Clock size={14} />
                              <span>Updated: {formatDate(test.updatedAt)}</span>
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-3">
                          <Badge variant="outline" className="bg-blue-50 text-yellow-400 border-blue-200">
                            Aptitude
                          </Badge>
                          <Link to={`/aptitude-test/${test._id}`}>
                            <Button className="bg-gradient-to-r from-yellow-400 to-yellow-500 hover:from-yellow-400 hover:to-yellow-500 text-white shadow-md hover:shadow-lg transition-all duration-200 flex items-center gap-2">
                              Take Test
                              <ArrowRight size={16} />
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              ) : (
                <div className="p-12 flex flex-col items-center justify-center text-center">
                  <FileText size={48} className="text-gray-300 mb-4" />
                  <h3 className="text-xl font-medium text-gray-700 mb-2">No Tests Found</h3>
                  <p className="text-gray-500 max-w-md">
                    {searchQuery ? 
                      `No tests matching "${searchQuery}" were found. Try a different search term.` : 
                      "There are currently no aptitude tests available. Please check back later."}
                  </p>
                  {searchQuery && (
                    <Button 
                      variant="outline" 
                      className="mt-4"
                      onClick={() => setSearchQuery("")}
                    >
                      Clear Search
                    </Button>
                  )}
                </div>
              )}
            </CardContent>
          </Card>
          
          <div className="bg-white rounded-xl shadow-md p-6 mb-6">
            <div className="flex items-center gap-2 mb-4">
              <CheckCircle size={20} className="text-green-500" />
              <h3 className="text-lg font-semibold text-gray-800">Benefits of Our Aptitude Tests</h3>
            </div>
            <Separator className="mb-4" />
            <div className="grid md:grid-cols-3 gap-4 text-gray-600">
              <div className="flex items-start gap-2">
                <BookOpen size={18} className="text-blue-500 mt-0.5" />
                <p>Comprehensive assessment of your cognitive abilities</p>
              </div>
              <div className="flex items-start gap-2">
                <BarChart size={18} className="text-blue-500 mt-0.5" />
                <p>Detailed analytics and performance insights</p>
              </div>
              <div className="flex items-start gap-2">
                <BrainCircuit size={18} className="text-blue-500 mt-0.5" />
                <p>Scientifically designed to measure your potential</p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AptitudeTest;