import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Search, BookOpen, X } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { Helmet } from "react-helmet";

interface Topic {
  _id: string;
  subject: string;
  description: string;
  usedInIndustries: string;
}

const StudyMaterial: React.FC = () => {
  //const api = `${import.meta.env.VITE_APP_API_URL}/admin`;
  const [topics, setTopics] = useState<Topic[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<Topic[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const getCourses = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await fetch(`${import.meta.env.VITE_APP_API_URL}/admin/getAllCourses`);
      const data = await response.json();
      console.log(data,"check");
      
      if (data.error === false) {
        setTopics(data.courseSections);
      }
    } catch (error) {
      console.log("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.trim()) {
      setIsSearching(true);
      const results = topics.filter(
        (topic) =>
          topic.subject.toLowerCase().includes(query.toLowerCase()) ||
          topic.description.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setIsSearching(false);
      setSearchResults([]);
    }
  };

  const clearSearch = (): void => {
    setSearchQuery("");
    setIsSearching(false);
    setSearchResults([]);
  };

  const renderTopicCard = (topic: Topic, index: number): JSX.Element => (
    <Card
      key={topic._id}
      className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300 cursor-pointer"
      onClick={() => navigate(`/study/${topic?.subject}`)}
    >
      <CardHeader key={index}>
        <CardTitle className="text-xl font-bold text-gray-900">{topic.subject}</CardTitle>
        <CardDescription className="text-gray-600">{topic.description}</CardDescription>
      </CardHeader>
      <CardContent>
        <p className="text-sm text-gray-500">{topic.usedInIndustries}</p>
      </CardContent>
    </Card>
  );

  const renderSearchResults = (): JSX.Element => (
    <div className="mt-8">
      {searchResults.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {searchResults.map((result, index) => renderTopicCard(result, index))}
        </div>
      ) : (
        <div className="text-center py-12">
          <BookOpen className="mx-auto text-gray-400 mb-4 h-16 w-16" />
          <h3 className="text-xl font-semibold text-gray-700 mb-2">No Results Found</h3>
          <p className="text-gray-500">Try adjusting your search terms or browse all materials</p>
        </div>
      )}
    </div>
  );

  return (<>    <Helmet>
  <title>Study Resources | Learn Digital Marketing, JavaScript & More</title>

  <meta http-equiv="x-ua-compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <meta name="description" 
    content="Explore expert-curated study resources on Digital Marketing, JavaScript, Python, and more at Skillyards. Enhance your skills with in-depth guides for all levels." />
  <meta name="keywords" 
    content="study resources, Digital Marketing, JavaScript tutorials, Python learning, coding guides, IT training, Skillyards, programming courses, web development, coding skills" />
  <meta name="author" content="Skillyards" />

  <link rel="canonical" href="https://skillyards.com/study" />
</Helmet>
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-12">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">Study Material</h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Explore our comprehensive collection of learning resources
          </p>
        </div>

        {/* Search Section */}
        <div className="max-w-2xl mx-auto mb-12">
          <div className="relative">
            <Input
              type="text"
              placeholder="Search by topic or keyword..."
              value={searchQuery}
              onChange={handleSearch}
              className="w-full px-6 py-4 text-lg rounded-full shadow-sm focus:ring-2 focus:ring-blue-500"
            />
            {searchQuery && (
              <Button
                variant="ghost"
                size="icon"
                onClick={clearSearch}
                className="absolute right-16 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
              >
                <X className="h-5 w-5" />
              </Button>
            )}
            <Button
              variant="ghost"
              size="icon"
              onClick={() => handleSearch({ target: { value: searchQuery } } as React.ChangeEvent<HTMLInputElement>)}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-600"
            >
              <Search className="h-6 w-6" />
            </Button>
          </div>
        </div>

        {/* Content Section */}
        {loading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {Array.from({ length: 8 }).map((_, index) => (
              <Skeleton key={index} className="h-48 w-full rounded-xl" />
            ))}
          </div>
        ) : isSearching || searchQuery.length ? (
          renderSearchResults()
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {topics.map((topic, index) => renderTopicCard(topic, index))}
          </div>
        )}
      </div>
      <Outlet />
    </div>
    </>
);
};

export default StudyMaterial;