import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Outlet } from "react-router-dom";
import { Calendar, GraduationCap, Timer, Book } from "lucide-react";
import { Card, CardTitle, CardDescription, CardContent, CardFooter } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
interface Content {
  _id: string;
  title: string;
  description: string;
  level: string;
  duration: string;
  authorname: string;
  createdAt: string;
  file: {
    fileUrl: string;
  };
}

const SingleCategoryContent: React.FC = () => {
  const api = `${import.meta.env.VITE_APP_API_URL}/admin`;
  const { subject } = useParams<{ subject: string }>();
  const [specificContent, setSpecificContent] = useState<Content[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const getAllSpecificContent = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await fetch(`${api}/courses/${subject}`);
      const data = await response.json();
      setSpecificContent(data?.contents || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleContentClick = (titel: string): void => {
    navigate(`${titel}`);
  };

  useEffect(() => {
    getAllSpecificContent();
  }, [subject]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="mb-8 text-center">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">
            <Book className="inline-block mr-2 text-blue-600" size={32} />
            {`${subject} Courses`}
          </h1>
          <p className="text-gray-600">Explore comprehensive lessons and materials in {subject}</p>
        </div>

        {/* Course Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {specificContent.map((content) => (
            <Card
              key={content._id}
              className="hover:shadow-lg transition-shadow duration-300 cursor-pointer"
              onClick={() => handleContentClick(`content/${content.title}`)}
            >
              <div className="flex flex-col md:flex-row h-full">
                {/* Image Section */}
                <div className="md:w-2/5 h-48 md:h-auto relative overflow-hidden">
                  <img
                    className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                    src={content.file.fileUrl}
                    alt={content.title}
                  />
                  <div className="absolute top-0 right-0 bg-blue-600 text-white px-3 py-1 text-sm font-medium rounded-bl-lg">
                    {content.level}
                  </div>
                </div>

                {/* Content Section */}
                <CardContent className="md:w-3/5 p-6 flex flex-col justify-between">
                  {/* Upper Content */}
                  <div>
                    <div className="flex items-center justify-between text-sm text-gray-600 mb-3">
                      <div className="flex items-center">
                        <Calendar size={16} className="mr-1" />
                        {new Date(content.createdAt).toLocaleDateString("en-us", {
                          day: "numeric",
                          month: "long",
                        })}
                      </div>
                      <span className="flex items-center">
                        <GraduationCap size={16} className="mr-1" />
                        {content.authorname}
                      </span>
                    </div>

                    <CardTitle className="text-xl font-semibold text-gray-900 mb-3 line-clamp-2 hover:text-blue-600">
                      {content.title}
                    </CardTitle>

                    <CardDescription
                      className="text-gray-600 text-sm line-clamp-2 mb-4"
                      dangerouslySetInnerHTML={{
                        __html:
                          content.description.slice(0, 250) +
                          (content.description.length > 150 ? "..." : ""),
                      }}
                    />
                  </div>

                  {/* Footer */}
                  <CardFooter className="flex items-center justify-between mt-auto p-0">
                    <div className="flex items-center text-blue-600">
                      <Timer size={16} className="mr-1" />
                      <span className="text-sm font-medium">{content.duration}</span>
                    </div>
                    <Button variant="ghost" className="text-blue-600 hover:text-blue-800">
                      Learn More
                      <Book size={16} className="ml-1" />
                    </Button>
                  </CardFooter>
                </CardContent>
              </div>
            </Card>
          ))}
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default SingleCategoryContent;