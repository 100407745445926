
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../loader";
import BlogForm from "../blogContentForm/blog-form";
import BlogContentForm from "../blogContentForm/blog-content-form";
import { updateBlog } from "../update-blog";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import GlitchLoader from "../glitch-loader";
import { toast } from "sonner";
import { BlogData, ContentItem } from "../../types";
import type { EditorConfig } from '@ckeditor/ckeditor5-core';

const UpdateBlog: React.FC = () => {
  const editorConfig: EditorConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'undo',
        'redo'
      ]
    },
    link: {
      addTargetToExternalLinks: true,
      decorators: {
        openInNewTab: {
          mode: 'manual' as const, // Explicitly specify the type as 'manual'
          label: 'Open in a new tab',
          defaultValue: true,
          attributes: {
            target: '_blank',
            rel: 'noopener noreferrer'
          }
        }
      }
    }
  };
  

  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [blogData, setBlogData] = useState<BlogData>({ ...initialBlogData });
  const [contentData, setContentData] = useState<ContentItem[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [formData, setFormData] = useState<ContentItem>({
    _id: "",
    heading: "",
    tablename: "",
    discription: "",
    discription2: "",
    images: null,
  });
  const [editedId, setEditedId] = useState<string | null>(null);

  const token = JSON.parse(localStorage.getItem("token_tr") || "null");

  useEffect(() => {
    if (state) {
      // console.log("State received:", state);
      setBlogData(mapStateToBlogData(state));
      setContentData(state.content);
    }
  }, [state]);

  useEffect(() => {
    // console.log("Updated contentData:", contentData);
  }, [contentData]);

  const handleDeleteContent = (id: string) => {
    setContentData((prev) => prev.filter((item) => item._id !== id));
    toast.success("Content deleted successfully!");
  };

  const handleSaveBlog = async () => {
    setIsLoading(true);
    console.log("Blog data before upload:", blogData);
    console.log("Content data before upload:", contentData);
    toast.loading("Saving blog...");
    try {
      await updateBlog(blogData, token, contentData);
      toast.success("Blog saved successfully!");
      navigate(-1); // Navigate back to the previous page
    } catch (error) {
      toast.error(`Failed to save blog. Please try again. ${error}`);
    } finally {
      setIsLoading(false);
      toast.dismiss(); // Dismiss loading toast
    }
  };

  const handleEditContent = (id: string) => {
    const itemToEdit = contentData.find((item) => item._id === id);
    if (itemToEdit) {
      setFormData(itemToEdit);
      setEditedId(id);
      setShow(true);
    }
  };

  const handleChange = (field: keyof ContentItem, value: string | File | null) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = () => {
    //console.log("Form data before submit:", formData);

    // Update the contentData state with the new form data
    setContentData((prevData) =>
      prevData.map((item) =>
        item._id === editedId ? { ...item, ...formData } : item
      )
    );

    // Reset the form and close the dialog
    setShow(false);
    setEditedId(null);
    setFormData({
      _id: "",
      heading: "",
      tablename: "",
      discription: "",
      discription2: "",
      images: null,
    });

    toast.success("Content updated successfully!");
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="update-blog">
        <BlogForm
          blogData={blogData}
          onChange={(field: keyof BlogData, value: string | File | null) =>
            setBlogData((prev) => ({ ...prev, [field]: value }))
          }
        />
        <BlogContentForm
          blogData={blogData}
          setBlogData={setBlogData}
          token={token}
          contentData={contentData}
          setContentData={setContentData}
        />
        <div className="bg-gray-50 p-2 rounded-lg shadow-md w-full mx-auto">
          <h2 className="text-2xl font-semibold text-gray-700 text-center mb-6">
            Content List
          </h2>
          <div className="overflow-x-auto">
            <table className="min-w-full border border-gray-200 bg-white rounded-lg">
              <thead className="bg-blue-600 text-white">
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-medium">Table Name</th>
                  <th className="px-6 py-3 text-left text-sm font-medium">Heading</th>
                  <th className="px-6 py-3 text-left text-sm font-medium">Description</th>
                  <th className="px-6 py-3 text-left text-sm font-medium">Actions</th>
                </tr>
              </thead>
              <tbody>
                {contentData.map((item) => (
                  <tr key={item._id} className="border-b">
                    <td className="px-6 py-4 text-sm text-gray-700">{item.tablename}</td>
                    <td className="px-6 py-4 text-sm text-gray-700">{item.heading}</td>
                    <td className="px-6 py-4 text-sm text-gray-700">
                      <p>{item.discription}</p>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex space-x-4">
                        <button
                          className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
                          onClick={() => handleEditContent(item._id)}
                        >
                          Edit
                        </button>
                        <button
                          className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
                          onClick={() => handleDeleteContent(item._id)}
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <ActionButtons
          onReset={() => setBlogData(mapStateToBlogData(state || {}))}
          onSave={handleSaveBlog}
          loading={isLoading}
        />
      </div>

      {/* Dialog for Editing Content */}
      {show && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white w-[80vw] max-w-[1200px] h-[90vh] overflow-y-auto rounded-lg shadow-lg p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-semibold">Input Values</h2>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={() => setShow(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <form className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Heading</label>
                <input
                  type="text"
                  value={formData.heading}
                  onChange={(e) => handleChange("heading", e.target.value)}
                  placeholder="Enter heading"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Table Name</label>
                <input
                  type="text"
                  value={formData.tablename}
                  onChange={(e) => handleChange("tablename", e.target.value)}
                  placeholder="Enter table name"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <div className="ckeditor-container relative z-50">
                  <CKEditor
                    editor={ClassicEditor}
                    data={formData.discription || ""}
                    config={editorConfig}
                    onChange={(_, editor) => handleChange("discription", editor.getData())}
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Out of Container Description</label>
                <div className="ckeditor-container relative z-50">
                  <CKEditor
                    editor={ClassicEditor}
                    data={formData.discription2 || ""}
                    config={editorConfig}
                    onChange={(_, editor) => handleChange("discription2", editor.getData())}
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Image</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleChange("images", e.target.files?.[0] || null)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
            </form>
            <div className="flex justify-end space-x-4 mt-4">
              <button
                className="px-4 py-2 text-gray-700 border border-gray-300 rounded hover:bg-gray-100"
                onClick={() => setShow(false)}
              >
                Close
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateBlog;

const initialBlogData: BlogData = {
  id: "",
  title: "",
  krywords: "",
  discription: "",
  images: null, // Allow null for images
  imagediscription: "",
  tableImage: null, // Allow null for images
  status: "Approved",
  content: [],
};

interface StateType {
  _id?: string;
  title?: string;
  krywords?: string;
  discription?: string;
  images?: string | File | null; // Allow null for images
  imagediscription?: string;
  tableImage?: string | File | null; // Allow null for images
  status?: string;
  content?: ContentItem[];
}

const mapStateToBlogData = (state: StateType): BlogData => ({
  id: state?._id || "",
  title: state?.title || "",
  krywords: state?.krywords || "",
  discription: state?.discription || "",
  images: state?.images || null, // Allow null for images
  imagediscription: state?.imagediscription || "",
  tableImage: state?.tableImage || null, // Allow null for images
  status: "Approved",
  content: state?.content || [],
});

interface ActionButtonsProps {
  onReset: () => void;
  onSave: () => void;
  loading: boolean;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ onReset, onSave, loading }) => (
  <div className="flex space-x-4 mt-4 justify-end">
    <button
      className="px-4 py-2 text-gray-700 border border-gray-300 rounded hover:bg-gray-100"
      onClick={onReset}
    >
      Reset
    </button>
    {loading ? (
      <div className="w-[250px]">
        <GlitchLoader text="...Uploading" />
      </div>
    ) : (
      <button
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        onClick={onSave}
      >
        Save Blog
      </button>
  )}
</div>
);
