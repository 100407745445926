import React, { useEffect, useState } from "react";
import { Download, ChevronLeft, ChevronRight } from "lucide-react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "@/components/ui/dropdown-menu"; // Assuming shadcn/ui is used
import checkAuth from "../authentication/auth";
import axios from "axios";
import { toast } from "sonner";
//import { NotificationContainer, NotificationManager } from "react-notifications";
// import "react-notifications/lib/notifications.css";

const api = `${import.meta.env.VITE_APP_API_URL}`;

interface Transaction {
  _id: string;
  trnsactionId: string;
  name: string;
  mobile: string;
  email: string;
  description: string;
  date: string;
  amount: string;
  type: string;
}

const AccountHistory: React.FC = () => {
  const token = checkAuth()||"";
  const [history, setHistory] = useState<Transaction[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const expireSession = (message: string) => {
    if (message === "Session Expired") {
      localStorage.removeItem("token_tr");
      localStorage.removeItem("name");
      toast.error("Session expired. Please log in again.");
      setTimeout(() => {
        window.location.assign("/admin/login");
      }, 500);
    }else{
      toast.error(message);
    }
  };

  const getHistory = async () => {
    try {
      const res = await axios(`${api}/user/transaction`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });
      if (res.status === 200) {
        setHistory(res.data.data);
        toast.success("Transaction history fetched successfully!");
      }
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error) && error.response) {
        expireSession(error.response.data.message);
      } else {
        expireSession("Something went wrong");
      }
    }
  };

  useEffect(() => {
    getHistory();
  }, [token]);

  const exportToTXT = (data: Transaction[]) => {
    try {
      const txtContent = data.map((row) => JSON.stringify(row, null, 2)).join("\n\n");
      const blob = new Blob([txtContent], { type: "text/plain;charset=utf-8" });
      saveAs(blob, "account_statement.txt");
      toast.success("Data exported to TXT successfully!"); // Success toast
    } catch (error) {
      console.error(error);
      toast.error("Failed to export data to TXT."); // Error toast
    }
  };

  const exportToCSV = (data: Transaction[]) => {
    try {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));
      for (const row of data) {
        const values = headers.map((header) => {
          const val = row[header as keyof Transaction];
          return typeof val === "string" ? `"${val}"` : val;
        });
        csvRows.push(values.join(","));
      }
      const csvContent = csvRows.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, "account_statement.csv");
      toast.success("Data exported to CSV successfully!"); // Success toast
    } catch (error) {
      console.error(error);
      toast.error("Failed to export data to CSV."); // Error toast
    }
  };


  const exportToExcel = () => {
    try {
      const worksheet = XLSX.utils.json_to_sheet(history);
      const workbook = { Sheets: { Sheet1: worksheet }, SheetNames: ["Sheet1"] };
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
      saveAs(blob, "account_statement.xlsx");
      toast.success("Data exported to Excel successfully!"); // Success toast
    } catch (error) {
      console.error(error);
      toast.error("Failed to export data to Excel."); // Error toast
    }
  };


  const totalPages = Math.ceil(history.length / itemsPerPage);
  const currentItems = history.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      {/* <NotificationContainer /> */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">Account History</h2>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <button className="p-2 hover:bg-gray-100 rounded-full transition-colors">
              <Download size={24} />
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => exportToTXT(history)}>Export to TXT</DropdownMenuItem>
            <DropdownMenuItem onClick={() => exportToCSV(history)}>Export to CSV</DropdownMenuItem>
            <DropdownMenuItem onClick={exportToExcel}>Export to Excel</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-100">
            <th className="p-3 border border-gray-200 text-left">ID</th>
            <th className="p-3 border border-gray-200 text-left">Name</th>
            <th className="p-3 border border-gray-200 text-left">Number</th>
            <th className="p-3 border border-gray-200 text-left">Email</th>
            <th className="p-3 border border-gray-200 text-left">Title</th>
            <th className="p-3 border border-gray-200 text-left">Date</th>
            <th className="p-3 border border-gray-200 text-left">Amount</th>
            <th className="p-3 border border-gray-200 text-left">Type</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((record) => (
            <tr key={record._id} className="hover:bg-gray-50 transition-colors">
              <td className="p-3 border border-gray-200">{record.trnsactionId}</td>
              <td className="p-3 border border-gray-200">{record.name}</td>
              <td className="p-3 border border-gray-200">{record.mobile}</td>
              <td className="p-3 border border-gray-200">{record.email}</td>
              <td className="p-3 border border-gray-200">{record.description}</td>
              <td className="p-3 border border-gray-200">{record.date}</td>
              <td className="p-3 border border-gray-200">{record.amount}</td>
              <td className="p-3 border border-gray-200">{record.type}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {history.length === 0 && (
        <p className="text-gray-600 text-center py-4">No matching records found</p>
      )}

      {/* Pagination Controls */}
      <div className="flex justify-center items-center gap-2 mt-6">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="p-2 hover:bg-gray-100 rounded-full disabled:opacity-50"
        >
          <ChevronLeft size={20} />
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={`p-2 px-4 rounded-full ${
              currentPage === index + 1 ? "bg-blue-500 text-white" : "hover:bg-gray-100"
            }`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="p-2 hover:bg-gray-100 rounded-full disabled:opacity-50"
        >
          <ChevronRight size={20} />
        </button>
      </div>
    </div>
  );
};

export default AccountHistory;