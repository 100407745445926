import React from "react";
import { Button } from "@/components/ui/button"; // shadcn Button
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "@/components/ui/table"; // shadcn Table

interface ContentItem {
  _id: string;
  title: string;
  description: string; // Renamed from `descriptions` to `description`
  topic: string;
  subTopic: string;
  subDescription: string;
  example: string;
  codeSnippet: string;
  file: File | null;
  links: string[];
  prerequisites: string[];
  subject: string;
  tags: string[];
  level: string;
  duration: string;
  courseId: string;
}
interface ContentTableProps {
  data: ContentItem[];
  handleEdit: (item: ContentItem) => void;
  handleDelete: (id: string) => void;
}

const ContentTable: React.FC<ContentTableProps> = ({ data, handleEdit, handleDelete }) => {
  return (
    <div className="mx-auto p-4">
      <Table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
        <TableHeader className="bg-gray-800">
          <TableRow>
            <TableHead className="text-white">Subject</TableHead>
            <TableHead className="text-white">Title</TableHead>
            <TableHead className="text-white">Description</TableHead>
            <TableHead className="text-white">SubTopic</TableHead>
            <TableHead className="text-white">SubDescription</TableHead>
            <TableHead className="text-white">CodeSnippet</TableHead>
            <TableHead className="text-white">Action</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={item._id} className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}>
              <TableCell>{item.subject || "N/A"}</TableCell>
              <TableCell>{item.title || "N/A"}</TableCell>
              <TableCell>{item.description || "N/A"}</TableCell>
              <TableCell>{item.subTopic || "N/A"}</TableCell>
              <TableCell>{item.subDescription || "N/A"}</TableCell>
              <TableCell>{item.codeSnippet || "N/A"}</TableCell>
              <TableCell>
                <div className="flex space-x-2">
                  <Button
                    onClick={() => handleEdit(item)}
                    className="bg-blue-500 hover:bg-blue-700"
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => handleDelete(item._id)}
                    className="bg-red-500 hover:bg-red-700"
                  >
                    Delete
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ContentTable;