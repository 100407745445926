import React from 'react';
import { Link } from 'react-router-dom';
import checkAuth from '../authentication/auth';
import { LayoutDashboard, Users, Landmark, BookOpen, Mail, FileText, Camera, Paintbrush, CupSodaIcon } from 'lucide-react';
import { cn } from '@/lib/utils'; // Utility function for classNames

const Sidebar: React.FC = () => {
    const token = checkAuth();
    if (!token) {
        window.location.assign("/admin/login");
    }

    return (
        <div className={cn(
            "fixed left-0 top-[73px] bottom-0 w-64 bg-white p-5 border-r",
            "transition-all duration-300 ease-in-out h-[100vh] scroll-auto"
        )}>
            <ul className="space-y-2">
                <li>
                    <Link 
                        to="/app/dashboard" 
                        className={cn(
                            "flex items-center gap-3 p-2 rounded-md text-sm font-medium",
                            "hover:bg-gray-100 hover:text-blue-600 transition-colors"
                        )}
                    >
                        <LayoutDashboard className="h-5 w-5" />
                        <span>Dashboard</span>
                    </Link>
                </li>
                <li>
                    <Link 
                        to="/app/students" 
                        className={cn(
                            "flex items-center gap-3 p-2 rounded-md text-sm font-medium",
                            "hover:bg-gray-100 hover:text-blue-600 transition-colors"
                        )}
                    >
                        <Users className="h-5 w-5" />
                        <span>Student List</span>
                    </Link>
                </li>
                <li>
                    <Link 
                        to="/app/account-history" 
                        className={cn(
                            "flex items-center gap-3 p-2 rounded-md text-sm font-medium",
                            "hover:bg-gray-100 hover:text-blue-600 transition-colors"
                        )}
                    >
                        <Landmark className="h-5 w-5" />
                        <span>Account History</span>
                    </Link>
                </li>
                <li>
                    <Link 
                        to="/app/certificates" 
                        className={cn(
                            "flex items-center gap-3 p-2 rounded-md text-sm font-medium",
                            "hover:bg-gray-100 hover:text-blue-600 transition-colors"
                        )}
                    >
                        <FileText className="h-5 w-5" />
                        <span>Certificate List</span>
                    </Link>
                </li>
                <li>
                    <Link 
                        to="/app/blogs" 
                        className={cn(
                            "flex items-center gap-3 p-2 rounded-md text-sm font-medium",
                            "hover:bg-gray-100 hover:text-blue-600 transition-colors"
                        )}
                    >
                        <BookOpen className="h-5 w-5" />
                        <span>Blogs</span>
                    </Link>
                </li>
                <li>
                    <Link 
                        to="/app/contact" 
                        className={cn(
                            "flex items-center gap-3 p-2 rounded-md text-sm font-medium",
                            "hover:bg-gray-100 hover:text-blue-600 transition-colors"
                        )}
                    >
                        <Mail className="h-5 w-5" />
                        <span>Contact Request</span>
                    </Link>
                </li>
                <li>
                    <Link 
                        to="/app/study-add" 
                        className={cn(
                            "flex items-center gap-3 p-2 rounded-md text-sm font-medium",
                            "hover:bg-gray-100 hover:text-blue-600 transition-colors"
                        )}
                    >
                        <BookOpen className="h-5 w-5" />
                        <span>Study Material</span>
                    </Link>
                </li>
                <li>
                    <Link 
                        to="/app/digitalmarketing-dashboard" 
                        className={cn(
                            "flex items-center gap-3 p-2 rounded-md text-sm font-medium",
                            "hover:bg-gray-100 hover:text-blue-600 transition-colors"
                        )}
                    >
                        <Camera className="h-5 w-5" />
                        <span>Digital Marketing</span>
                    </Link>
                </li>
                <li>
                <Link 
                        to="/app/push-notification" 
                        className={cn(
                            "flex items-center gap-3 p-2 rounded-md text-sm font-medium",
                            "hover:bg-gray-100 hover:text-blue-600 transition-colors"
                        )}
                    >
                        <Paintbrush className="h-5 w-5" />
                        <span>Push Notification</span>
                    </Link>
                </li>
                <li>
                <Link 
                        to="/app/create-coupon" 
                        className={cn(
                            "flex items-center gap-3 p-2 rounded-md text-sm font-medium",
                            "hover:bg-gray-100 hover:text-blue-600 transition-colors"
                        )}
                    >
                        <CupSodaIcon className="h-5 w-5" />
                        <span>Create cuopon</span>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;