import { Helmet } from "react-helmet";
import BgDisplayItems from "../common/BgDisplayItem";
import FAQ from "../common/FAQ";
import SemesterInfoCard from "../common/SemesterInfoCard";

const MCA = () => {
    const mcaInfo = {
        semester1: {
            image: "assets/full-stack-webp/11zon_compressed/programming-background-collage_10_11zon.webp", // Replace with actual image path
            heading: "BCA 1st Semester",
            subheading: "",
            data: [
                "Fundamentals of Computer & Operations",
                "Creative English",
                "Basic Introduction of Network",
                "System Architecture","Introduction of the C, C++ Programming Language","Introduction & Basics of SEO"
            ],
        },
        semester2: {
            image: "assets/full-stack-webp/11zon_compressed/programming-background-collage (8)_9_11zon.webp", // Replace with actual image path
            heading: "BCA 2nd Semester",
            
            subheading: "Data Structures and Algorithms",
            data: [
                "C,C++ Practice",
                "Introduction of the Python programming language",
                "Python Project",
                "Black & Grey Hat SEO","Assignment + Exam",
            ],
        },
        semester3: {
            image: "assets/full-stack-webp/11zon_compressed/programming-background-collage (7)_8_11zon.webp", // Replace with actual image path
            heading: "BCA 3rd Semester",
            subheading: "",
           
            data: [
                "HTML / HTML5",
                "CSS / CSS 3",
                "JavaScript & OOPS Concept",
                "User Interface & Frontend","Blog & Content Optimization"
            ],
        },
        semester4: {
            image: "assets/full-stack-webp/pexels-mikhail-nilov-7988747_7_11zon.webp", // Replace with actual image path
            heading: "BCA 4th Semester",
            subheading: "",
           
            data: [
                "Logical thinking",
                "Node JS & Php / Laravel",
                "Database management system",
                "Dev Tools ( docker , github,Kubernetes, AWS, GCP & Azure )","API Development & Implementation","Website creation & Off-Page SEO","Assignment + Exam"
            ],
        },
        semester5: {
            image: "assets/full-stack-webp/pexels-pixabay-276452_12_11zon.webp", // Replace with actual image path
            heading: "BCA 5th Semester",
            subheading: "",
           
            data: [
                "Business Intelligence",
                "Client-Server Computing",
                "Design and analysis of the algorithm",
                "Cloud Computing","Introduction of soft computing","Bookmarking & Domain","Full-Stack Development"
            ],
        },
        semester6: {
            image: "assets/full-stack-webp/pexels-tranmautritam-326514_4_11zon_13_11zon.webp", // Replace with actual image path
            heading: "BCA 6th Semester",
            subheading: "",
           
            data: [
                "Blog Optimization & Internal Linking",
                "Internal Linking & Page speed test",
                "Industry Level Projects",
                "Exam"
            ],
        },
    };

    const FAQ_ITEMS = [
        {
            question: "How is the Skillyards On-The Job BCA Degree Program different from traditional BCA degrees?",
            answer: `Skillyards On-The Job BCA Degree Program is different in a lot of ways than the traditional BCA such as practical training on client projects, unlike theory-based classroom training. Additionally, to provide students with a real-world experience before entering the industry, they also work as Software Developer Intern with a monthly stipend of up to 15000. As soon as their internship is complete, they get a 100% Guaranteed In-house Placement with a package of up to 10 LPA as a Software Developer before their graduation.`,
        },
        {
            question: "Are there any scholarship opportunities available?",
            answer: `Students get a scholarship of up to 70% based on their practical knowledge to provide students an easy hand at paying the fees.`,
        },
        {
            question: "Is EMI available in the On-The Job BCA Degree Program?",
            answer: `Yes, the On-The-Job BCA Program offers an EMI option so that students won't feel pressured to pay the program fees all at once.`,
        },
    ];

    const staticItem = {
        id: 1,
        title: "BCA Degree Program In Agra",
        subtitle: "With the On-The Job BCA, MCA, or BBA Degree Program",
        description: [
            "Project-based training with Skillyards Club Facilitation",
            "Paid Internship with stipend up to ₹15,000/month",
            "Guaranteed Placement up to 10 LPA in Top MNCs",
        ],
        buttonText: "Join Now",
        buttonAction: "#",
        backgroundImage: "/new-banner/webp/1banner.webp",
    };

    const additionalInfo = [
        {
            subheading: "Skillyards SkillPresent?",
            items: [
                "Technical Search Engine Optimization (SEO)",
                "How does Artificial Intelligence work in websites ",
            ],
        },
        {
            subheading: "Additional Advantages",
            items: [
                "6 months paid internship as a Junior Developer Intern",
                "Stipend of up to ₹15,000/ month",
                "Guaranteed in-house placement with a package of up to10 LPA",
            ],
        },
    ];

    const semesters = Object.values(mcaInfo);

    return (<>
    
    <Helmet>
    {/* Character Encoding */}
    <meta charSet="utf-8" />
    <title>Best BCA Degree Program in Agra | UGC Certified University | 10 LPA Placement</title>

    {/* Browser Compatibility */}
    <meta httpEquiv="x-ua-compatible" content="ie=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    {/* Meta Description */}
    <meta 
        name="description" 
        content="Join the Best BCA Degree Program in Agra at a UGC A+ Grade Certified University. Specialize in Web Development, AI, and Full-Stack Development with guaranteed placements up to 10 LPA." 
    />

    {/* Keywords */}
    <meta 
        name="keywords" 
        content="BCA in Agra, Best BCA Program Agra, BCA Web Development, BCA with AI, BCA Full Stack Development, UGC Certified BCA Agra, Skillyards BCA Course, Top BCA College Agra" 
    />

    {/* Author */}
    <meta name="author" content="Skillyards" />

    {/* Canonical URL */}
    <link rel="canonical" href="https://www.skillyards.com/bca-training-program-in-agra" />

    {/* Open Graph (OG) Meta Tags */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Best BCA Degree Program in Agra | UGC Certified | Placements Up to 10 LPA" />
    <meta 
        property="og:description" 
        content="Explore our UGC Certified BCA Degree Program in Agra. Specialize in Web Development, Artificial Intelligence, and Full Stack Development with assured placements up to 10 LPA." 
    />
    <meta property="og:url" content="https://www.skillyards.com/bca-training-program-in-agra" />
    <meta property="og:image" content="https://www.skillyards.com/assets/images/bca-program.jpg" />

    {/* Twitter Meta Tags */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Best BCA Degree Program in Agra | UGC Certified University | 10 LPA Placement" />
    <meta 
        name="twitter:description" 
        content="Join the Best BCA Program in Agra. Study Web Development, AI, and Full Stack Development with UGC Certification and assured placements up to 10 LPA." 
    />
    <meta name="twitter:image" content="https://www.skillyards.com/assets/images/bca-program.jpg" />
</Helmet>
    
        <div>
            {/* Hero Section */}
            <BgDisplayItems item={staticItem} />

            {/* BCA Program Heading */}
            <h1 className="text-2xl font-bold text-center mb-8">BCA Program
        <div className="w-[7rem] h-1 bg-yellow-400 mx-auto mt-2 mb-8"></div>

            </h1>

            {/* Semester Cards */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-4">
                {semesters.map((semester, index) => (
                    <SemesterInfoCard
                        key={index}
                        imageSrc={semester.image}
                        heading={semester.heading}
                        subheading={semester?.subheading||""}
                        data={semester.data}
                    />
                ))}
            </div>

            {/* Additional Information Sections */}
            <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8 py-4">
                {additionalInfo.map((section, index) => (
                    <div key={index} className="bg-white rounded-lg shadow-lg p-6">
                        <h2 className="text-2xl font-bold text-gray-800 mb-4">
                            {section.subheading}
                        </h2>
                        <ul className="space-y-2">
                            {section.items.map((item, idx) => (
                                <li key={idx} className="text-gray-700">
                                    <span className="mr-2">•</span>
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <div className="mt-12">
                <FAQ faqItems={FAQ_ITEMS} />
            </div>
        </div>
        </> );
};

export default MCA;