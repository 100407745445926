
import React, { useEffect, useState } from "react";
import { Edit, Trash } from "lucide-react";
import { Button } from "@/components/ui/button"; // Assuming shadcn/ui is used
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog"; // Assuming shadcn/ui is used
import checkAuth from "../authentication/auth";
import axios from "axios";
import { toast } from "sonner";
// import { NotificationContainer, NotificationManager } from "react-notifications";
// import "react-notifications/lib/notifications.css";  

const api = `${import.meta.env.VITE_APP_API_URL}`;
// const api = "http://localhost:8000/api";

interface Certificate {
  _id: string;
  certificateno: string;
  studentid: string;
  certificateid: string;
  name: string;
  program: string;
  startdate: string;
  enddate: string;
  status: string;
}

const CertificateList: React.FC = () => {
  const token = checkAuth()||"";
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [formData, setFormData] = useState({
    _id:"",
    studentid: "",
    certificateid: "",
    name: "",
    program: "",
    startdate: "",
    enddate: "",
    status: "",
  });

  const getCertificates = async () => {
    toast.loading("Fetching certificates...");
    try {
      const res = await axios(`${api}/admin/certificate`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });
      if (res.status === 200) {
        setCertificates(res.data.data);
        toast.success("Certificates fetched successfully!");
      }
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error) && error.response) {
        expireSession(error.response.data.message);
      } else {
        expireSession("Something went wrong");
      }
    } finally{
      toast.dismiss(); // Dismiss loading toast
    }
  };

  useEffect(() => {
    if (token) {
      getCertificates();
    }
  }, [token]);

  const expireSession = (message: string) => {
    if (message === "Session Expired") {
      localStorage.removeItem("token_tr");
      localStorage.removeItem("name");
      toast.warning("Session expired. Redirecting to login...");
      setTimeout(() => {
        window.location.assign("/admin/login");
      }, 500);
    }else{
      toast.error(message);
    }
    //NotificationManager.error(message);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${api}/admin/certificate`, formData, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });
      if (res.status === 201) {
        toast.success("Certificate created successfully!");
       // NotificationManager.success("Successfully Created");
        setShowAddModal(false);
        getCertificates();
      }
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error) && error.response) {
        expireSession(error.response.data.message);
      } else {
        expireSession("Something went wrong");
      }
    }finally{
      toast.dismiss();
    }
  };

  const updateCertificate = async (e: React.FormEvent) => {
    e.preventDefault();
    toast.loading("Updating certificate...");
    try {
      const res = await axios.put(`${api}/admin/certificate`, { ...formData, id: formData?._id }, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });
      if (res.status === 200) {
        toast.success("Certificate updated successfully!");
        // NotificationManager.success("Successfully Updated");
        setShowUpdateModal(false);
        getCertificates();
      }
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error) && error.response) {
        expireSession(error.response.data.message);
      } else {
        expireSession("Something went wrong");
      }
    } finally{
      toast.dismiss(); // Dismiss loading toast
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      {/* <NotificationContainer /> */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">Certificate List</h2>
        <Dialog open={showAddModal} onOpenChange={setShowAddModal}>
          <DialogTrigger asChild>
            <Button variant="outline" className="bg-yellow-400 hover:bg-yellow-500 text-white">
              Add Certificate
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Certificate Form</DialogTitle>
            </DialogHeader>
            <form onSubmit={onSubmit}>
              <div className="space-y-4">
                <div className="space-y-2">
                  <label htmlFor="studentid" className="block text-sm font-medium text-gray-700">
                    Student ID
                  </label>
                  <input
                    type="text"
                    id="studentid"
                    name="studentid"
                    value={formData.studentid}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter Student Id"
                  />
                </div>
                <div className="space-y-2">
                  <label htmlFor="certificateid" className="block text-sm font-medium text-gray-700">
                    Certificate ID
                  </label>
                  <input
                    type="text"
                    id="certificateid"
                    name="certificateid"
                    value={formData.certificateid}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter Certificate Id"
                  />
                </div>
                <div className="space-y-2">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter Student Name"
                  />
                </div>
                <div className="space-y-2">
                  <label htmlFor="program" className="block text-sm font-medium text-gray-700">
                    Program
                  </label>
                  <input
                    type="text"
                    id="program"
                    name="program"
                    value={formData.program}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter Program Name"
                  />
                </div>
                <div className="space-y-2">
                  <label htmlFor="startdate" className="block text-sm font-medium text-gray-700">
                    Start Date
                  </label>
                  <input
                    type="date"
                    id="startdate"
                    name="startdate"
                    value={formData.startdate}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="space-y-2">
                  <label htmlFor="enddate" className="block text-sm font-medium text-gray-700">
                    End Date
                  </label>
                  <input
                    type="date"
                    id="enddate"
                    name="enddate"
                    value={formData.enddate}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="space-y-2">
                  <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                    Status
                  </label>
                  <select
                    id="status"
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  >
                    <option value="">Select Status</option>
                    <option value="Pursuing">Pursuing</option>
                    <option value="Verified">Verified</option>
                    <option value="Canceled">Canceled</option>
                  </select>
                </div>
              </div>
              <DialogFooter className="mt-4">
                <Button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white">
                  Save Changes
                </Button>
              </DialogFooter>
            </form>
          </DialogContent>
        </Dialog>
      </div>

      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-yellow-400 text-white">
            <th className="p-3 border border-gray-200 text-left">S.No</th>
            <th className="p-3 border border-gray-200 text-left">Student ID</th>
            <th className="p-3 border border-gray-200 text-left">Certificate ID</th>
            <th className="p-3 border border-gray-200 text-left">Student Name</th>
            <th className="p-3 border border-gray-200 text-left">Program</th>
            <th className="p-3 border border-gray-200 text-left">Start Date</th>
            <th className="p-3 border border-gray-200 text-left">End Date</th>
            <th className="p-3 border border-gray-200 text-left">Status</th>
            <th className="p-3 border border-gray-200 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          {certificates.map((item) => (
            <tr key={item._id} className="hover:bg-gray-50 transition-colors">
              <td className="p-3 border border-gray-200">{item.certificateno}</td>
              <td className="p-3 border border-gray-200">{item.studentid}</td>
              <td className="p-3 border border-gray-200">{item.certificateid}</td>
              <td className="p-3 border border-gray-200">{item.name}</td>
              <td className="p-3 border border-gray-200">{item.program}</td>
              <td className="p-3 border border-gray-200">{item.startdate}</td>
              <td className="p-3 border border-gray-200">{item.enddate}</td>
              <td className="p-3 border border-gray-200">{item.status}</td>
              <td className="p-3 border border-gray-200">
                <button
                  onClick={() => {
                    setFormData(item);
                    setShowUpdateModal(true);
                  }}
                  className="text-blue-500 hover:text-blue-600"
                >
                  <Edit size={20} />
                </button>
                <button className="text-red-500 hover:text-red-600 ml-2">
                  <Trash size={20} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {certificates.length === 0 && (
        <p className="text-gray-600 text-center py-4">No matching records found</p>
      )}

      {/* Update Modal */}
      <Dialog open={showUpdateModal} onOpenChange={setShowUpdateModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update Certificate Form</DialogTitle>
          </DialogHeader>
          <form onSubmit={updateCertificate}>
            <div className="space-y-4">
              <div className="space-y-2">
                <label htmlFor="studentid" className="block text-sm font-medium text-gray-700">
                  Student ID
                </label>
                <input
                  type="text"
                  id="studentid"
                  name="studentid"
                  value={formData.studentid}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Enter Student Id"
                />
              </div>
              <div className="space-y-2">
                <label htmlFor="certificateid" className="block text-sm font-medium text-gray-700">
                  Certificate ID
                </label>
                <input
                  type="text"
                  id="certificateid"
                  name="certificateid"
                  value={formData.certificateid}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Enter Certificate Id"
                />
              </div>
              <div className="space-y-2">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Enter Student Name"
                />
              </div>
              <div className="space-y-2">
                <label htmlFor="program" className="block text-sm font-medium text-gray-700">
                  Program
                </label>
                <input
                  type="text"
                  id="program"
                  name="program"
                  value={formData.program}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Enter Program Name"
                />
              </div>
              <div className="space-y-2">
                <label htmlFor="startdate" className="block text-sm font-medium text-gray-700">
                  Start Date
                </label>
                <input
                  type="date"
                  id="startdate"
                  name="startdate"
                  value={formData.startdate}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="space-y-2">
                <label htmlFor="enddate" className="block text-sm font-medium text-gray-700">
                  End Date
                </label>
                <input
                  type="date"
                  id="enddate"
                  name="enddate"
                  value={formData.enddate}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="space-y-2">
                <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                  Status
                </label>
                <select
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="">Select Status</option>
                  <option value="Pursuing">Pursuing</option>
                  <option value="Verified">Verified</option>
                  <option value="Canceled">Canceled</option>
                </select>
              </div>
            </div>
            <DialogFooter className="mt-4">
              <Button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white">
                Save Changes
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CertificateList;