interface FormData {
    name: string;
    email: string;
    mobile: string;
    city: string;
    message: string;
    role: string;
    paymentStatus?: boolean;
}

export default function SaveToLocal(formData: FormData): void {
    if (typeof formData !== 'object' || formData === null) {
        console.error('Invalid form data');
        return;
    }
    
    // Ensure paymentStatus is set to false by default
    formData.paymentStatus = formData.paymentStatus ?? false;
    
    // Convert form data to a JSON string
    const jsonData = JSON.stringify(formData);
    
    // Store in localStorage with a key
    localStorage.setItem('formData', jsonData);
    
    // console.log('Form data stored successfully');
}
