// import React, { useEffect, useState } from "react";
// import { 
//   Download, 
//   FileText, 
//   FileSpreadsheet, 
//   Table2, 
//   CheckCircle2, 
//   XCircle 
// } from 'lucide-react';
// import { saveAs } from "file-saver";
// import * as XLSX from "xlsx";
// import moment from "moment";
// import axios from "axios";
// import checkAuth from "../authentication/auth";
// import { Button } from "@/components/ui/button";
// import { 
//   DropdownMenu, 
//   DropdownMenuTrigger, 
//   DropdownMenuContent, 
//   DropdownMenuItem 
// } from "@/components/ui/dropdown-menu";
// import { 
//   Table, 
//   TableBody, 
//   TableCell, 
//   TableHead, 
//   TableHeader, 
//   TableRow 
// } from "@/components/ui/table";
// import { 
//   Card, 
//   CardContent, 
//   CardHeader, 
//   CardTitle 
// } from "@/components/ui/card";
// import { Badge } from "@/components/ui/badge";
// import Pagination from "./pagination";
// import { toast } from "sonner";

// const api = `${import.meta.env.VITE_APP_API_URL}`;

// interface PaginationInfo {
//   currentPage: number;
//   totalPages: number;
//   itemsPerPage: number;
//   totalItems: number;
//   hasNextPage: boolean;
//   hasPrevPage: boolean;
// }

// interface Contact {
//   _id: string;
//   name: string;
//   mobile: string;
//   subject: string;
//   location: string;
//   createdAt: string;
//   message: string;
//   status: string;
// }

// interface ApiResponse {
//   success: boolean;
//   statusCode: number;
//   message: string;
//   data: Contact[];
//   pagination: PaginationInfo;
// }

// const ContactRequest: React.FC = () => {
//   const [loading, setLoading] = useState(false);
//   const token = checkAuth() || "";
//   const [contact, setContact] = useState<Contact[]>([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
//     currentPage: 1,
//     totalPages: 1,
//     itemsPerPage: 10,
//     totalItems: 0,
//     hasNextPage: false,
//     hasPrevPage: false
//   });

//   const getContact = async (page = 1) => {
//     setLoading(true);
//     toast.loading("Fetching contact data...");
//     try {
//       const res = await axios<ApiResponse>(`${api}/user/contact?page=${page}&limit=${paginationInfo.itemsPerPage}`, {
//         headers: {
//           "content-type": "application/json",
//           Authorization: `Bearer ${JSON.parse(token)}`,
//         },
//       });
      
//       if (res.status === 200) {
//         setContact(res.data.data);
//         setPaginationInfo(res.data.pagination);
//         toast.success("Contact data fetched successfully!");
//       }
//     } catch (error) {
//       console.error(error);
//       toast.error("Failed to fetch contact data. Please try again.");
//     } finally {
//       setLoading(false);
//       toast.dismiss(); 
//     }
//   };

//   const exportData = (type: 'txt' | 'csv' | 'excel') => {
//     if (contact.length === 0) {
//       toast.warning("No data available to export.");
//       return;
//     }

//     try {
//       switch(type) {
//         case 'txt': {
//           const txtContent = contact.map(row => JSON.stringify(row, null, 2)).join("\n\n");
//           saveAs(new Blob([txtContent], { type: "text/plain;charset=utf-8" }), "contact.txt");
//           toast.success("Data exported as TXT successfully!");
//           break;
//         }
//         case 'csv': {
//           const csvRows = [
//             Object.keys(contact[0]).join(","),
//             ...contact.map(row => 
//               Object.values(row).map(val => 
//                 typeof val === "string" ? `"${val}"` : val
//               ).join(",")
//             )
//           ];
//           saveAs(new Blob([csvRows.join("\n")], { type: "text/csv;charset=utf-8" }), "contact.csv");
//           toast.success("Data exported as CSV successfully!");
//           break;
//         }
//         case 'excel': {
//           const worksheet = XLSX.utils.json_to_sheet(contact);
//           const workbook = { Sheets: { Sheet1: worksheet }, SheetNames: ["Sheet1"] };
//           const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
//           saveAs(new Blob([excelBuffer], { type: "application/octet-stream" }), "contact.xlsx");
//           toast.success("Data exported as Excel successfully!");
//           break;
//         }
//         default: {
//           toast.error("Invalid export type selected.");
//           break;
//         }
//       }
//     } catch (error) {
//       console.error("Export error:", error);
//       toast.error("Failed to export data. Please try again.");
//     }
//   };

//   const markContactStatus = async (rec: Contact) => {
//     try {
//       const res = await axios.put(
//         `${api}/user/contact`,
//         {
//           id: rec._id,
//           status: rec.status === "Pending" ? "Closed" : "Pending",
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${JSON.parse(token)}`,
//           },
//         }
//       );
//       if (res.status === 200) {
//         // Refresh the current page after status update
//        // getContact(currentPage);
//         toast.success(`Contact status updated to ${res.data.status}`);
//       }
//     } catch (error) {
//       console.error("Status update error:", error);
//       toast.error("Failed to update contact status. Please try again.");
//     }
//   };

//   useEffect(() => {
//     getContact(1);
//   }, []);

//   const handlePageChange = (page: number) => {
//     setCurrentPage(page);
//     getContact(page);
//   };

//   return (
//     <Card className="w-full">
//       <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
//         <CardTitle className="text-2xl font-bold">Contact Requests</CardTitle>
//         <DropdownMenu>
//           <DropdownMenuTrigger asChild>
//             <Button variant="outline" size="sm">
//               <Download className="mr-2 h-4 w-4" /> Export
//             </Button>
//           </DropdownMenuTrigger>
//           <DropdownMenuContent>
//             <DropdownMenuItem onClick={() => exportData('txt')}>
//               <FileText className="mr-2 h-4 w-4" /> TXT
//             </DropdownMenuItem>
//             <DropdownMenuItem onClick={() => exportData('csv')}>
//               <Table2 className="mr-2 h-4 w-4" /> CSV
//             </DropdownMenuItem>
//             <DropdownMenuItem onClick={() => exportData('excel')}>
//               <FileSpreadsheet className="mr-2 h-4 w-4" /> Excel
//             </DropdownMenuItem>
//           </DropdownMenuContent>
//         </DropdownMenu>
//       </CardHeader>
//       <CardContent>
//         <div className="w-full overflow-x-auto">
//           <Table>
//             <TableHeader>
//               <TableRow>
//                 {['S.No', 'Name', 'Number', 'Program', 'Location', 'Date', 'Message', 'Status', 'Action'].map(header => (
//                   <TableHead key={header}>{header}</TableHead>
//                 ))}
//               </TableRow>
//             </TableHeader>
//             <TableBody>
//               {loading ? (
//                 <TableRow>
//                   <TableCell colSpan={9} className="text-center py-4">
//                     Loading...
//                   </TableCell>
//                 </TableRow>
//               ) : contact.length === 0 ? (
//                 <TableRow>
//                   <TableCell colSpan={9} className="text-center py-4">
//                     No contact requests found
//                   </TableCell>
//                 </TableRow>
//               ) : (
//                 contact.map((record, i) => (
//                   <TableRow key={record._id}>
//                     <TableCell>{((currentPage - 1) * paginationInfo.itemsPerPage) + i + 1}</TableCell>
//                     <TableCell>{record.name}</TableCell>
//                     <TableCell>{record.mobile}</TableCell>
//                     <TableCell>{record.subject}</TableCell>
//                     <TableCell>{record.location}</TableCell>
//                     <TableCell>{moment(record.createdAt).format("lll")}</TableCell>
//                     <TableCell>{record.message}</TableCell>
//                     <TableCell>
//                       <Badge variant={record.status === "Pending" ? "secondary" : "destructive"}>
//                         {record.status}
//                       </Badge>
//                     </TableCell>
//                     <TableCell>
//                       <Button 
//                         size="sm" 
//                         variant={record.status === "Pending" ? "outline" : "default"}
//                         onClick={() => markContactStatus(record)}
//                         disabled={loading}
//                       >
//                         {record.status === "Pending" ? (
//                           <><CheckCircle2 className="mr-2 h-4 w-4" /> Active</>
//                         ) : (
//                           <><XCircle className="mr-2 h-4 w-4" /> Closed</>
//                         )}
//                       </Button>
//                     </TableCell>
//                   </TableRow>
//                 ))
//               )}
//             </TableBody>
//           </Table>
//         </div>

//         {/* Pagination */}
//         {!loading && contact.length > 0 && (
//           <div className="flex justify-center mt-4">
//             <Pagination 
//               currentPage={paginationInfo.currentPage} 
//               totalPages={paginationInfo.totalPages} 
//               onPageChange={handlePageChange} 
//             />
//           </div>
//         )}
//       </CardContent>
//     </Card>
//   );
// };

// export default ContactRequest;




import React, { useEffect, useState } from "react";
import { 
  Download, 
  FileText, 
  FileSpreadsheet, 
  Table2, 
  CheckCircle2, 
  XCircle 
} from 'lucide-react';
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import axios from "axios";
import checkAuth from "../authentication/auth";
import { Button } from "@/components/ui/button";
import { 
  DropdownMenu, 
  DropdownMenuTrigger, 
  DropdownMenuContent, 
  DropdownMenuItem 
} from "@/components/ui/dropdown-menu";
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from "@/components/ui/table";
import { 
  Card, 
  CardContent, 
  CardHeader, 
  CardTitle 
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import Pagination from "./pagination";
import { toast } from "sonner";

const api = `${import.meta.env.VITE_APP_API_URL}`;

interface PaginationInfo {
  currentPage: number;
  totalPages: number;
  itemsPerPage: number;
  totalItems: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

interface Contact {
  _id: string;
  name: string;
  mobile: string;
  subject: string;
  location: string;
  createdAt: string;
  message: string;
  status: string;
}

interface ApiResponse {
  success: boolean;
  statusCode: number;
  message: string;
  data: Contact[];
  pagination: PaginationInfo;
}

const ContactRequest: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [statusUpdating, setStatusUpdating] = useState<string | null>(null);
  const token = checkAuth() || "";
  const [contact, setContact] = useState<Contact[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 10,
    totalItems: 0,
    hasNextPage: false,
    hasPrevPage: false
  });

  const getContact = async (page = 1) => {
    setLoading(true);
    const toastId = toast.loading("Fetching contact data...");
    
    try {
      const res = await axios<ApiResponse>(`${api}/user/contact?page=${page}&limit=${paginationInfo.itemsPerPage}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });
      
      if (res.status === 200) {
        setContact(res.data.data);
        setPaginationInfo(res.data.pagination);
        toast.success("Contact data fetched successfully!");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch contact data. Please try again.");
    } finally {
      setLoading(false);
      toast.dismiss(toastId);
    }
  };

  const exportData = (type: 'txt' | 'csv' | 'excel') => {
    if (contact.length === 0) {
      toast.warning("No data available to export.");
      return;
    }

    try {
      const getExportableData = () => contact.map(({ _id, ...rest }) => rest);

      switch(type) {
        case 'txt': {
          const txtContent = getExportableData().map(row => JSON.stringify(row, null, 2)).join("\n\n");
          saveAs(new Blob([txtContent], { type: "text/plain;charset=utf-8" }), "contact.txt");
          toast.success("Data exported as TXT successfully!");
          break;
        }
        case 'csv': {
          const exportableData = getExportableData();
          const csvRows = [
            Object.keys(exportableData[0]).join(","),
            ...exportableData.map(row => 
              Object.values(row).map(val => 
                typeof val === "string" ? `"${val}"` : val
              ).join(",")
            )
          ];
          saveAs(new Blob([csvRows.join("\n")], { type: "text/csv;charset=utf-8" }), "contact.csv");
          toast.success("Data exported as CSV successfully!");
          break;
        }
        case 'excel': {
          const worksheet = XLSX.utils.json_to_sheet(getExportableData());
          const workbook = { Sheets: { Sheet1: worksheet }, SheetNames: ["Sheet1"] };
          const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
          saveAs(new Blob([excelBuffer], { type: "application/octet-stream" }), "contact.xlsx");
          toast.success("Data exported as Excel successfully!");
          break;
        }
      }
    } catch (error) {
      console.error("Export error:", error);
      toast.error("Failed to export data. Please try again.");
    }
  };

  const markContactStatus = async (rec: Contact) => {
    // Prevent multiple clicks while updating
    if (statusUpdating === rec._id) return;
    
    setStatusUpdating(rec._id);
    const newStatus = rec.status === "Pending" ? "Closed" : "Pending";
    
    // Optimistically update the UI
    setContact(prevContacts => 
      prevContacts.map(contact => 
        contact._id === rec._id 
          ? { ...contact, status: newStatus }
          : contact
      )
    );

    try {
      const res = await axios.put(
        `${api}/user/contact`,
        {
          id: rec._id,
          status: newStatus,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );
      
      if (res.status === 200) {
        toast.success(`Contact status updated to ${newStatus}`);
      }
    } catch (error) {
      // Revert the optimistic update on error
      setContact(prevContacts => 
        prevContacts.map(contact => 
          contact._id === rec._id 
            ? { ...contact, status: rec.status }
            : contact
        )
      );
      console.error("Status update error:", error);
      toast.error("Failed to update contact status. Please try again.");
    } finally {
      setStatusUpdating(null);
    }
  };

  useEffect(() => {
    getContact(1);
  }, []);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getContact(page);
  };

  return (
    <Card className="w-full">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-2xl font-bold">Contact Requests</CardTitle>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="sm">
              <Download className="mr-2 h-4 w-4" /> Export
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => exportData('txt')}>
              <FileText className="mr-2 h-4 w-4" /> TXT
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => exportData('csv')}>
              <Table2 className="mr-2 h-4 w-4" /> CSV
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => exportData('excel')}>
              <FileSpreadsheet className="mr-2 h-4 w-4" /> Excel
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </CardHeader>
      <CardContent>
        <div className="w-full overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow>
                {['S.No', 'Name', 'Number', 'Program', 'Location', 'Date', 'Message', 'Status', 'Action'].map(header => (
                  <TableHead key={header}>{header}</TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={9} className="text-center py-4">
                    Loading...
                  </TableCell>
                </TableRow>
              ) : contact.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} className="text-center py-4">
                    No contact requests found
                  </TableCell>
                </TableRow>
              ) : (
                contact.map((record, i) => (
                  <TableRow key={record._id}>
                    <TableCell>{((currentPage - 1) * paginationInfo.itemsPerPage) + i + 1}</TableCell>
                    <TableCell>{record.name}</TableCell>
                    <TableCell>{record.mobile}</TableCell>
                    <TableCell>{record.subject}</TableCell>
                    <TableCell>{record.location}</TableCell>
                    <TableCell>{moment(record.createdAt).format("lll")}</TableCell>
                    <TableCell>{record.message}</TableCell>
                    <TableCell>
                      <Badge variant={record.status === "Pending" ? "secondary" : "destructive"}>
                        {record.status}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      <Button 
                        size="sm" 
                        variant={record.status === "Pending" ? "outline" : "default"}
                        onClick={() => markContactStatus(record)}
                        disabled={statusUpdating === record._id}
                      >
                        {statusUpdating === record._id ? (
                          "Updating..."
                        ) : record.status === "Pending" ? (
                          <><CheckCircle2 className="mr-2 h-4 w-4" /> Active</>
                        ) : (
                          <><XCircle className="mr-2 h-4 w-4" /> Closed</>
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>

        {/* Pagination */}
        {!loading && contact.length > 0 && (
          <div className="flex justify-center mt-4">
            <Pagination 
              currentPage={paginationInfo.currentPage} 
              totalPages={paginationInfo.totalPages} 
              onPageChange={handlePageChange} 
            />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default ContactRequest;