import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { Progress } from "@/components/ui/progress";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { Badge } from "@/components/ui/badge";
import { 
  Clock, 
  Award, 
  UserCircle, 
  Mail, 
  Phone, 
  Briefcase, 
  ChevronRight, 
  Brain, 
  Send,
  Lightbulb,
  LifeBuoy,
  ArrowRight,
  CheckCheck,
} from "lucide-react";
import { motion } from "framer-motion";
import { useAuth } from "@/lib/auth-context";

interface Option {
    text: string;
    isCorrect: boolean;
    _id: string;
}

interface Question {
    question: string;
    options: Option[];
    _id: string;
}

interface McqTest {
    _id: string;
    title: string;
    questions: Question[];
    createdAt: string;
    updatedAt: string;
    __v: number;
}

interface UserInfo {
    name: string;
    email: string;
    mobile: string;
    employeeId: string;
}

const api = `${import.meta.env.VITE_APP_API_URL}`;

const SingleAptitudeTest = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { isAuthenticated, login } = useAuth();
    const [test, setTest] = useState<McqTest | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedOptions, setSelectedOptions] = useState<Record<string, string>>({});
    const [showUserForm, setShowUserForm] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userInfo, setUserInfo] = useState<UserInfo>({
        name: "",
        email: "",
        mobile: "",
        employeeId: "",
    });
    const [userId, setUserId] = useState<string | null>(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [completionPercentage, setCompletionPercentage] = useState(0);

    useEffect(() => {
        if (test) {
            const answeredCount = Object.keys(selectedOptions).length;
            const totalQuestions = test.questions.length;
            setCompletionPercentage((answeredCount / totalQuestions) * 100);
        }
    }, [selectedOptions, test]);

    const fetchTest = async () => {
        if (!id) return;
        try {
            setLoading(true);
            setError(null);
            const response = await fetch(`${api}/get-single-mcq/${id}`);
            if (!response.ok) throw new Error(`Failed to fetch test: ${response.status}`);
            const data: McqTest = await response.json();
            setTest(data);
        } catch (err) {
            setError(err instanceof Error ? err.message : "An error occurred");
            setTest(null);
        } finally {
            setLoading(false);
        }
    };

    const handleUserInfoSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
            const response = await fetch(`${api}/start-mcq`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(userInfo),
            });

            const result = await response.json();

            // Check if response contains an error
            if (result.error) {
                setError(result.error); // Display the specific error from the backend
                // Wait for 3 seconds before redirecting
                setTimeout(() => {
                    navigate("/");
                }, 3000);
                return; // Exit the function early to prevent proceeding
            }

            if (!response.ok) {
                throw new Error(`Failed to submit user info: ${response.status}`);
            }

            // If no error and response is OK, authenticate the user and proceed
            setUserId(result._id);
            login();
            setShowUserForm(false);
            await fetchTest();
        } catch (err) {
            setError(err instanceof Error ? err.message : "Failed to submit user information");
            setTimeout(() => {
                navigate("/");
            }, 3000); // Redirect after 3 seconds on generic error
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDialogClose = (open: boolean) => {
        if (!open && !isAuthenticated) {
            navigate("/");
        }
        setShowUserForm(open);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserInfo((prev) => ({ ...prev, [name]: value }));
    };

    const handleOptionToggle = (questionId: string, optionId: string) => {
        setSelectedOptions((prev) => {
            const newSelected = { ...prev };
            if (newSelected[questionId] === optionId) {
                delete newSelected[questionId];
            } else {
                newSelected[questionId] = optionId;
            }
            return newSelected;
        });
    };

    const handleNextQuestion = () => {
        if (test && currentQuestionIndex < test.questions.length - 1) {
            setCurrentQuestionIndex(prev => prev + 1);
        }
    };

    const handlePrevQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(prev => prev - 1);
        }
    };

    const handleSubmit = async () => {
        if (!test || !userId) {
            setError("Test data or user ID is missing");
            return;
        }

        setShowConfirmDialog(false);
        setIsSubmitting(true);

        const submissionData = {
            userId,
            results: [
                {
                    testId: test._id,
                    mcqTestId: test._id,
                    submittedAnswers: test.questions
                        .filter((question) => selectedOptions[question._id])
                        .map((question) => {
                            const selectedIndex = question.options.findIndex(
                                (opt) => opt._id === selectedOptions[question._id]
                            );
                            return {
                                questionId: question._id,
                                selectedOption: selectedIndex.toString(),
                            };
                        }),
                },
            ],
        };

        try {
            const response = await fetch(`${api}/update-result`, {
                method: "PATCH",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(submissionData),
            });
            
            if (!response.ok) throw new Error(`Failed to submit test: ${response.status}`);
            setIsSubmitted(true);
        } catch (err) {
            setError(err instanceof Error ? err.message : "Unknown error");
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleViewResults = () => {
        navigate(`/aptitude-test-result/${id}`, { state: { test, selectedOptions, userId } });
    };

    if (loading) return (
        <div className="flex flex-col justify-center items-center h-screen bg-gradient-to-br from-blue-50 to-indigo-100">
            <div className="w-16 h-16 border-4 border-yellow-500 border-t-transparent rounded-full animate-spin mb-4"></div>
            <p className="text-xl font-medium text-yellow-500">Loading your aptitude test...</p>
        </div>
    );

    if (error) return (
        <div className="flex flex-col justify-center items-center h-screen bg-red-50">
            <div className="p-6 bg-white rounded-xl shadow-lg max-w-md text-center">
                <h2 className="text-2xl font-bold text-red-600 mb-2">Error</h2>
                <p className="text-gray-700 mb-4">{error}</p>
                <p className="text-sm text-gray-500">Redirecting to home in a moment...</p>
            </div>
        </div>
    );

    return (
        <>
            <Dialog open={showUserForm} onOpenChange={handleDialogClose}>
                <DialogContent className="sm:max-w-[500px] p-0 overflow-hidden bg-gradient-to-br from-yellow-50 to-yellow-100 border-none">
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        className="p-6"
                    >
                        <DialogHeader className="mb-6">
                            <DialogTitle className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 to-yellow-500">
                                Welcome to the Aptitude Test
                            </DialogTitle>
                            <DialogDescription className="text-black mt-2">
                                Please provide your information to get started
                            </DialogDescription>
                        </DialogHeader>
                        <form onSubmit={handleUserInfoSubmit} className="space-y-5">
                            <div className="relative">
                                <Label htmlFor="name" className="text-sm font-medium text-gray-700 mb-1 block">
                                    Full Name
                                </Label>
                                <div className="relative">
                                    <UserCircle className="absolute left-3 top-1/2 transform -translate-y-1/2 text-yellow-500" size={18} />
                                    <Input 
                                        id="name" 
                                        name="name" 
                                        value={userInfo.name} 
                                        onChange={handleInputChange} 
                                        required 
                                        className="pl-10 border-gray-300 bg-white focus:ring-2 focus:ring-yellow-400 focus:border-yellow-500 transition-all"
                                        placeholder="Enter your full name"
                                    />
                                </div>
                            </div>
                            <div className="relative">
                                <Label htmlFor="email" className="text-sm font-medium text-gray-700 mb-1 block">
                                    Email Address
                                </Label>
                                <div className="relative">
                                    <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-yellow-500" size={18} />
                                    <Input 
                                        id="email" 
                                        name="email" 
                                        type="email" 
                                        value={userInfo.email} 
                                        onChange={handleInputChange} 
                                        required 
                                        className="pl-10 border-gray-300 bg-white focus:ring-2 focus:ring-yellow-400 focus:border-yellow-500 transition-all"
                                        placeholder="your.email@example.com"
                                    />
                                </div>
                            </div>
                            <div className="relative">
                                <Label htmlFor="mobile" className="text-sm font-medium text-gray-700 mb-1 block">
                                    Mobile Number
                                </Label>
                                <div className="relative">
                                    <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-yellow-500" size={18} />
                                    <Input 
                                        id="mobile" 
                                        name="mobile" 
                                        value={userInfo.mobile} 
                                        onChange={handleInputChange} 
                                        required 
                                        className="pl-10 border-gray-300 bg-white focus:ring-2 focus:ring-yellow-400 focus:border-yellow-500 transition-all"
                                        placeholder="Enter your mobile number"
                                    />
                                </div>
                            </div>
                            <div className="relative">
                                <Label htmlFor="employeeId" className="text-sm font-medium text-gray-700 mb-1 block">
                                    Employee ID
                                </Label>
                                <div className="relative">
                                    <Briefcase className="absolute left-3 top-1/2 transform -translate-y-1/2 text-yellow-500" size={18} />
                                    <Input 
                                        id="employeeId" 
                                        name="employeeId" 
                                        value={userInfo.employeeId} 
                                        onChange={handleInputChange} 
                                        required 
                                        className="pl-10 border-gray-300 bg-white focus:ring-2 focus:ring-yellow-400 focus:border-yellow-500 transition-all"
                                        placeholder="Enter your employee ID"
                                    />
                                </div>
                            </div>
                            <Button 
                                type="submit" 
                                disabled={isSubmitting} 
                                className="w-full bg-gradient-to-r from-yellow-400 to-yellow-500 hover:from-yellow-500 hover:to-yellow-500 text-white font-medium py-2.5 rounded-lg shadow-lg hover:shadow-xl transition-all duration-200 flex items-center justify-center gap-2"
                            >
                                {isSubmitting ? (
                                    <>Processing<div className="animate-spin ml-2 h-4 w-4 border-2 border-white border-t-transparent rounded-full"></div></>
                                ) : (
                                    <>Begin Test <ChevronRight size={18} /></>
                                )}
                            </Button>
                        </form>
                    </motion.div>
                </DialogContent>
            </Dialog>

            <Dialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
                <DialogContent className="max-w-md bg-white p-6 rounded-lg">
                    <DialogHeader>
                        <DialogTitle className="text-xl font-bold text-gray-800">Submit Your Test?</DialogTitle>
                        <DialogDescription className="text-gray-600 mt-2">
                            You've answered {Object.keys(selectedOptions).length} out of {test?.questions.length || 0} questions ({Math.round(completionPercentage)}%).
                        </DialogDescription>
                    </DialogHeader>
                    <div className="py-4">
                        <p className="text-gray-700 mb-4">Are you sure you want to submit your test? You won't be able to change your answers after submission.</p>
                        <div className="flex justify-end gap-3 mt-4">
                            <Button 
                                variant="outline" 
                                onClick={() => setShowConfirmDialog(false)}
                                className="border-gray-300 text-gray-700 hover:bg-gray-100"
                            >
                                Review Answers
                            </Button>
                            <Button 
                                onClick={handleSubmit} 
                                className="bg-green-500 hover:bg-green-600 text-white"
                            >
                                Submit Test
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            {isAuthenticated && test && !showUserForm && (
                <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 py-8 px-4 sm:px-6 lg:px-8">
                    <div className="max-w-5xl mx-auto">
                        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
                            <div>
                                <h1 className="text-3xl font-bold text-gray-800">{test.title}</h1>
                                <div className="flex items-center mt-2 text-gray-600">
                                    <Clock size={16} className="mr-1" />
                                    <span className="text-sm">{test.questions.length} Questions</span>
                                    <span className="mx-2">•</span>
                                    <span className="text-sm">Take your time</span>
                                </div>
                            </div>
                            <Badge variant="outline" className="bg-white px-3 py-1.5 text-blue-600 border border-blue-200 shadow-sm flex items-center gap-1.5">
                                <Brain size={16} />
                                <span>Aptitude Assessment</span>
                            </Badge>
                        </div>
                        
                        <Card className="shadow-xl rounded-xl overflow-hidden border-none bg-white">
                            <CardHeader className="bg-gradient-to-r from-blue-600 to-indigo-600 p-6 text-white">
                                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                                    <div>
                                        <CardTitle className="text-2xl font-bold tracking-tight">
                                            Question {currentQuestionIndex + 1} of {test.questions.length}
                                        </CardTitle>
                                        <CardDescription className="text-blue-100 mt-1">
                                            {isSubmitted ? 'Test completed' : 'Select the best answer for each question'}
                                        </CardDescription>
                                    </div>
                                    <div className="w-full sm:w-48">
                                        <div className="flex justify-between text-xs text-blue-100 mb-1">
                                            <span>Progress</span>
                                            <span>{Math.round(completionPercentage)}%</span>
                                        </div>
                                        <Progress 
                                            value={completionPercentage} 
                                            className="h-2 bg-blue-400/30" 
                                        />
                                    </div>
                                </div>
                            </CardHeader>
                            
                            <CardContent className="p-0">
                                <div className="flex flex-col md:flex-row h-full">
                                    <div className="hidden md:block w-20 lg:w-24 bg-gray-50 border-r border-gray-200">
                                        <ScrollArea className="h-96">
                                            <div className="p-3 flex flex-col items-center gap-2">
                                                {test.questions.map((_, index) => (
                                                    <Button
                                                        key={index}
                                                        variant={currentQuestionIndex === index ? "default" : 
                                                            selectedOptions[test.questions[index]._id] ? "outline" : "ghost"}
                                                        className={`w-12 h-12 rounded-lg font-medium ${
                                                            currentQuestionIndex === index 
                                                                ? 'bg-blue-600 text-white' 
                                                                : selectedOptions[test.questions[index]._id]
                                                                ? 'bg-blue-50 border-blue-200 text-blue-700'
                                                                : 'text-gray-600'
                                                        }`}
                                                        onClick={() => setCurrentQuestionIndex(index)}
                                                        disabled={isSubmitted}
                                                    >
                                                        {index + 1}
                                                    </Button>
                                                ))}
                                            </div>
                                        </ScrollArea>
                                    </div>
                                    
                                    <div className="flex-1">
                                        <div className="p-6">
                                            <motion.div
                                                key={currentQuestionIndex}
                                                initial={{ opacity: 0, x: 10 }}
                                                animate={{ opacity: 1, x: 0 }}
                                                transition={{ duration: 0.3 }}
                                            >
                                                <div className="flex items-center mb-2">
                                                    <Lightbulb className="text-yellow-500 mr-2" size={24} />
                                                    <h3 className="text-xl font-semibold text-gray-800">
                                                        {test.questions[currentQuestionIndex].question}
                                                    </h3>
                                                </div>
                                                
                                                <Separator className="my-4" />
                                                
                                                <div className="mt-6 space-y-3">
                                                    {test.questions[currentQuestionIndex].options.map((option) => (
                                                        <motion.div
                                                            key={option._id}
                                                            whileHover={{ scale: 1.01 }}
                                                            className={`rounded-lg border ${
                                                                selectedOptions[test.questions[currentQuestionIndex]._id] === option._id
                                                                    ? 'border-blue-500 bg-blue-50'
                                                                    : 'border-gray-200 hover:border-blue-300 bg-white'
                                                            } transition-all duration-200 p-4 cursor-pointer`}
                                                            onClick={() => !isSubmitted && handleOptionToggle(test.questions[currentQuestionIndex]._id, option._id)}
                                                        >
                                                            <div className="flex items-center gap-3">
                                                                <Checkbox
                                                                    id={`${test.questions[currentQuestionIndex]._id}-${option._id}`}
                                                                    checked={selectedOptions[test.questions[currentQuestionIndex]._id] === option._id}
                                                                    onCheckedChange={() => !isSubmitted && handleOptionToggle(test.questions[currentQuestionIndex]._id, option._id)}
                                                                    className="h-5 w-5 rounded-full data-[state=checked]:bg-blue-500 data-[state=checked]:text-white border-gray-300"
                                                                    disabled={isSubmitted}
                                                                />
                                                                <Label
                                                                    htmlFor={`${test.questions[currentQuestionIndex]._id}-${option._id}`}
                                                                    className="text-lg text-gray-700 cursor-pointer font-medium"
                                                                >
                                                                    {option.text}
                                                                </Label>
                                                            </div>
                                                        </motion.div>
                                                    ))}
                                                </div>
                                            </motion.div>
                                            
                                            <div className="md:hidden mt-8">
                                                <p className="text-sm text-gray-500 mb-2">Question Navigation</p>
                                                <div className="grid grid-cols-5 sm:grid-cols-8 gap-2">
                                                    {test.questions.map((_, index) => (
                                                        <Button
                                                            key={index}
                                                            variant={currentQuestionIndex === index ? "default" : 
                                                                selectedOptions[test.questions[index]._id] ? "outline" : "ghost"}
                                                            className={`h-10 w-10 p-0 rounded-lg font-medium ${
                                                                currentQuestionIndex === index 
                                                                    ? 'bg-blue-600 text-white' 
                                                                    : selectedOptions[test.questions[index]._id]
                                                                    ? 'bg-blue-50 border-blue-200 text-blue-700'
                                                                    : 'text-gray-600'
                                                            }`}
                                                            onClick={() => setCurrentQuestionIndex(index)}
                                                            disabled={isSubmitted}
                                                        >
                                                            {index + 1}
                                                        </Button>
                                                    ))}
                                                </div>
                                            </div>
                                            
                                            <div className="flex justify-between mt-10">
                                                <Button
                                                    onClick={handlePrevQuestion}
                                                    disabled={currentQuestionIndex === 0 || isSubmitted}
                                                    className="bg-gray-100 text-gray-700 hover:bg-gray-200 border border-gray-300"
                                                >
                                                    Previous
                                                </Button>
                                                
                                                {currentQuestionIndex < test.questions.length - 1 ? (
                                                    <Button
                                                        onClick={handleNextQuestion}
                                                        disabled={isSubmitted}
                                                        className="bg-blue-500 hover:bg-blue-600 text-white"
                                                    >
                                                        Next Question
                                                    </Button>
                                                ) : (
                                                    !isSubmitted ? (
                                                        <Button
                                                            onClick={() => setShowConfirmDialog(true)}
                                                            className="bg-green-500 hover:bg-green-600 text-white"
                                                        >
                                                            Finish Test <Send size={16} className="ml-2" />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            onClick={handleViewResults}
                                                            className="bg-indigo-500 hover:bg-indigo-600 text-white"
                                                        >
                                                            View Results <Award size={16} className="ml-2" />
                                                        </Button>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                            
                            <div className="bg-gray-50 border-t border-gray-200 p-4">
                                <div className="flex flex-wrap justify-between items-center gap-4">
                                    <div className="flex items-center text-sm text-gray-600">
                                        <LifeBuoy size={16} className="mr-1.5" />
                                        <span>Need help? Contact support</span>
                                    </div>
                                    
                                    {isSubmitted ? (
                                        <div className="flex items-center text-green-600">
                                            <CheckCheck size={18} className="mr-1.5" />
                                            <span className="font-medium">Test successfully submitted</span>
                                        </div>
                                    ) : (
                                        <div className="flex items-center gap-4">
                                            <div className="text-sm text-gray-600">
                                                <span className="font-medium">{Object.keys(selectedOptions).length}</span> of {test.questions.length} answered
                                            </div>
                                            {!isSubmitted && currentQuestionIndex === test.questions.length - 1 && (
                                                <Button
                                                    onClick={() => setShowConfirmDialog(true)}
                                                    className="bg-green-500 hover:bg-green-600 text-white hidden sm:flex"
                                                >
                                                    Submit Test <ArrowRight size={16} className="ml-1.5" />
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            )}
        </>
    );
};

export default SingleAptitudeTest;