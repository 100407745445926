
import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { NotificationContainer, NotificationManager } from "react-notifications";
// import "react-notifications/lib/notifications.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { Plus, Trash } from "lucide-react";
import { Button } from "@/components/ui/button"; // Assuming shadcn/ui is used
import { toast } from "sonner";

const api = `${import.meta.env.VITE_APP_API_URL}`;

interface FormDataObject {
  [key: string]: string | File | Blob | null;
}
interface BlogContent {
  tablename: string;
  heading: string;
  images: File | null;
  discription: string;
  discription2: string;
}

const AddBlog: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem("token_tr") || "");

  const [title, setTitle] = useState("");
  const [keywords, setKeywords] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState<File | null>(null);
  const [imagediscription, setImageDescription] = useState("");
  const [tableimage, setTableImage] = useState<File | null>(null);
  const [sumeryhead, setSumeryHead] = useState("");
  const [sumerydiscription, setSumeryDescription] = useState("");
  const [status, setStatus] = useState("Approved");
  const [category, setCategory] = useState("");

  const [blogimg, setBlogImage] = useState<File | null>(null);
  const [tablename, setTableName] = useState("");
  const [heading, setHeading] = useState("");
  const [contentDescription, setContentDescription] = useState("");
  const [outDescription, setOutDescription] = useState("");
  const [AllBlogContent, setAllBlogContent] = useState<BlogContent[]>([]);

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCategory(e.target.value);
  };

  const AddBlogContent =async (val:string) => {
    setIsLoading(true);
    

      console.log(AllBlogContent,"all")

  
  try {
    await axios.post(
      `${import.meta.env.VITE_APP_API_URL}/admin/blog_content`, 
      {AllBlogContent,val},
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token}`,
        },
      }
    );
  
    setBlogImage(null);
    setContentDescription("");
    setHeading("");
    setTableName("");
    setOutDescription("");
    setIsLoading(false);
    toast.success("Blog content added successfully");
  } catch (error) {
    toast.error("Error while posting the content");
    setIsLoading(false);
  }
  
    
      // toast.success("Successfully added");
   
  };

  const handelBlogStateUpdate=()=>{
  
      if (!tablename) {
        setIsLoading(false);
        toast.error("Please enter table heading name"); 
        return;
      }
      if (!contentDescription) {
        setIsLoading(false);
        toast.error("Please enter description");
        return;
      }
      if (!heading) {
        setIsLoading(false);
        toast.error("Please enter heading"); 
        return;
      }
      const check = AllBlogContent.find(
        (ele) => ele.heading === heading && ele.tablename === tablename
      );
      if (check) {
        setIsLoading(false);
        toast.error("Already exists");
        return;
      }
      setAllBlogContent([
        ...AllBlogContent,
        { 
          tablename,
          heading,
          images: blogimg,
          discription: contentDescription,
          discription2: outDescription,
        },
      ]);
      setBlogImage(null);
      setContentDescription("");
      setHeading("");
      setTableName("");
      setOutDescription("");
  }

  const AddAllImagesContent = async (id: string) => {
    try {
      for (let i = 0; i < AllBlogContent.length; i++) {
        const formData = new FormData();
        formData.append("tablename", AllBlogContent[i].tablename);
        formData.append("heading", AllBlogContent[i].heading);
        formData.append("discription", AllBlogContent[i].discription);
        formData.append("discription2", AllBlogContent[i].discription2);
        if (AllBlogContent[i].images) {
          formData.append("images", AllBlogContent[i].images||"");
        }
        formData.append("id", id);
        //console.log(formData, "formm data")

        
      const formDataObject: FormDataObject  = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });

      //console.log(formDataObject, "data before submit")

        await axios.post(`${api}/admin/blog_content`, formData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
      }
      setIsLoading(false);
      toast.success("Successfully added all images and content");
      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (error) {
      console.log(error);
      toast.error("Failed to add images and content");
    }
  };

  const AddBlog = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("krywords", keywords);
      formData.append("discription", description);
      if (images) {
        formData.append("images", images);
      }
      formData.append("imagediscription", imagediscription);
      if (tableimage) {
        formData.append("tableimage", tableimage);
      }
      formData.append("sumeryhead", sumeryhead);
      formData.append("sumerydiscription", sumerydiscription);
      formData.append("status", status);
      formData.append("category", category);

      const formDataObject:FormDataObject  = {};
    formData.forEach((value, key) => {
      
      formDataObject[key] = value;
    });

    // console.log("Form Data before API Call:", formDataObject,"check");
    // console.log("All Blog Content:", AllBlogContent);

      const res = await axios.post(`${api}/admin/blog`, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 201) {
        AddAllImagesContent(res.data.data._id);
        AddBlogContent(res.data.data._id)
        toast.success("Blog created successfully!");
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.data.message === "Session Expired") {
          localStorage.removeItem("token_tr");
          localStorage.removeItem("mobile");
          toast.error("Session expired. Please log in again.");
          return (window.location.href = "/");
        }else{
          toast.error(error.response.data.message);
        }
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* <NotificationContainer /> */}
      <h2 className="text-2xl font-bold text-gray-800 mb-6">Add Blog</h2>

      {/* Blog Basic Info */}
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">Title *</label>
          <input
            type="text"
            placeholder="Enter blog title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Keywords *</label>
          <textarea
            placeholder="Enter keywords"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Short Description *</label>
          <textarea
            placeholder="Blog Short description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Category *</label>
          <select
            value={category}
            onChange={handleCategoryChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            <option value="">Select a category</option>
            <option value="digital-marketing">Digital Marketing</option>
            <option value="ui-ux-designing">UI/UX Designing</option>
            <option value="full-stack-web-development">Full-Stack Web Development</option>
            <option value="cyber-security">Cyber Security</option>
            <option value="degree">Degree</option>
            <option value="communication-skills">Communication Skills</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Main Image *</label>
          {images && (
            <img src={URL.createObjectURL(images)} alt="Main" className="w-24 h-auto rounded-lg mb-2" />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImages(e.target.files?.[0] || null)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Image Content Description *</label>
          <CKEditor
            editor={ClassicEditor}
            data={imagediscription}
            onChange={(_, editor) => {
              const data = editor.getData();
              setImageDescription(data);
            }}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Table Image *</label>
          {tableimage && (
            <img src={URL.createObjectURL(tableimage)} alt="Table" className="w-24 h-auto rounded-lg mb-2" />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setTableImage(e.target.files?.[0] || null)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Heading for Summary *</label>
          <input
            type="text"
            placeholder="Enter heading name"
            value={sumeryhead}
            onChange={(e) => setSumeryHead(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Summary Description *</label>
          <CKEditor
            editor={ClassicEditor}
            data={sumerydiscription}
            onChange={(_, editor) => {
              const data = editor.getData();
              setSumeryDescription(data);
            }}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Status *</label>
          <div className="flex gap-4">
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="status"
                value="Deactive"
                checked={status === "Deactive"}
                onChange={() => setStatus("Deactive")}
              />
              Deactive
            </label>
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="status"
                value="Approved"
                checked={status === "Approved"}
                onChange={() => setStatus("Approved")}
              />
              Approved
            </label>
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="status"
                value="Not-Approved"
                checked={status === "Not-Approved"}
                onChange={() => setStatus("Not-Approved")}
              />
              Not-Approved
            </label>
          </div>
        </div>
      </div>

      {/* Blog Contents */}
      <div className="mt-8">
        <h3 className="text-xl font-bold text-gray-800 mb-4">Blog Contents</h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Table of Content Name *</label>
            <input
              type="text"
              placeholder="Table of content name"
              value={tablename}
              onChange={(e) => setTableName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Heading Name *</label>
            <input
              type="text"
              placeholder="Enter blog content heading name"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Blog Image</label>
            {blogimg && (
              <img src={URL.createObjectURL(blogimg)} alt="Blog" className="w-24 h-auto rounded-lg mb-2" />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setBlogImage(e.target.files?.[0] || null)}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Description *</label>
            <CKEditor
              editor={ClassicEditor}
              data={contentDescription}
              onChange={(_, editor) => {
                const data = editor.getData();
                setContentDescription(data);
              }}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Out of Container Description</label>
            <CKEditor
              editor={ClassicEditor}
              data={outDescription}
              onChange={(_, editor) => {
                const data = editor.getData();
                setOutDescription(data);
              }}
            />
          </div>
          <Button onClick={handelBlogStateUpdate} className="bg-blue-500 hover:bg-blue-600 text-white">
            <Plus size={16} className="mr-2" /> Save Add Ons
          </Button>
        </div>
      </div>

      {/* Saved Add Ons */}
      <div className="mt-8">
        <h3 className="text-xl font-bold text-gray-800 mb-4">Saved Add Ons</h3>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3 border border-gray-200 text-left">S.No</th>
                <th className="p-3 border border-gray-200 text-left">Table of Content Name</th>
                <th className="p-3 border border-gray-200 text-left">Heading</th>
                <th className="p-3 border border-gray-200 text-left">Image</th>
                <th className="p-3 border border-gray-200 text-left">Description</th>
                <th className="p-3 border border-gray-200 text-left">Description2</th>
              </tr>
            </thead>
            <tbody>
              {AllBlogContent.map((ele, i) => (
                <tr key={i} className="hover:bg-gray-50 transition-colors">
                  <td className="p-3 border border-gray-200">{i + 1}</td>
                  <td className="p-3 border border-gray-200">{ele.tablename}</td>
                  <td className="p-3 border border-gray-200">{ele.heading}</td>
                  <td className="p-3 border border-gray-200">
                    {ele.images && <img src={URL.createObjectURL(ele.images)} alt="Blog" className="w-24 h-auto rounded-lg" />}
                  </td>
                  <td className="p-3 border border-gray-200">{parse(ele.discription)}</td>
                  <td className="p-3 border border-gray-200">{parse(ele.discription2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Button
          onClick={() => setAllBlogContent([])}
          className="mt-4 bg-red-500 hover:bg-red-600 text-white"
        >
          <Trash size={16} className="mr-2" /> Remove All
        </Button>
      </div>

      {/* Save and Reset Buttons */}
      <div className="mt-8 flex gap-4">
        <Button onClick={() => window.location.reload()} className="bg-gray-500 hover:bg-gray-600 text-white">
          Reset
        </Button>
        <Button onClick={AddBlog} className="bg-green-500 hover:bg-green-600 text-white" disabled={isLoading}>
          {isLoading ? "Saving..." : "Save Blog"}
        </Button>
      </div>
    </div>
  );
};

export default AddBlog;