import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";


interface Content {
  _id: string;
  title: string;
  description: string;
  level: string;
  duration: string;
  authorname: string;
  createdAt: string;
  file: {
    fileUrl: string;
  };
  example: string;
  codeSnippet: string;
  links: string[];
  tags: string[];
}

const ContentInfo: React.FC = () => {
  const api = `${import.meta.env.VITE_APP_API_URL}/admin/`;
  const { subject, titel } = useParams<{ subject: string; titel: string }>();
  const [content, setContent] = useState<Content | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [copyButtonText, setCopyButtonText] = useState("Copy");
  const [activeTab, setActiveTab] = useState("description");
  const navigate = useNavigate();

  const getContentDetails = async (): Promise<void> => {
    try {
      setLoading(true);
    const response = await fetch(`${api}searchCourseContentByTopic?query=${decodeURIComponent(titel || "")}`);

      // const response = await fetch(`${api}courses/${subject}/${titel}`);
      const data = await response.json();
    //console.log(data.data, "data")
      
      setContent(data.data[0]);
    } catch (error) {
      setError("Error fetching data");
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getContentDetails();
  }, [subject, titel]);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopyButtonText("Copied!");
    setTimeout(() => setCopyButtonText("Copy"), 1500);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-teal-50 to-green-50">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-teal-600"></div>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-600 text-center">{error}</div>;
  }

  if (!content) {
    return <div className="text-center">No content available</div>;
  }

  const {
    title,
    file,
    description,
    example,
    codeSnippet,
    links,
    tags,
    createdAt,
  } = content;

  return (<>
    

    <div className="min-h-screen bg-gradient-to-r from-teal-50 to-green-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        {/* Back Button */}
        <Button
          variant="ghost"
          className="mb-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size={16} className="mr-2" />
          Back to {subject}
        </Button>

        {/* Hero Section */}
        <div className="bg-white rounded-2xl shadow-xl overflow-hidden mb-8 transform transition-all hover:shadow-2xl">
          {file?.fileUrl && (
            <div className="relative h-96 w-full overflow-hidden">
              <img
                src={file.fileUrl}
                alt="Header"
                className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
              <div className="absolute bottom-0 left-0 right-0 p-8 text-white">
                <h1 className="text-4xl font-bold mb-2">{title}</h1>
                <p className="text-teal-200">
                  Published {new Date(createdAt).toLocaleDateString()}
                </p>
              </div>
            </div>
          )}

          {/* Navigation Tabs */}
          <div className="border-b border-gray-200">
            <nav className="flex space-x-8 px-6" aria-label="Tabs">
              {["description", "code", "resources"].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200 ${
                    activeTab === tab
                      ? "border-teal-500 text-teal-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  }`}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </nav>
          </div>

          <div className="p-8">
            {/* Content Sections */}
            <div className={`space-y-8 ${activeTab === "description" ? "block" : "hidden"}`}>
              <div className="prose prose-teal max-w-none">
                <h2 className="text-2xl font-bold text-teal-700 mb-4">Overview</h2>
                <div className="bg-white rounded-lg p-6 shadow-sm">
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </div>
              </div>
            </div>

            <div className={`space-y-8 ${activeTab === "code" ? "block" : "hidden"}`}>
              <div>
                <h2 className="text-2xl font-bold text-teal-700 mb-4">Example</h2>
                <div className="relative rounded-lg overflow-hidden">
                  <SyntaxHighlighter
                    language="javascript"
                    style={solarizedlight}
                    customStyle={{
                      borderRadius: "0.75rem",
                      padding: "1.5rem",
                      fontSize: "0.95rem",
                      backgroundColor: "#1a1a1a",
                    }}
                  >
                    {example}
                  </SyntaxHighlighter>
                </div>
              </div>

              <div>
                <h2 className="text-2xl font-bold text-teal-700 mb-4">Implementation</h2>
                <div className="relative rounded-lg overflow-hidden group">
                  <SyntaxHighlighter
                    language="javascript"
                    style={solarizedlight}
                    customStyle={{
                      borderRadius: "0.75rem",
                      padding: "1.5rem",
                      fontSize: "0.95rem",
                      backgroundColor: "#1a1a1a",
                    }}
                  >
                    {codeSnippet}
                  </SyntaxHighlighter>
                  <button
                    onClick={() => copyToClipboard(codeSnippet)}
                    className="absolute top-4 right-4 bg-teal-600/90 backdrop-blur-sm text-white px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200 hover:bg-teal-700 opacity-0 group-hover:opacity-100"
                  >
                    {copyButtonText}
                  </button>
                </div>
              </div>
            </div>

            <div className={`space-y-8 ${activeTab === "resources" ? "block" : "hidden"}`}>
              <div>
                <h2 className="text-2xl font-bold text-teal-700 mb-4">Related Links</h2>
                <ul className="space-y-3">
                  {links?.map((link, index) => (
                    <li key={index} className="bg-white rounded-lg shadow-sm transition-all hover:shadow-md">
                      <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block p-4 text-teal-600 hover:text-teal-800"
                      >
                        {link}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                <h2 className="text-2xl font-bold text-teal-700 mb-4">Tags</h2>
                <div className="flex flex-wrap gap-2">
                  {tags?.map((tag, index) => (
                    <span
                      key={index}
                      className="px-4 py-2 rounded-full text-sm font-medium bg-gradient-to-r from-teal-500 to-green-500 text-white shadow-sm hover:shadow-md transition-shadow duration-200"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </> );
};

export default ContentInfo;