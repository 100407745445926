import { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { 
  CreditCard, 
  ArrowRight, 
  User, 
  Mail, 
  Phone, 
  MapPin, 
  CheckCircle2, 
  Copy, 
  Check,
  Briefcase
} from 'lucide-react';
import { toast } from "sonner";
import axios from "axios";

// Define types for better type safety
interface FormData {
  name: string;
  email: string;
  mobile: string;
  city: string;
  message: string;
  role: string;
  paymentStatus?: boolean;
}

interface PaymentVerificationData {
  order_creation_id: string;
  userId: string;
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
  amount: number;
  currency: string;
}

// Separate the localStorage logic for better reusability
const getFromLocal = (): FormData | null => {
  try {
    const jsonData = localStorage.getItem('formData');
    if (!jsonData) {
      console.warn('No form data found in localStorage');
      return null;
    }
    return JSON.parse(jsonData);
  } catch (error) {
    console.error('Error parsing form data:', error);
    return null;
  }
};

const api = `${import.meta.env.VITE_APP_API_URL}/payment/`;

const loadRazorpayScript = (): Promise<boolean> => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};

export default function PaymentModal() {
  const [isOpen, setIsOpen] = useState(true);
  const [success, setSuccess] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [userDetails, setUserDetails] = useState<FormData | null>(null);

  useEffect(() => {
    const data = getFromLocal();
    if (data) {
      setUserDetails(data);
    }
  }, []);

  
  

  const handleClose = () => {
    setIsOpen(false);
  };

  const copyPaymentId = () => {
    if (success) {
      navigator.clipboard.writeText(success);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      toast.success("Payment ID copied to clipboard");
    }
  };

  const handlePaymentContinuation = async () => {
    setIsLoading(true);
    setError("");

    try {
      const scriptLoaded = await loadRazorpayScript();
      if (!scriptLoaded) {
        throw new Error("Razorpay SDK failed to load");
      }

      const formData = userDetails || getFromLocal();
      if (!formData) {
        throw new Error("No user details found");
      }

      const orderResponse = await axios.post(
        `${api}create-razorpay-order`,
        formData
      );

      if (!orderResponse.data?.order) {
        throw new Error("Failed to create order");
      }

      const { amount, id } = orderResponse.data.order;
      const { _id } = orderResponse.data.user;

      const options = {
        key: "rzp_live_Y8Pe5kKuBA8pNN",
        amount: amount.toString(),
        currency: "INR",
        name: "Skillyards Versatility",
        description: "Workshop Registration Payment",
        image: "/Web_logo/front_weblogo.jpeg",
        order_id: id,
        handler: async (response: any) => {
          try {
            const verificationData: PaymentVerificationData = {
              order_creation_id: id,
              userId: _id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              amount: 99,
              currency: "INR"
            };

            const paymentResult = await axios.post(
              `${api}verify-payment`,
              { orderData: verificationData }
            );

            if (paymentResult.data?.success) {
              setSuccess(paymentResult.data?.paymentId);
              toast.success("Payment successful!");
              
              // Update localStorage with payment status
              if (formData) {
                localStorage.setItem('formData', JSON.stringify({
                  ...formData,
                  paymentStatus: true
                }));
              }
            } else {
              throw new Error("Payment verification failed");
            }
          } catch (error) {
            console.error(error);
            setError("Payment verification failed. Please contact support.");
            toast.error("Payment verification failed");
          }
        },
        prefill: {
          name: formData.name,
          email: formData.email,
          contact: formData.mobile,
        },
        notes: {
          address: formData.city,
        },
        theme: {
          color: "#FFB93F",
        }
      };

      const paymentObject = new (window as any).Razorpay(options);
      paymentObject.open();
    } catch (error: any) {
      console.error(error);
      setError(error.message || "Something went wrong. Please try again.");
      toast.error("Failed to initialize payment");
    } finally {
      setIsLoading(false);
    }
  };

  if (!userDetails) {
    return null;
  }

  const userFields = [
    { icon: User, label: 'Name', value: userDetails.name },
    { icon: Mail, label: 'Email', value: userDetails.email },
    { icon: Phone, label: 'Phone', value: userDetails.mobile },
    { icon: MapPin, label: 'City', value: userDetails.city },
    { icon: Briefcase, label: 'Role', value: userDetails.role || 'Not specified' }
  ];

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
  <DialogContent className="sm:max-w-[600px] p-4 bg-white rounded-lg shadow-lg">
    <div className="max-h-[80vh] overflow-y-auto">
      {success ? (
        <div className="p-4 flex flex-col items-center text-center">
          <div className="w-14 h-14 flex items-center justify-center bg-green-100 rounded-full">
            <CheckCircle2 size={32} className="text-green-500" />
          </div>
          
          <div className="mt-4 space-y-2">
            <h3 className="text-xl font-semibold text-gray-900">Payment Successful!</h3>
            <p className="text-sm text-gray-600">Thank you for registering for our workshop</p>
          </div>

          <div className="w-full mt-4">
            <div className="bg-gray-50 rounded-md p-3">
              <div className="text-xs font-medium text-gray-500 mb-2">Payment ID</div>
              <div className="flex items-center justify-between p-2 bg-white rounded-md border border-gray-100">
                <code className="text-xs text-gray-700">{success}</code>
                <Button
                  onClick={copyPaymentId}
                  size="sm"
                  variant="ghost"
                  className={`h-8 ${copied ? 'text-green-600' : 'text-gray-600'}`}
                >
                  {copied ? <Check size={14} /> : <Copy size={14} />}
                </Button>
              </div>
            </div>
          </div>

          <p className="text-xs text-gray-500 mt-4">
            Our team will contact you shortly with further details
          </p>
        </div>
      ) : (
        <div className="space-y-4">
          <div className="text-center">
            <div className="w-12 h-12 mx-auto flex items-center justify-center bg-yellow-100 rounded-full">
              <CreditCard className="w-6 h-6 text-yellow-600" />
            </div>
            <h2 className="text-lg font-semibold text-gray-900 mt-2">Complete Registration</h2>
            <p className="text-xs text-gray-500">Review your details and proceed to payment</p>
          </div>

          {error && (
            <div className="bg-red-50 border-l-4 border-red-500 p-3 rounded-md text-xs text-red-600">
              {error}
            </div>
          )}

          <div className="grid grid-cols-2 gap-3">
            {userFields.map(({ icon: Icon, label, value }) => (
              <div key={label} className="flex items-start gap-3 p-3 bg-white rounded-md border border-gray-100">
                <Icon className="w-4 h-4 text-yellow-500 flex-shrink-0 mt-1" />
                <div className="flex-1 min-w-0">
                  <p className="text-xs text-gray-500">{label}</p>
                  <p className="text-sm font-medium text-gray-900 truncate">{value}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-col gap-3 pt-2">
            <Button
              onClick={handlePaymentContinuation}
              className="w-full bg-yellow-500 hover:bg-yellow-600 text-white font-medium h-10 rounded-lg flex items-center justify-center gap-2"
              disabled={isLoading}
            >
              {isLoading ? (
                "Processing..."
              ) : (
                <>
                  Pay Now
                  <ArrowRight className="w-4 h-4" />
                </>
              )}
            </Button>
            
            <button
              onClick={handleClose}
              className="text-xs text-gray-500 hover:text-gray-700"
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  </DialogContent>
</Dialog>
  
  );
}