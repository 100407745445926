
import React, { useEffect, useState } from "react";
import {  Edit, Trash, Plus, ChevronLeft, ChevronRight } from "lucide-react";

// import { NotificationContainer, NotificationManager } from "react-notifications";
// import "react-notifications/lib/notifications.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

import { Button } from "@/components/ui/button"; // Assuming shadcn/ui is used
import checkAuth from "../authentication/auth";
import { toast } from "sonner";

const api = `${import.meta.env.VITE_APP_API_URL}`;
// const api = "http://localhost:8000/api";

interface Blog {
  _id: string;
  images: string;
  title: string;
  imagediscription: string;
  createdAt: string;
  status: string;
}

const AdminBlog: React.FC = () => {
  const token = checkAuth()||"";
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(blogs.length / itemsPerPage);
  const [currentItems, setCurrentItems] = useState<Blog[]>([]);

  const expireSession = (message: string) => {
    if (message === "Session Expired") {
      localStorage.removeItem("token_tr");
      localStorage.removeItem("name");
      toast.error("Session expired. Please log in again.");
      setTimeout(() => {
        window.location.assign("/admin/login");
      }, 500);
    }else{
      toast.error(message);
    }
  };

  const getBlogs = async () => {
    try {
      const res = await axios(`${api}/admin/blog`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });
      if (res.status === 200) {
        setBlogs(res.data.data);
        toast.success("Blogs fetched successfully!");
      }
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error) && error.response) {
        expireSession(error.response.data.message);
      } else {
        expireSession("Something went wrong");
      }
      setBlogs([]);
    }
  };

  useEffect(() => {
    getBlogs();
  }, [token]);

  useEffect(() => {
    setCurrentItems(blogs.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
  }, [blogs, currentPage]);

  // const exportToTXT = (data: Blog[]) => {
  //   const txtContent = data.map((row) => JSON.stringify(row, null, 2)).join("\n\n");
  //   const blob = new Blob([txtContent], { type: "text/plain;charset=utf-8" });
  //   saveAs(blob, "blogs.txt");
  // };

  // const exportToCSV = (data: Blog[]) => {
  //   const csvRows = [];
  //   const headers = Object.keys(data[0]);
  //   csvRows.push(headers.join(","));
  //   for (const row of data) {
  //     const values = headers.map((header) => {
  //       const val = row[header as keyof Blog];
  //       return typeof val === "string" ? `"${val}"` : val;
  //     });
  //     csvRows.push(values.join(","));
  //   }
  //   const csvContent = csvRows.join("\n");
  //   const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  //   saveAs(blob, "blogs.csv");
  // };

  // const exportToExcel = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(blogs);
  //   const workbook = { Sheets: { Sheet1: worksheet }, SheetNames: ["Sheet1"] };
  //   const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  //   const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  //   saveAs(blob, "blogs.xlsx");
  // };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleDelete = async (id: string) => {
    const userConfirmed = window.confirm("Are you sure you wish to delete this post?");
    if (userConfirmed) {
      try {
        const res = await axios.delete(`${api}/admin/blog/${id}`, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        });
        if (res.status === 200) {
          toast.success("Post deleted successfully!");
         // NotificationManager.success("Post deleted successfully");
          const updatedBlogs = blogs.filter((blog) => blog._id !== id);
          setBlogs(updatedBlogs);
        }
      } catch (error) {
        console.error(error);
        toast.error("Failed to delete the post.");
        //NotificationManager.error("Failed to delete the post");
      }
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      {/* <NotificationContainer /> */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">Blogs</h2>
        <Button onClick={() => navigate("/app/add-blog")} className="bg-yellow-400 hover:bg-yellow-500 text-white">
          <Plus size={20} className="mr-2" /> Add Blog
        </Button>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-yellow-400 text-white">
              <th className="p-3 border border-gray-200 text-left">S.No</th>
              <th className="p-3 border border-gray-200 text-left">Image</th>
              <th className="p-3 border border-gray-200 text-left">Title</th>
              <th className="p-3 border border-gray-200 text-left">Description</th>
              <th className="p-3 border border-gray-200 text-left">Date</th>
              <th className="p-3 border border-gray-200 text-left">Status</th>
              <th className="p-3 border border-gray-200 text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((blog, index) => (
              <tr key={blog._id} className="hover:bg-gray-50 transition-colors">
                <td className="p-3 border border-gray-200">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                <td className="p-3 border border-gray-200">
                  {blog.images && <img src={blog.images} alt="Blog" className="w-24 h-auto rounded-lg" />}
                </td>
                <td className="p-3 border border-gray-200">{blog.title}</td>
                <td className="p-3 border border-gray-200">{blog.imagediscription}</td>
                <td className="p-3 border border-gray-200">{moment(blog.createdAt).format("lll")}</td>
                <td className="p-3 border border-gray-200">{blog.status}</td>
                <td className="p-3 border border-gray-200">
                  <button
                    onClick={() => navigate("/app/update-blog", { state: blog })}
                    className="text-blue-500 hover:text-blue-600"
                  >
                    <Edit size={20} />
                  </button>
                  <button onClick={() => handleDelete(blog._id)} className="text-red-500 hover:text-red-600 ml-2">
                    <Trash size={20} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {blogs.length === 0 && <p className="text-gray-600 text-center py-4">No matching records found</p>}

      {/* Pagination */}
      <div className="flex justify-center items-center gap-2 mt-6">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="p-2 hover:bg-gray-100 rounded-full disabled:opacity-50"
        >
          <ChevronLeft size={20} />
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={`p-2 px-4 rounded-full ${
              currentPage === index + 1 ? "bg-yellow-400 text-white" : "hover:bg-gray-100"
            }`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="p-2 hover:bg-gray-100 rounded-full disabled:opacity-50"
        >
          <ChevronRight size={20} />
        </button>
      </div>
    </div>
  );
};

export default AdminBlog;