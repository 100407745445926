
import React from "react";

interface Student {
  id: number;
  name: string;
  course: string;
}

const StudentList: React.FC = () => {
  // Replace with actual data fetch logic
  const students: Student[] = [];

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mt-3">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">Student List</h2>
      {students.length > 0 ? (
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="p-3 border border-gray-200 bg-yellow-400 text-white text-left">
                ID
              </th>
              <th className="p-3 border border-gray-200 bg-yellow-400 text-white text-left">
                Name
              </th>
              <th className="p-3 border border-gray-200 bg-yellow-400 text-white text-left">
                Course
              </th>
            </tr>
          </thead>
          <tbody>
            {students.map((student) => (
              <tr key={student.id} className="hover:bg-gray-50 transition-colors">
                <td className="p-3 border border-gray-200 bg-gray-50 text-gray-700">
                  {student.id}
                </td>
                <td className="p-3 border border-gray-200 bg-gray-50 text-gray-700">
                  {student.name}
                </td>
                <td className="p-3 border border-gray-200 bg-gray-50 text-gray-700">
                  {student.course}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-gray-600 text-center py-4">No matching records found</p>
      )}
    </div>
  );
};

export default StudentList;