import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs';
import {
  Code2,
  FileCode,
  AlertCircle,
  CheckCircle,
  Search,
  ChevronDown,
  ChevronUp,
  Download,
} from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import {  oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface AnalysisResult {
  file: string;
  analysis: string;
  score: number;
}

interface AnalysisData {
  message: string;
  data: AnalysisResult[];
}

const AnalysisResults: React.FC = () => {
  const location = useLocation();
  const { analysisResults, templateType = 'general' } = (location.state || {}) as {
    analysisResults?: AnalysisData;
    templateType?: string;
  };
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState<'file' | 'score'>('file');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [processedResults, setProcessedResults] = useState<AnalysisResult[]>([]);

  useEffect(() => {
    if (analysisResults?.data) {
      const resultsMap = new Map<string, AnalysisResult>();
      analysisResults.data.forEach(result => {
        const { file, analysis, score } = result;
        if (resultsMap.has(file) && !analysis.trim()) return;
        if (!resultsMap.has(file) || analysis.trim()) {
          resultsMap.set(file, { file, analysis, score });
        }
      });
      setProcessedResults(Array.from(resultsMap.values()));
    }
  }, [analysisResults]);

  if (!analysisResults) {
    return (
      <div className="flex flex-col items-center justify-center h-screen p-4">
        <AlertCircle className="h-12 w-12 text-red-500 mb-4" />
        <h1 className="text-xl font-semibold text-center">No analysis results available.</h1>
        <p className="text-gray-500 mt-2 text-center">Please analyze a repository first.</p>
      </div>
    );
  }

  const getTemplateTitle = () => {
    switch (templateType) {
      case 'performance':
        return 'Performance Analysis';
      case 'security':
        return 'Security Audit';
      default:
        return 'Code Analysis';
    }
  };

  const toggleSort = (criteria: 'file' | 'score') => {
    if (sortBy === criteria) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(criteria);
      setSortDirection('asc');
    }
  };

  const filteredResults = processedResults.filter(result =>
    result.file.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (result.analysis && result.analysis.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const sortedAndFilteredResults = [...filteredResults].sort((a, b) => {
    if (sortBy === 'file') {
      return sortDirection === 'asc' ? a.file.localeCompare(b.file) : b.file.localeCompare(a.file);
    } else {
      return sortDirection === 'asc' ? a.score - b.score : b.score - a.score;
    }
  });

  const goodResults = sortedAndFilteredResults.filter(r => r.score >= 7);
  const okayResults = sortedAndFilteredResults.filter(r => r.score >= 4 && r.score < 7);
  const poorResults = sortedAndFilteredResults.filter(r => r.score < 4);

  const handleExportResults = () => {
    const jsonStr = JSON.stringify(processedResults, null, 2);
    const dataUri = `data:application/json;charset=utf-8,${encodeURIComponent(jsonStr)}`;
    const link = document.createElement('a');
    link.download = `code-analysis-${new Date().toISOString().split('T')[0]}.json`;
    link.href = dataUri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getOverallScore = () => {
    if (processedResults.length === 0) return 0;
    return Math.round(processedResults.reduce((acc, curr) => acc + curr.score, 0) / processedResults.length * 10) / 10;
  };

  const overallScore = getOverallScore();

  return (
    <div className="container mx-auto py-6 px-4">
      <Card className="shadow-lg">
        <CardHeader className="bg-slate-50 border-b">
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
            <div>
              <CardTitle className="text-2xl flex items-center gap-2">
                <Code2 className="h-6 w-6" />
                {getTemplateTitle()}
              </CardTitle>
              <CardDescription>
                {processedResults.length} files analyzed | Overall Score:
                <Badge className={`ml-2 ${
                  overallScore >= 7 ? 'bg-green-100 text-green-800' :
                  overallScore >= 4 ? 'bg-yellow-100 text-yellow-800' :
                  'bg-red-100 text-red-800'
                }`}>
                  {overallScore}/10
                </Badge>
              </CardDescription>
            </div>
            <div className="flex gap-2 w-full sm:w-auto">
              <div className="relative w-full sm:w-64">
                <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
                <Input
                  placeholder="Search files..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="pl-8"
                />
              </div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button size="icon" variant="outline" onClick={handleExportResults}>
                      <Download className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Export analysis results</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        </CardHeader>
        <CardContent className="p-0">
          <div className="p-4 bg-slate-100 flex justify-between items-center text-sm">
            <div className="flex gap-2">
              <div>Sort by:</div>
              <button
                onClick={() => toggleSort('file')}
                className="flex items-center font-medium hover:text-blue-700"
              >
                File Name
                {sortBy === 'file' && (
                  sortDirection === 'asc' ? <ChevronUp className="h-4 w-4 ml-1" /> : <ChevronDown className="h-4 w-4 ml-1" />
                )}
              </button>
              <span>|</span>
              <button
                onClick={() => toggleSort('score')}
                className="flex items-center font-medium hover:text-blue-700"
              >
                Score
                {sortBy === 'score' && (
                  sortDirection === 'asc' ? <ChevronUp className="h-4 w-4 ml-1" /> : <ChevronDown className="h-4 w-4 ml-1" />
                )}
              </button>
            </div>
            <div className="hidden sm:flex gap-2">
              <Badge variant="outline" className="bg-green-100">Good: {goodResults.length}</Badge>
              <Badge variant="outline" className="bg-yellow-100">Okay: {okayResults.length}</Badge>
              <Badge variant="outline" className="bg-red-100">Poor: {poorResults.length}</Badge>
            </div>
          </div>

          <Tabs defaultValue="all" className="w-full">
            <TabsList className="grid grid-cols-4 w-full">
              <TabsTrigger value="all">All ({sortedAndFilteredResults.length})</TabsTrigger>
              <TabsTrigger value="good">Good ({goodResults.length})</TabsTrigger>
              <TabsTrigger value="okay">Okay ({okayResults.length})</TabsTrigger>
              <TabsTrigger value="poor">Poor ({poorResults.length})</TabsTrigger>
            </TabsList>

            <TabsContent value="all" className="m-0">
              <ScrollArea className="h-[60vh]">
                <ResultsList results={sortedAndFilteredResults} />
              </ScrollArea>
            </TabsContent>
            <TabsContent value="good" className="m-0">
              <ScrollArea className="h-[60vh]">
                <ResultsList results={goodResults} />
              </ScrollArea>
            </TabsContent>
            <TabsContent value="okay" className="m-0">
              <ScrollArea className="h-[60vh]">
                <ResultsList results={okayResults} />
              </ScrollArea>
            </TabsContent>
            <TabsContent value="poor" className="m-0">
              <ScrollArea className="h-[60vh]">
                <ResultsList results={poorResults} />
              </ScrollArea>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  );
};

const ResultsList: React.FC<{ results: AnalysisResult[] }> = ({ results }) => {
  if (results.length === 0) {
    return (
      <div className="p-8 text-center">
        <p className="text-gray-500">No results match your criteria.</p>
      </div>
    );
  }

  return (
    <div className="divide-y">
      {results.map((result, index) => (
        <ResultItem key={`${result.file}-${index}`} result={result} />
      ))}
    </div>
  );
};

// Helper to detect code sections in analysis text
const isCodeBlock = (text: string): boolean => {
  // Check if the text contains common code patterns
  const codePatterns = [
    /function\s+\w+\s*\(/,  // function declarations
    /const\s+\w+\s*=/,      // const declarations
    /let\s+\w+\s*=/,        // let declarations
    /var\s+\w+\s*=/,        // var declarations
    /import\s+.*from/,      // import statements
    /class\s+\w+/,          // class declarations
    /if\s*\(.*\)\s*\{/,     // if statements
    /for\s*\(.*\)\s*\{/,    // for loops
    /^\s*<.*>\s*$/,         // HTML tags
    /^\s*\w+\s*\(\)\s*\{/   // method declarations
  ];
  
  return codePatterns.some(pattern => pattern.test(text));
};

const ResultItem: React.FC<{ result: AnalysisResult }> = ({ result }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCodeContent, setIsCodeContent] = useState(false);

  useEffect(() => {
    // Determine if the analysis content appears to be code
    if (result.analysis) {
      setIsCodeContent(isCodeBlock(result.analysis));
    }
  }, [result.analysis]);

  const getScoreColor = (score: number) => {
    if (score >= 7) return 'bg-green-100 text-green-800';
    if (score >= 4) return 'bg-yellow-100 text-yellow-800';
    return 'bg-red-100 text-red-800';
  };

  const getScoreIcon = (score: number) => {
    if (score >= 7) return <CheckCircle className="h-5 w-5 text-green-600" />;
    if (score >= 4) return <AlertCircle className="h-5 w-5 text-yellow-600" />;
    return <AlertCircle className="h-5 w-5 text-red-600" />;
  };

  const renderAnalysisContent = () => {
    if (!result.analysis || result.analysis.trim() === '') {
      return (
        <div className="italic text-gray-500">
          No detailed analysis available for this file.
        </div>
      );
    }

    // If the content appears to be code, use syntax highlighting
    if (isCodeContent) {
      return (
        <div className="border rounded-md overflow-hidden">
          <SyntaxHighlighter
            language="js" 
            style={oneDark}
            customStyle={{ 
              margin: 0,
              borderRadius: '0.375rem',
              maxHeight: '400px'
            }}
            wrapLines={true}
            showLineNumbers={true}
          >
            {result.analysis.trim()}
          </SyntaxHighlighter>
        </div>
      );
    }
    
    // Otherwise, render as formatted text
    return (
      <div className="bg-slate-50 p-4 rounded-md text-sm overflow-x-auto whitespace-pre-wrap border ">
<SyntaxHighlighter language="js"  style={oneDark}>
  {result.analysis.replace(/\./g, ".\n")}
</SyntaxHighlighter>
      </div>
    );
  };

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen}>
      <CollapsibleTrigger className="flex justify-between items-center w-full p-4 hover:bg-slate-50">
        <div className="flex items-center gap-3">
          <FileCode className="h-5 w-5 text-blue-600" />
          <div className="text-left">
            <div className="font-medium">{result.file}</div>
            <div className="text-sm text-gray-500 truncate max-w-xs sm:max-w-md">
              {result.analysis ?
                (result.analysis.substring(0, 60) + (result.analysis.length > 60 ? '...' : '')) :
                'No detailed analysis available'}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Badge className={getScoreColor(result.score)}>
            {result.score}/10
          </Badge>
          {isOpen ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="overflow-hidden">
        <div className="px-4 pb-4 pt-2">
          <div className="flex items-center gap-2 mb-3">
            {getScoreIcon(result.score)}
            <span className="font-medium">
              {result.score >= 7 ? 'Good quality code' :
               result.score >= 4 ? 'Room for improvement' :
               'Needs significant refactoring'}
            </span>
          </div>
          {renderAnalysisContent()}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};

export default AnalysisResults;