import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Helmet } from "react-helmet";

interface Blog {
  _id: string;
  title: string;
  description: string;
  images: string;
  imagediscription: string;
  category: string;
  authorname: string;
  content: {
    _id: string;
    heading: string;
    discription: string;
    discription2: string;
    tablename: string;
  }[];
  tableimage: string;
  krywords:string
}

const SingleBlogPage: React.FC = () => {
  const { titel } = useParams<{ titel: string }>();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [blog, setBlog] = useState<Blog | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [readTime, setReadTime] = useState<string>("");

  const api = `${import.meta.env.VITE_APP_API_URL}/`;

  // Calculate reading time
  const calculateReadingTime = (blog: Blog | null): string => {
    if (!blog) return "0 min read";

    const countWords = (text: string) => text.split(/\s+/).filter(Boolean).length;

    let totalWordCount = 0;

    if (blog.description) totalWordCount += countWords(blog.description);
    if (blog.imagediscription) totalWordCount += countWords(blog.imagediscription);

    if (blog.content && Array.isArray(blog.content)) {
      blog.content.forEach((section) => {
        if (section.discription) totalWordCount += countWords(section.discription);
        if (section.discription2) totalWordCount += countWords(section.discription2);
      });
    }

    const averageReadingSpeed = 200; // words per minute
    const readingTime = Math.ceil(totalWordCount / averageReadingSpeed);
    return `${readingTime} min read`;
  };

  // Fetch blog data
  const fetchBlogData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${api}user/getSingleblogs/${titel}?id=${id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch blog data");
      }
      const data = await response.json();
      // console.log(data,"data");
      
      setBlog(data.data);
    } catch (err) {
      setError(err instanceof Error ? err.message : " or occurred");
    } finally {
      setLoading(false);
      console.log('final');
      
    }
  };
  // console.log(blog,"blog");
  

  // Category colors
  const categoryColors: Record<string, string> = {
    "allcategory": "bg-cyan-400 text-gray-900",
    "Digital Marketing": "bg-blue-500 text-white",
    "UI/UX Designing": "bg-green-500 text-white",
    "Full-Stack Web Development": "bg-yellow-500 text-gray-900",
    "Cybersecurity": "bg-red-500 text-white",
    "Degree": "bg-purple-500 text-white",
    "Communication Skills": "bg-orange-500 text-white",
  };

  useEffect(() => {
    fetchBlogData();
  }, [titel]);

  useEffect(() => {
    if (blog) {
      setReadTime(calculateReadingTime(blog));
    }
  }, [blog]);

  if (error) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-100">
        <p className="text-red-500 text-lg">{error}</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-100">
        loading...
      </div>
    );
  }
 

  return (<>

  {/* Dynamic Helmet Tag */}
  {blog && (
        <Helmet>
          <title>{blog.title}</title>
          <meta name="description" content={blog.description} />
          <meta name="keywords" content={blog.krywords} />
          
          {/* Dynamic Canonical Tag */}
          <link 
            rel="canonical" 
            href={`https://skillyards.com/blogs/${titel}?id=${id}`} 
          />

          {/* Open Graph Meta Tags */}
          <meta property="og:title" content={blog.title} />
          <meta property="og:description" content={blog.description} />
          <meta property="og:image" content={blog.images} />
          <meta property="og:url" content={`https://skillyards.com/blogs/${titel}?id=${id}`} />
          <meta property="og:type" content="article" />
        </Helmet>
      )}

       
  
  

    <div className="bg-gray-50 min-h-screen py-12">
      <title>{blog?.title || "Blog"}</title>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <Card className="shadow-lg rounded-2xl overflow-hidden">
          {/* Header Section */}
          <CardHeader className="border-b-2 pb-8">
            <CardTitle className="text-3xl md:text-4xl lg:text-6xl font-semibold text-gray-900 text-center">
              {blog?.title}
            </CardTitle>
          
          </CardHeader>
          <div className="flex  items-center gap-4 p-2 pt-4">
            <Badge className="bg-blue-100 text-blue-800">
                {readTime}
              </Badge>
              <Badge className={categoryColors[blog?.category || "allcategory"]}>
                {blog?.category}
              </Badge>
              <span className="text-md font-medium">By {blog?.authorname}</span>
            
            </div>

          {/* Main Image Section */}
          <CardContent className="my-12">
            {blog?.images && (
              <img
                src={blog.images}
                alt={blog.imagediscription || "Blog Image"}
                className="w-full h-auto object-cover rounded-2xl shadow-2xl"
              />
            )}
            {blog?.imagediscription && (
              <div
                className="mt-12 text-xl md:text-2xl text-left md:text-center"
                dangerouslySetInnerHTML={{ __html: blog.imagediscription }}
              />
            )}
            <div className="font-opensans text-xl text-center mt-6">
              {blog?.description}
            </div>
          </CardContent>

          {/* Table of Contents & Supplementary Image */}
          <div className="grid md:grid-cols-2 gap-8 px-4 mb-12">
            {blog?.content && blog?.content?.length > 0 && (
              <Card className="bg-yellow-50 border-2 border-yellow-500 rounded-xl p-6">
                <h2 className="text-2xl font-bold text-center text-gray-900 mb-4">
                  Table of Contents
                </h2>
                {blog?.content.map((section, i) => (
                  <div
                    key={section._id}
                    className="flex hover:bg-yellow-100 cursor-pointer p-2 rounded-lg transition"
                  >
                    <p className="text-yellow-600 font-bold text-lg">{i + 1}.</p>
                    <p className="text-lg text-gray-800 ml-2">{section.tablename}</p>
                  </div>
                ))}
              </Card>
            )}

            {blog?.tableimage && (
              <div className="flex items-center justify-center">
                <img
                  src={blog.tableimage}
                  alt="Supplementary Table Image"
                  className="max-h-[500px] w-auto object-contain rounded-xl"
                />
              </div>
            )}
          </div>

          {/* Content Sections */}
          <div className="space-y-12 px-4 mb-12">
            {blog?.content?.map((section) => (
              <Card key={section._id} className="p-6 rounded-2xl">
                <h2 className="text-3xl font-extrabold text-gray-800 mb-4">
                  {section.heading}
                </h2>
                <div
                  className="text-gray-800 text-xl"
                  dangerouslySetInnerHTML={{ __html: section.discription }}
                />
                <div
                  className="text-gray-800 text-xl mt-4"
                  dangerouslySetInnerHTML={{ __html: section.discription2 }}
                />
              </Card>
            ))}
          </div>
        </Card>
      </div>
    </div>
    </>  );
};

export default SingleBlogPage;