import React from 'react';

interface SemesterInfoCardProps {
  imageSrc: string;
  heading: string;
  subheading: string;
  data: string[];
}

const SemesterInfoCard: React.FC<SemesterInfoCardProps> = ({
  imageSrc,
  heading,
  subheading,
  data,
}) => {
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      {/* Image Section */}
      <div className="w-full">
        <img
          src={imageSrc}
          alt={heading}
          className="w-full h-48 object-cover"
          loading="lazy" // Lazy load images for better performance
        />
      </div>

      {/* Content Section */}
      <div className="p-6">
        <p className="text-3xl  text-gray-800 mb-2">{heading}</p>
        <p className="text-lg text-gray-600 mb-4 ">{subheading}</p>
        <ul className="space-y-2">
          {data.map((item, index) => (
            <li key={index} className="text-gray-700 py-4">
              <span className="mr-2">•</span>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SemesterInfoCard;