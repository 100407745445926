import React, { useState } from "react";
import ContentTable from "./content-table";
import FormComponent from "./content-form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { toast } from "sonner";

const AdminPost: React.FC = () => {
  const api = `${import.meta.env.VITE_APP_API_URL}/admin/`;

  interface CreateCourse {
    _id: string;
    usedInIndustries: string;
    descriptionts: string;
    subject: string;
  }

  interface CourseFormData {
    _id: string;
    title: string;
    description: string; // Updated from `descriptions` to `description`
    topic: string;
    subTopic: string;
    subDescription: string;
    example: string;
    codeSnippet: string;
    file: File | null;
    links: string[];
    prerequisites: string[];
    subject: string;
    tags: string[];
    level: string;
    duration: string;
    courseId: string;
    createCourse: CreateCourse;
  }

  interface ContentItem {
    _id: string;
    title: string;
    description: string;
    topic: string;
    subTopic: string;
    subDescription: string;
    example: string;
    codeSnippet: string;
    file: File | null;
    links: string[];
    prerequisites: string[];
    subject: string;
    tags: string[];
    level: string;
    duration: string;
    courseId: string;
  }

  const [content, setContent] = useState<ContentItem[]>([]);
  const [createCourse, setCreateCourse] = useState<CreateCourse>({
    _id: "",
    usedInIndustries: "",
    descriptionts: "",
    subject: "",
  });

  const handleEditContent = () => {};
  const handleDelContent = () => {};

  const handleChangeAddCourse = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCreateCourse((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateCourse = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch(`${api}createCourse`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(createCourse),
      });
      await response.json();
      // console.log(result);
      toast.success("Course created successfully!")
      // alert("Course created successfully!");
    } catch (error) {
      toast.error(`An error occurred. Please try again.${error}`)
      // console.error("Error posting data:", error);
      // alert("An error occurred. Please try again.");
    }
  };

  const [formData, setFormData] = useState<CourseFormData>({
    _id: "",
    title: "",
    description: "", // Updated from `descriptions` to `description`
    topic: "",
    subTopic: "",
    subDescription: "",
    example: "",
    codeSnippet: "",
    file: null,
    links: [],
    prerequisites: [],
    subject: "",
    tags: [],
    level: "",
    duration: "",
    courseId: "",
    createCourse: createCourse,
  });

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gradient-to-r from-blue-50 to-gray-100 p-2">
      <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800">
        Create a Course
      </h2>

      {/* Create Course Form */}
      <Card className="w-full max-w-4xl mb-8">
        <CardHeader>
          <CardTitle>Create Course</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleCreateCourse} className="space-y-4">
            <div>
              <Label htmlFor="subject">Course Name</Label>
              <Input
                type="text"
                id="subject"
                name="subject"
                placeholder="Enter course name"
                value={createCourse.subject}
                onChange={handleChangeAddCourse}
                required
              />
            </div>

            <div>
              <Label htmlFor="usedInIndustries">Industry Used</Label>
              <Input
                type="text"
                id="usedInIndustries"
                name="usedInIndustries"
                placeholder="Used in industries"
                value={createCourse.usedInIndustries}
                onChange={handleChangeAddCourse}
                required
              />
            </div>

            <div>
              <Label htmlFor="descriptionts">Description</Label>
              <Input
                id="descriptionts"
                name="descriptionts"
                placeholder="Enter course description"
                value={createCourse.descriptionts}
                onChange={handleChangeAddCourse}
                required
              />
            </div>

            <Button type="submit" className="w-full">
              Submit
            </Button>
          </form>
        </CardContent>
      </Card>

      {/* Form Component */}
      <FormComponent
  createCourse={createCourse}
  setCreateCourse={setCreateCourse}
  formData={formData}
  setFormData={setFormData}
  setContent={setContent}
  handleCreateCourse={handleCreateCourse}
/>

      {/* Content Table */}
      {content.length > 0 && (
        <Card className="w-full max-w-4xl">
          <CardHeader>
            <CardTitle>Content List</CardTitle>
          </CardHeader>
          <CardContent>
            <ContentTable
              data={content}
              handleEdit={handleEditContent}
              handleDelete={handleDelContent}
            />
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default AdminPost;