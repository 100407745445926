import { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
// It's better to define lazy components outside the components array
const CarouselCustom = lazy(() => import('./home/CarouselCustom'));
const SkillyardsPVT = lazy(() => import('./home/SkillyardsPVT'));
const TrainingPrograms = lazy(() => import('./home/TrainingPrograms'));
const OurPartner = lazy(() => import('./home/OurPartner'));
const OurFounder = lazy(() => import('./home/OurFounder'));
const SkillyardsEvent = lazy(() => import('./home/SkillyardsEvent'));
const SkillyardsLife = lazy(() => import('./home/SkillyardsLife'));
const HomeFAQ = lazy(() => import('./home/HomeFAQ'));
const WhatStudentSaid = lazy(() => import('./home/WhatStudentSaid'));
const CallToActionSection = lazy(() => import('./home/GetInTouch'))
const TalkToTeam = lazy(() => import('./common/TalkToTeam'))

const components = [
  { Component: CarouselCustom, id: 'carousel' },
  { Component: SkillyardsPVT, id: 'skillyards' },
  { Component: TrainingPrograms, id: 'programs' },
  { Component: WhatStudentSaid, id: 'whatstudentsaid' },
  { Component: CallToActionSection, id: 'calltoactionsection' },
  { Component: OurPartner, id: 'partners' },
  { Component: OurFounder, id: 'founders' },
  { Component: SkillyardsEvent, id: 'events' },
  { Component: SkillyardsLife, id: 'life' },
  { Component: HomeFAQ, id: 'faq' },
  { Component: TalkToTeam, id: 'talktoteam' },

];

const LoadingFallback = () => (
  <div className="w-full h-[100vh] animate-pulse bg-gradient-to-r from-gray-100 to-gray-200" />
);

const HomePage = () => {
  return (
    <div className="min-h-screen bg-white">
    <Helmet>
  <title>Best IT Training Platform in Agra | Skillyards</title>
  
  <meta http-equiv="x-ua-compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <meta name="description" 
    content="Skillyards, the leading IT training platform in Agra, offers hands-on training, paid internships, and guaranteed placements, shaping the city into a growing IT hub." />
  <meta name="keywords" 
    content="Skillyards, IT training in Agra, full stack web development training, best full stack developer training, digital marketing training, UI/UX design training, SEO and PPC training, front-end development, back-end development, best IT courses in Agra" />
  <meta name="author" content="Skillyards" />
  
  <link rel="canonical" href="https://www.skillyards.com/" />
</Helmet>

      {components.map(({ Component, id }) => (
        <Suspense key={id} fallback={<LoadingFallback />}>
          <section className="relative">
            <Component />
          </section>
        </Suspense>
      ))}
    </div>
  );
};

export default HomePage;