import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useState } from "react";

interface Option {
  text: string;
  isCorrect: boolean;
}

interface Question {
  question: string;
  options: Option[];
}
 const api=`${import.meta.env.VITE_APP_API_URL}`
const CreateAptitudeTest = () => {
  const [title, setTitle] = useState("");
  const [course, setCourse] = useState(""); // Removed default "fullstack" value

  const [questions, setQuestions] = useState<Question[]>([
    {
      question: "",
      options: [
        { text: "", isCorrect: false },
        { text: "", isCorrect: false },
        { text: "", isCorrect: false },
        { text: "", isCorrect: false },
      ],
    },
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleQuestionChange = (index: number, value: string) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].question = value;
    setQuestions(updatedQuestions);
  };

  const handleOptionChange = (qIndex: number, oIndex: number, value: string) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].options[oIndex].text = value;
    setQuestions(updatedQuestions);
  };

  const handleCorrectAnswerChange = (qIndex: number, oIndex: number) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].options.forEach((opt, idx) => {
      opt.isCorrect = idx === oIndex;
    });
    setQuestions(updatedQuestions);
  };
 
  

  const addQuestion = () => {
    const newQuestion: Question = {
      question: "",
      options: [
        { text: "", isCorrect: false },
        { text: "", isCorrect: false },
        { text: "", isCorrect: false },
        { text: "", isCorrect: false },
      ],
    };
    setQuestions([...questions, newQuestion]);
  };

  const removeQuestion = (index: number) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const validateForm = () => {
    if (!title.trim()) {
      return "Title is required";
    }
    
    // Add validation for course
    if (!course) {
      return "Course selection is required";
    }

    for (let q of questions) {
      if (!q.question.trim()) {
        return "All questions must have text";
      }
      if (q.options.some(opt => !opt.text.trim())) {
        return "All options must have text";
      }
      const correctCount = q.options.filter(opt => opt.isCorrect).length;
      if (correctCount !== 1) {
        return "Each question must have exactly one correct answer";
      }
    }
    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    setIsSubmitting(true);
    setError(null);

    const formData = {
      title,
      course,
      questions,
    };

    try {
      console.log("Submitting MCQ Test Data:", formData);
      const response = await fetch(`${api}/create-mcq`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to create MCQ test");
      }

      const result = await response.json();
      console.log("API Response:", result);

      setTitle("");
      setCourse(""); // Reset to empty string instead of "fullstack"
      setQuestions([
        {
          question: "",
          options: [
            { text: "", isCorrect: false },
            { text: "", isCorrect: false },
            { text: "", isCorrect: false },
            { text: "", isCorrect: false },
          ],
        },
      ]);

    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card className="w-full mx-auto">
      <CardHeader>
        <CardTitle className="text-2xl">Create MCQ Test</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Title */}
          <div className="space-y-2">
            <Label htmlFor="title">Test Title *</Label>
            <Input
              id="title"
              placeholder="Enter MCQ Test Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          {/* Course Selection */}
          <div className="space-y-2">
            <Label htmlFor="course">Course</Label>
            <Select value={course} onValueChange={setCourse}>
              <SelectTrigger id="course">
                <SelectValue placeholder="Select a course" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="fullstack">Full Stack</SelectItem>
                <SelectItem value="digitalmarketing">Digital Marketing</SelectItem>
                <SelectItem value="androiddeveloper">Android Developer</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {/* Questions */}
          {questions.map((q, qIndex) => (
            <div key={qIndex} className="border p-4 rounded-lg mb-4">
              <Label htmlFor={`question-${qIndex}`}>Question {qIndex + 1}</Label>
              <Input
                id={`question-${qIndex}`}
                placeholder="Enter question text"
                value={q.question}
                onChange={(e) => handleQuestionChange(qIndex, e.target.value)}
                required
              />

              {/* Options */}
              {q.options.map((option, oIndex) => (
                <div key={oIndex} className="flex items-center mt-2">
                  <input
                    type="radio"
                    name={`correct-${qIndex}`}
                    checked={option.isCorrect}
                    onChange={() => handleCorrectAnswerChange(qIndex, oIndex)}
                    className="mr-2"
                  />
                  <Input
                    placeholder={`Option ${oIndex + 1}`}
                    value={option.text}
                    onChange={(e) => handleOptionChange(qIndex, oIndex, e.target.value)}
                    required
                  />
                </div>
              ))}

              {/* Remove Question */}
              {questions.length > 1 && (
                <Button
                  type="button"
                  variant="destructive"
                  onClick={() => removeQuestion(qIndex)}
                  className="mt-2"
                >
                  Remove Question
                </Button>
              )}
            </div>
          ))}

          {/* Error Message */}
          {error && (
            <p className="text-red-500 text-sm">{error}</p>
          )}

          {/* Add Question Button */}
          <Button type="button" onClick={addQuestion} variant="secondary">
            Add Question
          </Button>

          {/* Submit Button */}
          <Button 
            type="submit" 
            className="w-full"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Saving..." : "Save MCQ Test"}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default CreateAptitudeTest;